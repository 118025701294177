import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    useIonViewDidEnter
} from "@ionic/react";
import {
    getCurrentUsersCreatorPreferences,
    updateVideoChatPreferences
} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "./ManageStepComponent";

const ManageVideoChatPreferences = () => {
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    const [pricePerMinute, setPricePerMinute] = useState();
    const [minDuration, setMinDuration] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    const handleSuccess = _ => {
        history.push("/settings");
        setDisabled(false);
        setCurrentIdx(0);
    }
    const handleError = _ => {
        alert("Something went wrong. We were unable to update your preferences.");
        setDisabled(false);
        setCurrentIdx(0);
    }
    useIonViewDidEnter(() => {

        getCurrentUsersCreatorPreferences().then(prefs => {
            if (prefs && prefs.videoChatPricePerMinute) {
                setPricePerMinute(prefs.videoChatPricePerMinute / 100);
                setMinDuration(prefs.minimumVideoChatDuration);
            }
        }).catch(_ => {
            alert("Something went wrong. We were unable to retrieve your current preferences.");
            history.goBack();
        })
    }, []);

    const steps = [
        {
            key: "videoChat0",
            label: "Do you want to allow users to schedule video chats?",
            placeholder: "Example $50.00. Min. $1",
            trueLabel: "Yes",
            falseLabel: "No",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                updateVideoChatPreferences(-1.0, -1.0, false).then(handleSuccess).catch(handleError);
            },
            onPrevClicked: () => {
                history.goBack();
            }
        },
        {
            key: "videoChat1",
            label: "What do you want to charge per minute of video chat? All prices in US Dollars ($). Min $0.50",
            placeholder: "Example 3.00",
            value: pricePerMinute,
            onChange: (e) => setPricePerMinute(e.target.value),
            inputType: "number",
            inputMode: "decimal",
            onNextClicked: () => {
                if (!pricePerMinute || isNaN(pricePerMinute) || pricePerMinute < .5) {
                    alert("You must charge at least 50 cents per minute e.g. 0.75")
                    return;
                }
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        {
            key: "videoChat2",
            label: "What is the shortest video chat you will accept in minutes? Min 15 minutes.",
            placeholder: "Ex: 25",
            value: minDuration,
            onChange: (e) => setMinDuration(e.target.value),
            inputType: "number",
            inputMode: "numeric",
            onNextClicked: () => {
                if (!minDuration || isNaN(minDuration) || minDuration < 15) {
                    alert("You mut specify a duration of at least 15 minutes e.g. 15")
                    return;
                }
                if (disabled) return;
                setDisabled(true);
                updateVideoChatPreferences(pricePerMinute, minDuration, true).then(handleSuccess).catch(handleError);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        // {
        //     key: "videoChat3",
        //     label: "Do you want to allow non-subscribers to schedule video chats?",
        //     trueLabel: "Yes",
        //     falseLabel: "No. Subscribers only",
        //     inputType: "bool",
        //     onTrue: () => {
        //         if (disabled) return;
        //         setDisabled(true);
        //         updateVideoChatPreferences(pricePerMinute, minDuration, true).then(handleSuccess).catch(handleError)
        //     },
        //     onFalse: () => {
        //         if (disabled) return;
        //         setDisabled(true);
        //         updateVideoChatPreferences(pricePerMinute, minDuration, false).then(handleSuccess).catch(handleError)
        //     },
        //     onPrevClicked: () => {
        //         setCurrentIdx(0);
        //     }
        // }
    ];
    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-text-center"}>
                <ManageStep disabled={disabled} step={step}/>
            </IonContent>
        </IonPage>
    );
}

export default ManageVideoChatPreferences;
