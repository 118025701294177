import React, {Component} from "react";
import {Redirect, Route} from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Pusher from "pusher-js";
import {chatbubble, settings} from 'ionicons/icons';

import './App.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import AuthProtected from "./components/Auth/AuthProtectedRoute";
import {ROUTES} from "./Routes";
import {getPusherAppKey, isApp} from "./Utils/Utils";
import {getBaseUrl} from "./requests/BaseRequests/BaseRequests";
import AppUrlListener from "./components/DeepLinks/AppURLListener";
import {OneSignalInit} from "./Utils/OneSignalInit";
import HomePage from "./pages/Home/HomePage";
import ProfilePageDefault from "./pages/Profile/ProfilePageDefault";
import UpdateRequiredPage from "./pages/UpdateRequired/UpdateRequired";
import {getCurrentVersion} from "./requests/AppVersion/AppVersionRequests";

setupIonicReact();
if (isApp()) {
    try {
        OneSignalInit();
    } catch (error) {
        console.log("Could not setup one signal");
    }

}

// Pusher.logToConsole = true;

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {pusher: null, hideApp: false, loading: true}
        this.timeout = null;
    }

    componentDidMount = () => {
        this.setUpPusher();
        if (isApp()) {
            getCurrentVersion().then(currentVersion => {
                console.log("Current Version " + JSON.stringify(currentVersion));
                if (currentVersion.currentVersion > 0) { // we update 0 when the next version is deployed and update s3
                    this.setState({hideApp: true});
                }
                this.setState({loading: false});
            }).catch(_ => {
                this.setState({loading: false});
            })
        } else {
            this.setState({loading: false});
        }
    }

    setUpPusher = () => {
        const sessionTokenJson = localStorage.getItem("session-token");
        if (!sessionTokenJson) {
            setTimeout(() => {
                this.setUpPusher()
            }, 1000);
            return;
        }
        const pusher = new Pusher(getPusherAppKey(), {
            cluster: 'mt1',
            authEndpoint: getBaseUrl() + "/auth/socket",
            auth: {
                headers: {
                    "Authorization": JSON.parse(sessionTokenJson).token
                }
            }
        });
        this.setState({pusher});
    }

    componentWillUnmount = () => {
        if (this.pusher) {
            this.pusher.disconnect();
        }
    }

    render = () => {
        const {loading, hideApp} = this.state;
        return (
            <IonApp>
                <IonReactRouter>
                    <AppUrlListener/>
                    {
                        !hideApp && !loading ?
                            <IonTabs>
                                <IonRouterOutlet>
                                    {
                                        ROUTES.map((rt, index) => {
                                            const {authProtected, exact, route, component} = rt;
                                            return authProtected ?
                                                <Route exact={exact} path={route} key={"route" + index}>
                                                    <AuthProtected>
                                                        {React.cloneElement(component, {pusher: this.state.pusher})}
                                                    </AuthProtected>
                                                </Route>
                                                :
                                                <Route exact={exact} path={route} key={"route" + index}>
                                                    {component}
                                                </Route>
                                        })
                                    }
                                    <Route exact path="/">
                                        <HomePage/>
                                    </Route>
                                    <Route>
                                        <ProfilePageDefault/>
                                    </Route>
                                </IonRouterOutlet>
                                <IonTabBar slot="bottom" className={false ? "display-none " : "overflow-scroll "}>
                                    <IonTabButton tab="chats" href="/chats">
                                        <IonIcon icon={chatbubble}/>
                                        <IonLabel>Messages</IonLabel>
                                    </IonTabButton>
                                    <IonTabButton tab="settings" href="/settings">
                                        <IonIcon icon={settings}/>
                                        <IonLabel>Settings</IonLabel>
                                    </IonTabButton>
                                </IonTabBar>
                            </IonTabs>
                            :
                            <></>
                    }
                    {hideApp && !loading ?
                        <Route>
                            <UpdateRequiredPage/>
                        </Route>
                        :
                        <></>
                    }
                </IonReactRouter>
            </IonApp>
        );
    }
}

export default App;
