import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './Auth.css';
import {login} from "../../requests/Auth/AuthRequests";
import {getSearchParamOrDefault} from "../../Utils/Utils";
import {IonPage} from "@ionic/react";
import HowItWorksVideo from "../../components/HowItWorks/HowItWorksVideo";

const Login = ({isSignUp}) => {
    const [disabled, setDisabled] = useState();
    const [phone, setPhone] = useState();
    const [password, setPassword] = useState();
    const history = useHistory();
    const loginUser = () => {
        if (disabled) {
            return;
        }
        if (!phone.trim().replace(/\D/g, '') || !password) {
            alert("You must provide all fields");
            return;
        }
        setDisabled(true);
        return login(phone.trim().replace(/\D/g, ''), password).then(data => {
            const expiration = new Date().getTime() + (1000 * 60 * 60 * 24);
            localStorage.setItem("session-token", JSON.stringify({token: data, expiration}));
            history.replace(getSearchParamOrDefault("redirect", "/chats"));
            setDisabled(false);
        }).catch(_ => {
            alert("Something went wrong. Double check your password");
            setPassword("");
            setDisabled(false);
        });
    }

    return (
        <IonPage>
            <section className="login login-alt position-relative overflow-scroll">
                <div className="container height-100vh">
                    <div className="col-md-10 m-auto">
                        <div className="login-main shadow-lg p-md-5 p-4"
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0 auto',
                                width: '80%'
                            }}>
                                <Link to="/chats">
                                    <img
                                        style={{width: "300px", marginBottom: "10px"}}
                                        src="assets/logo-sm-light.png"
                                        alt="logo"
                                        className="img-fluid"
                                    />
                                </Link>
                                <input type="tel" className="form-control form-control-lg"
                                       inputMode={'tel'}
                                       placeholder="Phone number"
                                       value={phone}
                                       onChange={(e) => setPhone(e.target.value)}
                                />
                                <input type="password"
                                       placeholder="Password"
                                       className="form-control form-control-lg"
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                />
                                <button className={"btn btn-red-gradient rounded-pill text-uppercase mt-2"}
                                        onClick={loginUser}
                                >
                                    {disabled ? "Loading..." : "Sign In"}
                                </button>
                                <br/>
                                <Link
                                    className={"mb-2 mt-2"}
                                    to={"/sign-up" + window.location.search} replace>
                                    Sign Up
                                </Link>
                                <div style={{height: "10px"}}/>
                                <HowItWorksVideo className={"mt-3"}/>
                                <div style={{height: "10px"}}/>
                                <Link to={'/legal'}>
                                    Legal
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </IonPage>
    );
}

export default Login;
