import React from 'react';
import MeetingDetails from "./MeetingDetails";
import FanRequestDetails from "./FanRequestDetails";
import RatingDetails from "./RatingDetails";
import JoinVideoChat from "./JoinVideoChat";
import MarkArrival from "./MarkArrival";
import ConfirmArrival from "./ConfirmArrival";
import VideoForSaleDetails from "./VideoForSaleDetails";
import ViewVideoDetails from "./ViewVideoDetails";
import RequestVideoDetails from "./RequestVideoDetails";
import BountyVideoDetails from "./BountyVideoDetails";

const MessageDetailsModal = ({closeModal, message, currentUserId}) => {
    if (!message) {
        return <></>;
    }
    if (message.messageType === "BOOKING_REQUEST") {
        return (
            <MeetingDetails message={message} closeModal={closeModal} currentUserId={currentUserId}/>
        );
    }
    if (message.messageType === "BOOKING_ACCEPTANCE") {
        return (
            <MeetingDetails message={message} closeModal={closeModal}
                            isViewOnly={true}
                            currentUserId={currentUserId}
            />
        );
    }
    if (message.messageType === "VIDEO_CHAT_REQUEST") {
        return (
            <MeetingDetails message={message} closeModal={closeModal}
                            isVideoChat={true}
                            currentUserId={currentUserId}
            />
        );
    }
    if (message.messageType === "VIDEO_CHAT_ACCEPTANCE") {
        return (
            <MeetingDetails message={message} closeModal={closeModal}
                            isViewOnly={true}
                            isVideoChat={true}
                            currentUserId={currentUserId}
            />
        );
    }
    if (message.messageType === "FAN_REQUEST") {
        return (
            <FanRequestDetails message={message} currentUserId={currentUserId} closeModal={closeModal}/>
        );
    }
    if (message.messageType === "CREATOR_START_VIDEO_CHAT") {
        return (
            <JoinVideoChat message={message} currentUserId={currentUserId} closeModal={closeModal}/>
        );
    }
    if (message.messageType === "FAN_START_VIDEO_CHAT") {
        return (
            <JoinVideoChat message={message} currentUserId={currentUserId} closeModal={closeModal} isFansTurn={true}/>
        );
    }
    if (message.messageType === "RATING") {
        return (
            <RatingDetails message={message} closeModal={closeModal}/>
        );
    }

    if (message.messageType === "MARK_ARRIVAL") {
        return <MarkArrival currentUserId={currentUserId} closeModal={closeModal} message={message}/>;
    }

    if (message.messageType === "CONFIRM_ARRIVAL") {
        return <ConfirmArrival currentUserId={currentUserId} closeModal={closeModal} message={message}/>;
    }

    if (message.messageType === "VIDEO_FOR_SALE") {
        return <VideoForSaleDetails currentUserId={currentUserId} closeModal={closeModal} message={message}/>;
    }
    if (message.messageType === "VIDEO_PURCHASED") {
        return <ViewVideoDetails currentUserId={currentUserId} closeModal={closeModal} message={message}/>
    }
    if (message.messageType === "FAN_REQUEST_COMPLETED") {
        return <RequestVideoDetails currentUserId={currentUserId} closeModal={closeModal} message={message}/>
    }
    if (message.messageType === "BOUNTY_COMPLETED") {
        return <BountyVideoDetails closeModal={closeModal} message={message}/>;
    }
    return <>{message.messageType}</>;
}

export default MessageDetailsModal;
