import React from 'react';
import {
    IonPage,
    IonHeader,
    IonContent,
    useIonViewDidEnter, IonToolbar
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {getHandleForUserId} from "../../requests/Handles/HandleRequests";

const ProfilePageRedirect = ({}) => {
    const {id} = useParams();
    const history = useHistory();
    useIonViewDidEnter(() => {
        getHandleForUserId(id).then(handle => {
            if (!handle) {
                history.goBack();
                return;
            }
            history.replace("/p/" + handle);
        }).catch(_ => {
        });
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>

            </IonContent>
        </IonPage>
    );
}

export default ProfilePageRedirect;
