import React, {useEffect, useState} from 'react';
import {formatCurrency as formatCurrencyDollars} from "../../../../Utils/Utils";

const MobileSearchSliders = ({
                                 waitAndSearch,
                                 lat,
                                 setLat,
                                 lng,
                                 setLng,
                                 maxPriceForRequests,
                                 setMaxPriceForRequests,
                                 maxPriceForVideoChats,
                                 setMaxPriceForVideoChats,
                                 maxPriceForBookings,
                                 setMaxPriceForBookings,
                                 minApprovalRating,
                                 setMinApprovalRating,
                                 maxPriceForSubscribers,
                                 setMaxPriceForSubscribers,
                                 maxPriceForTextMessage,
                                 setMaxPriceForTextMessage,
                                 maxPriceForMMS,
                                 setMaxPriceForMMS,
                                 maxDistance,
                                 setMaxDistance
                             }) => {
    const [lastChanged, setLastChanged] = useState();
    const sliders = [
        {
            name: "Price Per Sub",
            min: 1,
            max: 999999,
            value: maxPriceForSubscribers,
            step: .01,
            onChange: (({target}) => {
                setMaxPriceForSubscribers(target.value)
                setLastChanged("Price Per Sub")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "Subscription: < " + formatCurrencyDollars(val) + "/mo" : "Any Subscription Price"
            ))
        },
        {
            name: "Price Per SMS",
            min: 1.01,
            max: 99999,
            value: maxPriceForTextMessage,
            step: .01,
            onChange: (({target}) => {
                setMaxPriceForTextMessage(target.value)
                setLastChanged("Price Per SMS")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "SMS: < " + formatCurrencyDollars(val) + "/msg" : "Any SMS Price"
            ))
        },
        {
            name: "Price Per MMS",
            min: 1,
            max: 99999,
            value: maxPriceForMMS,
            step: .01,
            onChange: (({target}) => {
                setMaxPriceForMMS(target.value)
                setLastChanged("Price Per MMS")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "MMS: < " + formatCurrencyDollars(val) + "/msg" : "Any MMS Price"
            ))
        },
        {
            name: "Price Per Minute (Video Chat)",
            min: 1,
            max: 500,
            value: maxPriceForVideoChats,
            step: .01,
            onChange: (({target}) => {
                setMaxPriceForVideoChats(target.value)
                setLastChanged("Price Per Minute (Video Chat)")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "Video Chat: < " + formatCurrencyDollars(val) + "/min" : "Any Video Chat Price"
            ))
        },
        {
            name: "Price Per Minute (Booking)",
            min: 15,
            max: 1000000,
            value: maxPriceForBookings,
            step: 1,
            onChange: (({target}) => {
                setMaxPriceForBookings(target.value);
                setLastChanged("Price Per Minute (Booking)")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "Booking: < " + formatCurrencyDollars(val) + "/hr" : "Any Booking Price"
            ))
        },
        {
            name: "Max Price Per Request",
            min: 5,
            max: 999999,
            value: maxPriceForRequests,
            step: 60,
            onChange: (({target}) => {
                setMaxPriceForRequests(target.value);
                setLastChanged("Max Price Per Request")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "< " + formatCurrencyDollars(val) + "/request" : "Any Request Price"

            ))
        },
        {
            name: "Rating",
            min: 0,
            max: 1,
            value: minApprovalRating,
            step: 0.01,
            onChange: (e) => {
                e.preventDefault();
                const newRating = e.target.value;
                if (newRating >= .5) {
                    setMinApprovalRating(newRating);
                    waitAndSearch();
                }
                setLastChanged("Rating");
            },
            formattedValue: ((val) => (
                val ? "" + (val * 100).toFixed(0) + "% would hire again" : "Any Rating"
            ))
        },
        {
            name: "Distance",
            min: 1,
            max: 100,
            value: maxDistance,
            step: 1,
            onChange: (e) => {
                setMaxDistance(e.target.value);
                if (!lat || !lng) {
                    return navigator.geolocation.getCurrentPosition(({coords}) => {
                        const latitude = coords.latitude;
                        const longitude = coords.longitude;
                        setLat(latitude);
                        setLng(longitude);
                        waitAndSearch();
                    }, () => {
                        alert("Something went wrong, we were unable to find your latitude or longitude. Please check your browser settings and refresh the page.");
                    }, {enableHighAccuracy: true});
                } else {
                    waitAndSearch();
                }
            },
            formattedValue: ((val) => (
                val ? maxDistance + "mi" : "Any Distance"
            ))
        }
    ];

    const [idx, setIdx] = useState(0);
    const [showText, setShowText] = useState(true);
    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            if (idx < 7) {
                setIdx(prevIdx => prevIdx + 1)
            } else {
                setShowText(false);
                clearInterval(interval);
            }
        }, 300);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, []);

    return (
        <div className={"row col-lg-10 col-md-10 col-sm-12 small-screens-only"} style={{margin: "0 auto"}}>
            <div className="col-lg-12 col-md-12 col-sm-12"
                 style={{
                     height: "fit-content",
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center"
                 }}>
                <div className={"mt-5"}></div>
                {
                    sliders.map((slide, currentIdx) => (
                        <div>
                            {
                                (showText && idx === currentIdx) || slide.name === lastChanged ?
                                    <>
                                        <div className={"range-text w-100 center-text text-center"}
                                        >
                                            {slide.formattedValue(slide.value)}
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                            <br/>
                            <input
                                className="my-range w-80"
                                type="range"
                                min={slide.min}
                                step={slide.step}
                                value={slide.value}
                                onChange={slide.onChange}
                                max={slide.max}
                                style={{
                                    background: `linear-gradient(to right, #F47B60 0%, #F47B60 ${
                                        (slide.value ? ((slide.value / slide.max) * 100) : 0)
                                    }%, #DEE2E6 ${
                                        100 - (slide.value ? ((slide.value / slide.max) * 100) : 0)
                                    }%, #DEE2E6 100%`
                                }}
                            >
                            </input>
                            <br/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default MobileSearchSliders;
