import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {getRequest as noAuthGet} from "../BaseRequests/BaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const getCurrentUsersCreatorPreferences = () => {
    return getRequest(getBaseUrl() + "/creator-preferences").then(defaultResponseHandler);
}

export const getCreatorPreferencesForUser = (userId) => {
    return noAuthGet(getBaseUrl() + "/creator-preferences/" + userId).then(defaultResponseHandler);
}

export const initializeCreatorPreferences = (smsPriceInDollars, subPriceInDollars = 0, publicSMS = false) => {
    const url = getBaseUrl() + `/creator-preferences/initialize?smsPriceInDollars=${smsPriceInDollars}&subPriceInDollars=${subPriceInDollars}&publicSMS=${publicSMS}`;
    return postRequest(url, {}).then(defaultResponseHandler);
}

export const updateMessagingPreferences = (smsPrice = 1, mmsPrice = null, publicSMS = false, publicMMS = false) => {
    const url = getBaseUrl() + `/creator-preferences/messaging?smsPrice=${smsPrice}&mmsPrice=${mmsPrice}&publicSMS=${publicSMS}&publicMMS=${publicMMS}`;
    return postRequest(url).then(defaultResponseHandler);
}

export const updateSubPrice = (subPrice) => {
    const url = getBaseUrl() + `/creator-preferences/sub-price?subPrice=${subPrice}`;
    return postRequest(url).then(defaultResponseHandler);
}

export const updateRequestPreferences = (requestPrice = null, publicRequests = false) => {
    const url = getBaseUrl() + `/creator-preferences/requests?requestPrice=${requestPrice}&publicRequests=${publicRequests}`;
    return postRequest(url).then(defaultResponseHandler);
}

export const updateVideoChatPreferences = (pricePerMin = null, minDuration = null, publicVideoChats = false) => {
    const url = getBaseUrl() + `/creator-preferences/video-chats?&pricePerMin=${pricePerMin}&minDuration=${minDuration}&publicVideoChats=${publicVideoChats}`;
    return postRequest(url).then(defaultResponseHandler);
}

export const updateBookingPreferences = (
    fhBookingPrice = -1,
    chBookingPrice = -1,
    chBookingDuration = -1,
    fhBookingDuration = -1,
    publicBookings = false,
    bookingQuestions = ""
) => {
    const url = getBaseUrl() + `/creator-preferences/bookings?&fhBookingPrice=${fhBookingPrice}&chBookingPrice=${chBookingPrice}&chBookingDuration=${chBookingDuration}&fhBookingDuration=${fhBookingDuration}&publicBookings=${publicBookings}&bookingQuestions=${bookingQuestions}`;
    return postRequest(url).then(defaultResponseHandler);
}

export const checkIfCurrentUserHasCreatorPreferences = () => {
    return getRequest(getBaseUrl() + "/creator-preferences/has").then(defaultResponseHandler);
}
