import axios from "axios";

export const postRequest = (url, body, headers = {}) => {
    return axios.post(url, body, {headers});
}

export const patchRequest = (url, body, headers = {}) => {
    return axios.patch(url, body, {headers});
}

export const putRequest = (url, body, headers = {}) => {
    return axios.put(url, body, {headers});
}

export const getRequest = (url, headers) => {
    return axios.get(url, {headers});
}

export const baseDeleteRequest = (url, headers) => {
    return axios.delete(url, {headers});
}

export const uploadMultiPartData = (url, file, headers, params = {}) => {
    const formData = new FormData();
    Object.keys(params).forEach(param => {
        formData.append(param, params[param]);
    });
    headers['Content-Type'] = 'multipart/form-data';
    formData.append('file', file);
    const config = {headers: headers};
    return axios.put(url, formData, config);
}

export const uploadMultiPartDataPost = (url, file, headers, params = {}) => {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(params).forEach(param => {
        formData.append(param, params[param]);
    });
    headers['Content-Type'] = 'multipart/form-data';
    const config = {headers: headers};
    return axios.post(url, formData, config);
}


export const getBaseUrl = () => {
    if (process.env.REACT_APP_ENV_TYPE === "PRODUCTION") {
        console.log("PRODUCTION");
        return "https://fan-numbers-v2-api.herokuapp.com";
    }
    if (process.env.REACT_APP_ENV_TYPE === "STAGING") {
        console.log("STAGING");
        return "https://fan-numbers-v2-api.herokuapp.com";
    }
    console.log("ENV TYPE " + process.env.REACT_APP_ENV_TYPE);
    return "http://localhost:8080";
}

export const defaultResponseHandler = (res) => {
    if (res && res['status'] >= 200 && res['status'] < 300) {
        return res['data'];
    }
    throw 'invalid response';
}
