import React, {useState} from 'react';
import {IonBackButton, IonButtons, IonHeader, IonToolbar, IonPage, IonContent, useIonViewDidEnter} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {getTermsAndConditionStatements} from "../../requests/Legal/LegalRequests";

const TermsAndConditions = ({}) => {
    const [terms, setTerms] = useState([]);
    useIonViewDidEnter(() => {
        getTermsAndConditionStatements().then(tAndC => {
            setTerms(tAndC);
        }).catch(_ => {

        });
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/legal"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{width: '80vw', margin: '0 auto', padding: "20px"}}>
                    {
                        terms.map(el => (
                            <>
                                <h6>{el.header}</h6>
                                {
                                    el.paragraphs.map(p => (
                                        <>
                                            <p>
                                                {p}
                                            </p>
                                            <br/>
                                        </>
                                    ))
                                }
                            </>
                        ))
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}

export default TermsAndConditions;
