import React, {Component} from 'react';
import MobileSearchSliders from "./MobileSearchSliders";

class MobileSearchSlidersWrapper extends Component {

    constructor(props) {
        super(props);
        this.searchDelayTimeout = null;
    }

    waitAndSearch = () => {
        if (this.searchDelayTimeout) {
            clearTimeout(this.searchDelayTimeout);
            this.searchDelayTimeout = null;
        }
        this.searchDelayTimeout = setTimeout(() => {
            this.props.performNewSearch();
        }, 350);
    }

    render = () => {
        const {
            lat,
            setLat,
            lng,
            setLng,
            maxPriceForRequests,
            setMaxPriceForRequests,
            maxPriceForVideoChats,
            setMaxPriceForVideoChats,
            maxPriceForBookings,
            setMaxPriceForBookings,
            minApprovalRating,
            setMinApprovalRating,
            maxPriceForSubscribers,
            setMaxPriceForSubscribers,
            maxPriceForTextMessage,
            setMaxPriceForTextMessage,
            maxPriceForMMS,
            setMaxPriceForMMS,
            maxDistance,
            setMaxDistance
        } = this.props;
        return (
            <MobileSearchSliders
                waitAndSearch={this.waitAndSearch}
                lat={lat}
                setLat={setLat}
                lng={lng}
                setLng={setLng}
                maxPriceForRequests={maxPriceForRequests}
                setMaxPriceForRequests={setMaxPriceForRequests}
                maxPriceForVideoChats={maxPriceForVideoChats}
                setMaxPriceForVideoChats={setMaxPriceForVideoChats}
                maxPriceForBookings={maxPriceForBookings}
                setMaxPriceForBookings={setMaxPriceForBookings}
                minApprovalRating={minApprovalRating}
                setMinApprovalRating={setMinApprovalRating}
                maxPriceForSubscribers={maxPriceForSubscribers}
                setMaxPriceForSubscribers={setMaxPriceForSubscribers}
                maxPriceForTextMessage={maxPriceForTextMessage}
                setMaxPriceForTextMessage={setMaxPriceForTextMessage}
                maxPriceForMMS={maxPriceForMMS}
                setMaxPriceForMMS={setMaxPriceForMMS}
                maxDistance={maxDistance}
                setMaxDistance={setMaxDistance}
            />
        );
    }
}

export default MobileSearchSlidersWrapper;
