import React, {useEffect, useState} from 'react';
import BountyLink from "./BountyLink";
import {isCurrentUser} from "../../Utils/Utils";
import {Link} from "react-router-dom";

const Bounties = ({userId, descriptionToId, idToAmount}) => {
    const [prompt, setPrompt] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [creatorIsCurrentUser, setCreatorIsCurrentUser] = useState(false);
    const [description, setDescription] = useState();
    const [sorted, setSorted] = useState([]);
    const [isAsc, setIsAsc] = useState(false);
    useEffect(() => {
        setPrompt(isCurrentUser(userId) ? "Click a bounty to fulfill it & get paid" : "Click a bounty to support it & get notified")
        setCreatorIsCurrentUser(isCurrentUser(userId));
        setSorted(Object.keys(descriptionToId).sort((s1, s2) => {
            if (idToAmount[s1] < idToAmount[s2]) {
                return -1 * (isAsc ? -1 : 1);
            } else if (idToAmount[s1] > idToAmount[s2]) {
                return (isAsc ? -1 : 1);
            } else {
                return 0;
            }
        }));
    }, [userId]);
    const toggleSorted = () => {
        let newSorted = Object.keys(descriptionToId).sort((s1, s2) => {
            if (idToAmount[s1] < idToAmount[s2]) {
                return -1;
            } else if (idToAmount[s1] > idToAmount[s2]) {
                return 1;
            } else {
                return 0;
            }
        });
        setSorted(isAsc ? newSorted : newSorted.reverse());
        setIsAsc(!isAsc);
    }
    return (
        <>
            <section className="mb-5">
                <div className="container-fluid">
                    <div className="upload-video py-5">
                        {
                            descriptionToId && Object.keys(descriptionToId).length ?
                                <div className={''}>{prompt}</div> : <></>
                        }
                        <div className={"d-flex flex-row"}>
                            {
                                userId ?
                                    <Link
                                        to={(isCurrentUser(userId) ? "/creator-bounty-creation/" : "/bounty-creation/") + userId + "?redirect=" + window.location.pathname}
                                        className={"w-100 d-flex flex-row justify-content-end cursor-pointer"}>
                                        <i className={"fa fa-plus-circle edit-mode-toggle cursor-pointer"}/>
                                    </Link>
                                    : <></>
                            }

                            <i className={`fa fa-sort cursor-pointer`} style={{marginLeft: '32px'}}
                               onClick={toggleSorted}
                            />
                        </div>
                        <div className="row gx-5 gy-3 d-flex flex-column align-items-center mt-3">
                            {descriptionToId && Object.keys(descriptionToId).length ? <></> :
                                <span>Want to see something? Willing to pay? Create a bounty and when enough people have pledged money, I will do it!</span>}
                            {
                                isAsc ?
                                    sorted.map(bountyDescription => (
                                        <>
                                            <BountyLink
                                                bountyId={descriptionToId[bountyDescription]}
                                                userId={userId}
                                                bountyDescription={bountyDescription}
                                                bountyPrice={idToAmount[descriptionToId[bountyDescription]]}
                                                onClick={() => {
                                                    setDescription(bountyDescription)
                                                    setShowModal(true);
                                                }}
                                            />
                                        </>
                                    ))
                                    :
                                    sorted.map(bountyDescription => (
                                        <>
                                            <BountyLink
                                                bountyId={descriptionToId[bountyDescription]}
                                                userId={userId}
                                                bountyDescription={bountyDescription}
                                                bountyPrice={idToAmount[descriptionToId[bountyDescription]]}
                                                onClick={() => {
                                                    setDescription(bountyDescription)
                                                    setShowModal(true);
                                                }}
                                            />
                                        </>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Bounties;
