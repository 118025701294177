import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const getWithdrawalHistory = () => {
    return getRequest(getBaseUrl() + "/withdrawals").then(defaultResponseHandler);
}

export const createWithdrawal = () => {
    return postRequest(getBaseUrl() + "/withdrawals").then(defaultResponseHandler);
}
