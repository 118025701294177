import React from 'react';
// import {UPLOAD_CATEGORIES} from "../../../Upload/UploadCategories";
import Badge from "../Badges/Badge";

const CategoriesSelector = ({filterValue, setFilterValue, onSidebarSelection, sortedTags}) => {
    const isDarkMode = localStorage.getItem("dark-mode") === "enabled";
    return (
        <div className={"col-lg-1 position-absolute small-screens-hidden web-only"} style={{
            top: "0",
            left: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <div className="ms-auto position-relative search-box d-md-block d-none w-100">
                <i className="fa fa-search color-white" style={{
                    color: localStorage.getItem("dark-mode") === "enabled" ? "white" : "black"
                }}></i>
                <input type="text" className={"search-input w-100 color-white"} placeholder="Filter"
                       style={{
                           textAlign: 'left',
                           marginLeft: "8px",
                           color: localStorage.getItem("dark-mode") === "enabled" ? "white" : "black"
                       }}
                       value={filterValue}
                       onChange={({target}) => setFilterValue(target.value)}
                />
            </div>
            <br/>
            <div className={"w-100 category-selector-container"}
                 style={{
                     maxHeight: "90vh",
                     overflowY: "scroll",
                     width: "100% !important",
                     color: isDarkMode ? "white !important" : "black !important"
                 }}>
                {
                    sortedTags
                        .filter(val => !filterValue || val.toLowerCase().includes(filterValue.toLowerCase()))
                        .map((category, idx) => {
                            return <Badge
                                klass={"transform-on-hover-2 hover-badge"}
                                key={"cat" + idx}
                                handleClick={() => onSidebarSelection(category)}
                                text={category}
                                top={idx * 50 + "px"}
                                color={idx % 3 === 0 ? "purple" : (idx % 3 === 1 ? "blue" : "yellow")}
                                fontSize={category.length >= 8 ?
                                    ((category).length > 12 ? "12px" : "10px")
                                    : "16px"}
                            />
                        })
                }
            </div>
        </div>
    );
}

export default CategoriesSelector;
