import React from 'react';

const defaultFunction = () => {
};

const Badge = ({
                   text = "",
                   color,
                   zIndex,
                   fontSize,
                   handleClick = defaultFunction,
                   onMouseEnter = defaultFunction,
                   onMouseLeave = defaultFunction,
    top,
    klass = ""
               }) => {
    let className = " badge-primary";
    if (color === "purple") {
        className = " badge-tertiary"
    } else if (color === "blue") {
        className = " badge-secondary"
    }
    return (
        <div
            style={{zIndex, textShadow: "none !important", fontSize, top}}
            className={" badge " + className + " " + klass}
            onClick={handleClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <span style={{textShadow: "none"}}>{text}</span>
        </div>
    );
};

export default Badge;
