import {IonContent, IonHeader, IonPage, IonButtons, IonBackButton, IonToolbar, useIonViewDidEnter} from '@ionic/react';
import './CreatorSetup.css';
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {checkIfCurrentUserHasHandle, saveHandle} from "../../requests/Handles/HandleRequests";
import {
    initializeCreatorPreferences, updateSubPrice
} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "../SettingsManagement/ManageStepComponent";

const CreatorSetup = () => {
    const [disabled, setDisabled] = useState();
    const [hasHandleAlready, setHasHandleAlready] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    const [handle, setHandle] = useState();
    const [smsPrice, setSMSPrice] = useState();
    const [subPrice, setSubPrice] = useState();
    const history = useHistory();
    useIonViewDidEnter(() => {
        checkIfCurrentUserHasHandle().then(hasHandle => {
            if (hasHandle) {
                setHasHandleAlready(true);
                setCurrentIdx(1);
            }
        }).catch(_ => {
            alert("Something went wrong");
        })
    }, []);
    const steps = [
        {
            key: 'creator-setup-0',
            label: "What do you want your handle to be?",
            placeholder: "Ex. the_dude",
            value: handle,
            onChange: (e) => setHandle(e.target.value.trim().replace(/[^a-z0-9]/gi, "")),
            inputType: "text",
            onNextClicked: () => {
                const formattedHandle = handle.toLowerCase().trim().replace(/[^a-z0-9]/gi, "");
                if (!formattedHandle) return;
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                history.push("/settings");
            }
        },
        {
            key: 'creator-setup-1',
            label: "What do you want to charge to reply to a text message (no media)? All prices are in US Dollars ($)",
            placeholder: "EX. 50.00",
            value: smsPrice,
            onChange: (e) => setSMSPrice(e.target.value),
            inputType: "number",
            inputMode: 'decimal',
            onNextClicked: () => {
                if (!smsPrice) return;
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                if (hasHandleAlready) {
                    history.push("/settings");
                } else {
                    setCurrentIdx(prev => prev - 1)
                }
            }
        },
        {
            key: 'creator-setup-2',
            label: "Do you want to charge fans to subscribe?",
            placeholder: "Example $50.00. Min. $1",
            value: null,
            trueLabel: "Yes",
            falseLabel: "No, free subs!",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                setSubPrice(0);
                return savePreferences(true).catch(_ => {
                    alert("Something went wrong. Make sure you are charging at least a $1 per message");
                });
            },
            onPrevClicked: () => {
                history.goBack();
            }
        },
        {
            key: 'creator-setup-3',
            label: "Enter your desired subscription price. Min. $5.50. Set to $999,999 if you don't want to deal with subscribers. All prices in US Dollars ($)",
            placeholder: "EX. 50.00",
            value: subPrice,
            onChange: (e) => setSubPrice(e.target.value),
            inputType: "number",
            inputMode: 'decimal',
            onNextClicked: () => {
                return savePreferences(true).catch(_ => {
                    alert("Something went wrong. Make sure you are charging at least a $1 per message");
                });
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1)
            }
        },
        // {
        //     key: 'creator-setup-4',
        //     label: "Do you want non-subscribers to be able to text you? They will still have to pay per message.",
        //     trueLabel: "Yes",
        //     falseLabel: "No",
        //     inputType: "bool",
        //     onTrue: () => {
        //         return savePreferences(true).catch(_ => {
        //             alert("Something went wrong. Make sure you are charging at least a $1 per message");
        //         });
        //     },
        //     onFalse: () => {
        //         return savePreferences(false).catch(_ => {
        //             alert("Something went wrong. Make sure you are charging at least a $1 per message");
        //         });
        //     },
        //     onPrevClicked: () => {
        //         setCurrentIdx(0);
        //     }
        // },
    ];

    const savePreferences = (publicSms) => {
        if (hasHandleAlready) {
            return initializeCreatorPreferences(smsPrice, subPrice, publicSms).then(data => {
                alert("You're all set! You can specify MMS, Request, Video chat and Booking settings if you so desire on the settings page. But to keep things quick, we have just gone through setting up the bare minimum. You can now share your handle with your followers to start earning!")
                setCurrentIdx(0);
                history.push("/settings");
            });
        } else {
            return saveHandle(handle.toLowerCase().trim().replace(/[^a-z0-9]/gi, "")).then(r => {
                return initializeCreatorPreferences(smsPrice, subPrice, publicSms).then(data => {
                    alert("You're all set! You can specify MMS, Request, Video chat and Booking settings if you so desire on the settings page. But to keep things quick, we have just gone through setting up the bare minimum. You can now share your handle with your followers to start earning!")
                    setCurrentIdx(0);
                    history.push("/settings");
                });
            });
        }
    }

    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={"ion-text-center"}>
                <ManageStep step={step} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
};

export default CreatorSetup;
