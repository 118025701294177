import React, {useEffect, useState} from 'react';
import {IonContent} from '@ionic/react';
import MuxVideo from "@mux/mux-video-react";
import {getVideoPlayback} from "../../../../requests/VideosForSale/VideosForSaleRequests";
const ViewVideoDetails = ({message, closeModal}) => {
    const [playbackId, setPlaybackId] = useState();

    useEffect(() => {
        getVideoPlayback(message.metadata.videoId).then(pbId => {
            setPlaybackId(pbId);
        }).catch(_ => {
            alert("Something went wrong. We were unable to retrieve the video.");
            closeModal();
        })
    }, [message])

    if (!playbackId) {
        return <></>;
    }

    return (
        <IonContent>
            <MuxVideo
                style={{ height: '100%', maxWidth: '100%' }}
                playbackId={playbackId}
                streamType="on-demand"
                controls
                autoPlay
                muted
            />
        </IonContent>
    );
}

export default ViewVideoDetails;
