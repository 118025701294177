import {postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const setLocation = (latitude, longitude) => {
    if (!latitude || !longitude) throw 'invalid latitude or longitude';
    return postRequest(getBaseUrl() + "/locations", {latitude, longitude}).then(defaultResponseHandler);
}

export const clearLocation = () => {
    return postRequest(getBaseUrl() + "/locations/clear", {}).then(defaultResponseHandler);
}
