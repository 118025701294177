import {useHistory, useParams} from "react-router-dom";
import React, {useState} from "react";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonItem
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {formatCurrency, getSearchParamOrDefault} from "../../Utils/Utils";
import {createMuxUploadLinkForContentRequest} from "../../requests/ContentRequests/ContentRequests";
import * as UpChunk from "@mux/upchunk";
import {finishBounty} from "../../requests/Bounties/BountyRequests";

const BountyCompletion = ({}) => {
    const history = useHistory();
    const {userId, bountyDesc} = useParams();
    const [file, setFile] = useState();
    const [disabled, setDisabled] = useState();
    const completeBounty = () => {
        if (disabled || !file) return;
        setDisabled(true);
        const redirect = getSearchParamOrDefault("redirect", "/chats");
        const handleError = (_) => {
            setDisabled(false);
            alert("Something went wrong. The request may have expired or you may have selected an invalid file type");
        }
        const handleSuccess = (_) => {
            setDisabled(false);
            setFile(null);
            history.push(redirect)
        }
        // upload to mux
        return finishBounty(bountyDesc).then(directUploadUrl => {
            const upload = UpChunk.createUpload({
                endpoint: directUploadUrl, // Authenticated url
                file: file, // File object with your video file’s properties
                chunkSize: 5120, // Uploads the file in ~5mb chunks
            });
            upload.on('error', handleError);
            upload.on('success', handleSuccess);
        }).catch(handleError);
    }
    const selectFile = () => {
        if (disabled) return;
        document.getElementById("complete-bounty-input").click();
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    Upload a video to finish the bounty and collect the funds
                </IonItem>
                {
                    file ?
                        <video src={file ? URL.createObjectURL(file) : ""}
                               width={'200px'} ø
                               controls={true}
                        />
                        : <></>
                }
                <input className={'display-none'}
                       type={'file'}
                       id={'complete-bounty-input'}
                       onChange={(e) => {
                           setFile(e.target.files[0]);
                       }}

                />
            </IonContent>
            <button className={'btn btn-red-gradient'} onClick={file ? completeBounty : selectFile}>
                {disabled ? "Loading... " : `${file ? "Complete Bounty" : "Select Video"}`}
            </button>
        </IonPage>
    )
}

export default BountyCompletion;
