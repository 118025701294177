import React, {useState} from 'react';
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel, IonModal,
    IonPage,
    IonToolbar
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {formatCurrency} from "../../Utils/Utils";
import {contributeToBounty} from "../../requests/Bounties/BountyRequests";

const BountyContribution = ({}) => {
    const history = useHistory();
    const {userId, bountyDesc} = useParams();
    const [disabled, setDisabled] = useState();
    const [amount, setAmount] = useState();
    const [total, setTotal] = useState();
    const contributeFunds = () => {
        if (!amount || disabled) return;
        if (amount < 5.99) {
            alert("Your contribution is too small");
            return;
        }
        setDisabled(true);
        contributeToBounty(userId, amount, bountyDesc).then(clientSecret => {
            history.replace("/pay/" + clientSecret);
            setDisabled(false);
        }).catch(_ => {
            alert("Something went wrong. Try again!");
            setDisabled(false);
        });
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {/*<IonItem>*/}
                {/*    <IonLabel position="stacked">Description:</IonLabel>*/}
                {/*    {bountyDesc}*/}
                {/*</IonItem>*/}
                <IonItem>
                    <IonLabel position="stacked">Contribution amount?</IonLabel>
                    <IonInput value={amount}
                              onIonChange={e => {
                                  setAmount(e.target.value);
                                  setTotal(e.target.value)
                              }}
                              inputMode={'decimal'}
                              type="number" placeholder="How much to contribute? Min $5.99"/>
                </IonItem>
            </IonContent>
            <button className={'btn btn-red-gradient'} onClick={contributeFunds}>
                {disabled ? "Loading... " : `Contribute ${total ? formatCurrency(total * 100) : ""}`}
            </button>
        </IonPage>
    )
}

export default BountyContribution;
