import {getRequest, postRequest, uploadMultiPartDataPost} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const createContentRequest = (creatorId, body, priceInCents) => {
    return postRequest(getBaseUrl() + `/fan-requests?creatorId=${creatorId}&body=${body}&priceInCents=${priceInCents}`).then(defaultResponseHandler);
}

export const completeRequest = (requestId, file) => {
    return uploadMultiPartDataPost(getBaseUrl() + "/fan-requests/complete/" + requestId, file).then(defaultResponseHandler);
}

export const createMuxUploadLinkForContentRequest = (requestId) => {
    return postRequest(getBaseUrl() + "/fan-requests/large-complete/" + requestId, {}).then(defaultResponseHandler);
}

export const getFanRequestMuxPlayBackId = (requestId) => {
    return getRequest(getBaseUrl() + "/fan-requests/" + requestId, {}).then(defaultResponseHandler);
}
