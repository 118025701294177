import React, {Component} from 'react';
import {IonAvatar, IonImg, IonItem, IonLabel, IonList, IonSearchbar} from "@ionic/react";
import {searchByHandle} from "../../../requests/Search/SearchRequests";
import {getProfilePicturesBaseUrl} from "../../../Utils/Utils";
import SearchResult from "./SearchResult";

class NewChatUserSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            results: []
        }
        this.timeout = null;
    }

    render = () => {
        const {closeModal} = this.props;
        const setSearch = (search) => this.setState({search});
        return (
            <>
                <IonSearchbar
                              onIonChange={(e) => {
                                  setSearch(e.target.value);
                                  if (this.timeout) {
                                      clearTimeout(this.timeout);
                                  }
                                  this.timeout = setTimeout(() => {
                                      searchByHandle(e.target.value).then(data => {
                                        this.setState({results: data || []})
                                      }).catch(_ => {
                                          console.log("ERROR " + JSON.stringify(_));
                                      });
                                  }, 200);
                              }}
                              placeholder="Search by handle"/>
                <IonList>
                    {
                        this.state.results.map(profile => (
                            <SearchResult profile={profile} closeModal={closeModal}/>
                        ))
                    }
                </IonList>
            </>
        )
    }
}

export default NewChatUserSearch;
