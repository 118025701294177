import HeaderLogo from "../../components/Header/HeaderLogo";
import {IonPage, IonHeader, IonContent, useIonViewDidEnter, IonToolbar, IonBackButton, IonButtons} from '@ionic/react';
import {useHistory, useParams} from "react-router-dom";
import {createTip} from "../../requests/Tips/TipRequests";
import React, {useState} from "react";
import {cancelSubscription} from "../../requests/Subscriptions/SubscriptionRequests";

export const CancelSubscription = ({}) => {
    const {id} = useParams();
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className={"flex-column width-80 m-0-auto"}>
                    <br/>
                    <button className={'btn btn-red-gradient'}
                            onClick={() => {
                                if (disabled) return;
                                setDisabled(true);
                                return cancelSubscription(id).then(_ => {
                                    alert("Subscription cancelled!");
                                    history.replace("/chats");
                                    setDisabled(false);
                                }).catch(_ => {
                                    alert("Something went wrong");
                                    setDisabled(false);
                                });
                            }}
                    >
                        {disabled ? "Cancelling..." : "Cancel Your Subscription"}
                    </button>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default CancelSubscription;
