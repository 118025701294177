import React, {useState} from 'react';
import {
    IonPage,
    IonHeader,
    IonContent,
    IonButtons,
    IonBackButton,
    IonToolbar,
    useIonViewDidEnter,
    IonSpinner,
    IonList, useIonViewDidLeave, IonTitle, IonFabButton, IonIcon, IonFab
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {searchByHandle} from "../../requests/Search/SearchRequests";
import SearchResult from "../../components/Chat/NewChatModal/SearchResult";
import {isApp} from "../../Utils/Utils";
import {add, imagesOutline} from "ionicons/icons";

const DO_NOTHING = () => {
};
const SearchPage = () => {
    const history = useHistory();
    const {id} = useParams();
    const [disabled, setDisabled] = useState(true);
    const [results, setResults] = useState([]);
    useIonViewDidEnter(() => {
        return searchByHandle(id).then(data => {
            if (data) {
                setResults(data);
            }
            setDisabled(false);
        }).catch(_ => {
            alert("Something went wrong.")
            history.goBack();
        });
    });
    useIonViewDidLeave(() => {
        setResults([]);
        setDisabled(true);
    })
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {/*<HeaderLogo/>*/}
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                    <IonTitle>{id}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {
                    disabled ?
                        <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
                        :
                        <IonList>
                            {
                                results.map(profile => (
                                    <SearchResult profile={profile} isSearchResultsPage={true} closeModal={DO_NOTHING}/>
                                ))
                            }
                        </IonList>
                }
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton
                        onClick={() => {
                            let join = id.split(" ").map(el => el && (el.charAt(0) === '#' || el.charAt(0) === '@') ? "%23" + el.substring(1) : "%23" + el).join(" ");
                            if (isApp()) {
                                window.open("https://fannumbers.com/search-redirect/" + join, "_blank");
                            } else {
                                history.push("/search-redirect/" + join);
                            }
                        }}>
                        <IonIcon icon={imagesOutline}/>
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    )
}

export default SearchPage;
