import {
    calendarOutline,
    cashOutline,
    notificationsOutline,
    playOutline,
    videocamOutline,
    starHalfOutline,
    trophyOutline
} from 'ionicons/icons';
import {IonIcon} from "@ionic/react";
import {formatCurrency} from "../../../Utils/Utils";

export const SPECIAL_MESSAGE_BUTTON_TEXT = {
    "SMS": "",
    "MMS": "",
    "VIDEO_FOR_SALE": "Click to purchase",
    "VIDEO_CHAT_REQUEST": "View Details",
    "VIDEO_CHAT_ACCEPTANCE": "View Details",
    "BOOKING_REQUEST": "View Details",
    "BOOKING_ACCEPTANCE": "View Details",
    "MARK_ARRIVAL": "Click to mark your arrival (required)",
    "CONFIRM_ARRIVAL": "Click to confirm they've arrived",
    "ARRIVAL_ALL_SET": "Your arrival has been confirmed!",
    "CREATOR_START_VIDEO_CHAT": "Click to start the video chat",
    "FAN_START_VIDEO_CHAT": "Click to join the video chat",
    "FAN_REQUEST": "View Details",
    "FAN_REQUEST_COMPLETED": "I did it! Click to view!",
    "VIDEO_PURCHASED": "Click to watch",
    "SUBSCRIPTION_SUCCEEDED": "You're subscribed!",
    "TIP": "Maybe say thanks?",
    "RATING": "Click to Review",
    "BOUNTY_COMPLETED": "Click to Watch"
};

export const SPECIAL_MESSAGE_ICON = {
    "SMS": <></>,
    "MMS": <></>,
    "VIDEO_FOR_SALE": <IonIcon icon={playOutline} />,
    "VIDEO_CHAT_REQUEST": <IonIcon icon={videocamOutline}/>,
    "VIDEO_CHAT_ACCEPTANCE": <IonIcon icon={videocamOutline}/>,
    "BOOKING_REQUEST": <IonIcon icon={calendarOutline}/>,
    "BOOKING_ACCEPTANCE": <IonIcon icon={calendarOutline}/>,
    "MARK_ARRIVAL": <IonIcon icon={calendarOutline}/>,
    "CONFIRM_ARRIVAL": <IonIcon icon={calendarOutline}/>,
    "ARRIVAL_ALL_SET": <IonIcon icon={calendarOutline}/>,
    "CREATOR_START_VIDEO_CHAT": <IonIcon icon={videocamOutline}/>,
    "FAN_START_VIDEO_CHAT": <IonIcon icon={videocamOutline}/>,
    "FAN_REQUEST": <IonIcon icon={playOutline}/>,
    "FAN_REQUEST_COMPLETED": <IonIcon icon={playOutline}/>,
    "VIDEO_PURCHASED": <IonIcon icon={playOutline}/>,
    "SUBSCRIPTION_SUCCEEDED": <IonIcon icon={notificationsOutline}/>,
    "TIP": <IonIcon icon={cashOutline}/>,
    "RATING": <IonIcon icon={starHalfOutline}/>,
    "BOUNTY_COMPLETED": <IonIcon icon={trophyOutline}/>
};


export const SPECIAL_MESSAGE_LABEL_FUNCTIONS = {
    VIDEO_FOR_SALE: (message) => {
         return `Video for sale`;
    },
    VIDEO_CHAT_REQUEST: (message) => {
         return `Video Chat Requested ${formatCurrency(message.amountPaid)}`;
    },
    VIDEO_CHAT_ACCEPTANCE: (message) => {
         return `Video Chat Accepted`;
    },
    BOOKING_REQUEST: (message) => {
         return `Booking Requested ${formatCurrency(message.amountPaid)}`;
    },
    BOOKING_ACCEPTANCE: (message) => {
         return `Booking accepted`;
    },
    MARK_ARRIVAL: (message) => {
         return `Booking ${formatCurrency(message.amountPaid)}`;
    },
    CONFIRM_ARRIVAL: (message) => {
         return `Booking`;
    },
    ARRIVAL_ALL_SET: (message) => {
         return `Booking Arrival Confirmed`;
    },
    CREATOR_START_VIDEO_CHAT: (message) => {
         return `Video Chat`;
    },
    FAN_START_VIDEO_CHAT: (message) => {
         return `Video Chat`;
    },
    FAN_REQUEST: (message) => {
         return `Content Request`;
    },
    FAN_REQUEST_COMPLETED: (message) => {
         return `Content Request`;
    },
    VIDEO_PURCHASED: (message) => {
         return `Video Purchased`;
    },
    SUBSCRIPTION_SUCCEEDED: (message) => {
         return `Subscription`;
    },
    TIP: (message) => {
         return `Tip ${formatCurrency(message.amountPaid)}`;
    },
    RATING: (message) => {
         return `Leave a Review`;
    },
    BOUNTY_COMPLETED: (message) => {
        return `Bounty Completed`;
    }
};

