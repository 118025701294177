import React from "react";
import {Link} from 'react-router-dom';
import {getProfilePicturesBaseUrl} from "../../../Utils/Utils";
import {
    SPECIAL_MESSAGE_BUTTON_TEXT,
    SPECIAL_MESSAGE_ICON,
    SPECIAL_MESSAGE_LABEL_FUNCTIONS
} from "./SpecialMessageConstants";

const RightChatMessage = ({message, handles}) => {
    const {id, senderId, body, createdAt, media, messageType, metadata} = message;
    return (
        <li id={id}>
            <div className="chat_message message_inner_wrapper outgoing_msg cursor-pointer"
            >
                <Link to={"/p/" + handles[senderId]}>
                    <img className={"chat_picture border-2px-solid-green object-fit-cover"}
                         src={getProfilePicturesBaseUrl() + senderId} alt={""}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = "assets/id.png";
                         }}
                    />
                </Link>
                <div className="time_message_section">
                    <div className="chat_time">
                    </div>
                    <div className="chat_message flex-column justify-content-center"
                         style={{
                             height: '100px', padding: '16px', borderRadius: '12px',
                             border: '2px solid rgba(56,171,252, .95)', background: 'none',
                             color: 'white'
                         }}
                    >
                        <div className={'flex-row align-items-center'} style={{height: '50px'}}>
                            {SPECIAL_MESSAGE_ICON[messageType]}
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            {SPECIAL_MESSAGE_LABEL_FUNCTIONS[messageType](message)}
                        </div>
                        <div className={'underline'} style={{height: '50px'}}>
                            {SPECIAL_MESSAGE_BUTTON_TEXT[messageType]}
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
            </div>
        </li>
    );
}

export default RightChatMessage;
