import React, {useEffect, useState} from 'react';
import {IonAvatar, IonImg, IonItem, IonLabel} from "@ionic/react";
import {formatCurrency, isApp} from "../../Utils/Utils";
import {getFollowerCount, getSubscriberCount} from "../../requests/Subscriptions/SubscriptionRequests";
import {getEarningsBalance} from "../../requests/Earnings/EarningsRequests";
import {useHistory} from "react-router-dom";

const formatSubscriberSummaryMessage = (subCount, hasAtLeastOnePayingCustomer) => {
    if (subCount > 0) {
        return `${subCount} ${isApp() ? 'follower' : 'subscriber'}${subCount > 1 ? 's' : ''}`;
    }
    if (hasAtLeastOnePayingCustomer) {
        return 'Mass Sends';
    }
    return 'Your Account';
}

const SubscriberSummary = ({arbitraryId, hasAtLeastOnePayingCustomer}) => {
    const history = useHistory();
    const [subCount, setSubCount] = useState(-1);
    const [balance, setBalance] = useState("");
    const [hasMoney, setHasMoney] = useState(false);
    useEffect(() => {
        const getCountRequest = isApp() ? getFollowerCount : getSubscriberCount;
        getCountRequest().then(count => {
            console.log("Following Count " + count);
            setSubCount(count);
            getEarningsBalance().then(currentBalance => {
                setBalance(formatCurrency(currentBalance));
                setHasMoney(!!currentBalance);
            }).catch(_ => {
                console.log("Error getEarningsBalance " + JSON.stringify(_));
            });
        }).catch(_ => {
            console.log("Error getSubscriberCount " + JSON.stringify(_));
        });
    }, [arbitraryId]);

    let formattedMessage = formatSubscriberSummaryMessage(subCount, hasAtLeastOnePayingCustomer);

    return (
        <IonItem
            onClick={() => history.push(subCount || hasAtLeastOnePayingCustomer ? '/subscriber-management' : (hasMoney ? '/withdrawals' : '/settings'))}
            className={'cursor-pointer'}>
            <IonAvatar slot="start">
                <IonImg src={'assets/id.png'}/>
            </IonAvatar>
            <IonLabel>
                {subCount === -1 ? <h2 className={"m-l-16px"}/> :
                    <h2 className={"m-l-16px"}>{formattedMessage}</h2>
                }
                <p className={"m-l-16px"}>
                    Balance: {balance}
                </p>
            </IonLabel>
        </IonItem>
    );
}

export default SubscriberSummary;
