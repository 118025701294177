import React, {useEffect, useState} from "react";
import {getCurrentUsersId} from "../../requests/Auth/AuthRequests";
import {getProfilePicturesBaseUrl} from "../../Utils/Utils";
import {directUploadProfilePhoto, updateProfilePhoto} from "../../requests/ProfileImages/ProfileImageRequests";

const ProfilePhoto = () => {
    const [pulsePhoto, setPulsePhoto] = useState();
    const [currentUsersId, setCurrentUsersId] = useState();
    const [imgUrl, setImgUrl] = useState(null);
    useEffect(() => {
        getCurrentUsersId().then(userId => {
            setCurrentUsersId(userId);
        }).catch(_ => {

        });
    }, []);
    if (!currentUsersId) {
        return <></>;
    }
    return (
        <>
            <img className={`profile_photo_circle cursor-pointer ${pulsePhoto ? "pulse" : ""}`}
                 src={!imgUrl ? getProfilePicturesBaseUrl() + currentUsersId + "?dummy=" + new Date().getTime() : imgUrl}
                 onError={({currentTarget}) => {
                     currentTarget.onerror = null;
                     currentTarget.src = "assets/id.png";
                     setPulsePhoto(true);
                 }}
                 style={{
                     marginTop: "16px",
                     width: "120px",
                     height: "120px",
                     borderRadius: "50%",
                     objectFit: 'cover',
                     background: "white"
                 }}
                 onClick={() => {
                     document.getElementById("profile-photo-selector").click();
                 }}
            >
            </img>
            <input type={'file'} className={'hidden'} id={'profile-photo-selector'} onChange={(e) => {
                const file = e.target.files[0];
                return updateProfilePhoto().then(uploadUrl => {
                    return directUploadProfilePhoto(uploadUrl, file).then(_ => {
                        setImgUrl(URL.createObjectURL(file));
                    });
                }).catch(_ => {
                });
            }}/>
        </>
    );
}

export default ProfilePhoto;
