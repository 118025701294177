import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {getProfileBio} from "../../requests/Profile/ProfileRequests";

const ProfileBio = ({userId}) => {
    const [bio, setBio] = useState();
    useEffect(() => {
        getProfileBio(userId).then(biog => {
            setBio(biog ? biog.bio : "");
        }).catch(_ => {

        });
    }, []);
    if (!bio) {
        return <p className="user-description"/>;
    }
    return (
        <p className="user-description">{bio.split(" ").map(term => (
            term.startsWith("#") ?
                <Link key={'term-' + term} to={"/search-redirect/%23" + term.substring(1)}>{term}&nbsp;</Link> :
                <span key={'term-' + term}>{term}&nbsp;</span>
        ))}</p>
    )
}

export default ProfileBio;
