import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const getUsersReferralLinks = () => {
    return getRequest(getBaseUrl() + "/referral-links").then(defaultResponseHandler);
}

export const saveReferralLink = (linkId) => {
    return postRequest(getBaseUrl() + "/referral-links", {linkId}).then(defaultResponseHandler);
}
