import React from 'react';
import {useHistory} from "react-router-dom";
import {IonAvatar, IonIcon, IonImg, IonItem, IonLabel, isPlatform} from "@ionic/react";
import {ellipse} from "ionicons/icons";
import {formatCurrency, getProfilePicturesBaseUrl, isApp} from "../../Utils/Utils";

const ChatSummary = ({conversationId, userId, otherUsersHandle, currentUserId, message, magicLink}) => {
    const history = useHistory();
    const now = new Date().getTime();
    const formattedTime = !message || !message.createdAt ? new Date().toLocaleDateString() : now - (24 * 60 * 60 * 1000) > message.createdAt ? new Date(message.createdAt).toLocaleDateString() : new Date(message.createdAt).toLocaleTimeString();
    const currentUserIsCreator = !conversationId.startsWith("conversation_" + currentUserId);
    const messageMoney = currentUserIsCreator && message && message.amountPaid ? formatCurrency(message.amountPaid) : "";
    const completeMessage = message ? messageMoney + " " + message.body : "Newly Created Conversation";
    return (
        <IonItem key={"chat-summary" + conversationId} onClick={() => {
            const chatToNavigateTo = "/chat/" + conversationId;
            if (isApp()) {
                if (magicLink) {
                    window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + chatToNavigateTo, "_blank")
                }
            } else {
                history.push(chatToNavigateTo)
            }
        }} className={'cursor-pointer'}>
            <IonAvatar slot="start">
                <img src={getProfilePicturesBaseUrl() + userId}
                     onError={({currentTarget}) => {
                         currentTarget.onerror = null;
                         currentTarget.src = "assets/id.png";
                     }}
                />
            </IonAvatar>
            <IonLabel>
                <h2 className={"m-l-16px"}>@{otherUsersHandle}</h2>
                <p className={"m-l-16px"}>
                    {!message || currentUserId === message.senderId ? "You: " : ""}{completeMessage}
                </p>
            </IonLabel>
            {
                message && currentUserId !== message.senderId ?
                    <IonIcon icon={ellipse} style={{color: '#CE283C'}}/>
                    : message ?
                    <p>{formattedTime}</p> : <></>
            }
        </IonItem>
    );
}

export default ChatSummary;
