import React, {useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
const CheckOutForm = ({postSubmit, returnUrl}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || isLoading) {
            return;
        }
        setIsLoading(true);
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
        });

        if (error) {
            alert(error.message);
            setIsLoading(false);
        } else {
            // NOT IMMEDIATE FAILURE
            postSubmit();
            setIsLoading(false);
        }
    };
    return (
        <form id={"cc-wrapper"} onSubmit={handleSubmit}>
            <PaymentElement className={"payment-element-clazz"}/>
            <br/>
            <button className={"btn btn-lg btn-red-gradient rounded-pill"} style={{alignSelf: "center"}} type={"submit"}>{isLoading ? "Loading..." : "Pay"}</button>
        </form>
    );
}

export default CheckOutForm;
