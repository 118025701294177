import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonViewDidEnter} from "@ionic/react";
import {
    getCurrentUsersCreatorPreferences,
    updateSubPrice
} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "./ManageStepComponent";

const ManageSubscriptionPricing = () => {
    const [subPrice, setSubPrice] = useState(5.50);
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    const [hasPriceAlready, setHasPriceAlready] = useState(false);
    useIonViewDidEnter(() => {
        getCurrentUsersCreatorPreferences().then(prefs => {
            setSubPrice(prefs && prefs.twoWayPrice ? prefs.twoWayPrice / 100 : null);
            setHasPriceAlready(prefs && prefs.twoWayPrice);
        }).catch(_ => {
            alert("Something went wrong. We were unable to retrieve your current subscription preferences.");
        })
    }, []);
    const steps = [
        {
            key: "updateSubs0",
            label: "Do you want to charge fans to subscribe?",
            placeholder: "Example $50.00. Min. $1",
            value: null,
            trueLabel: "Yes",
            falseLabel: "No, free subs!",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                return updateSubPrice(0).then(data => {
                    setDisabled(false);
                    history.push("/settings");
                }).catch(_ => {
                    setDisabled(false);
                    alert("Something went wrong. Try again or make sure your price is at least $5.50");
                });
            },
            onPrevClicked: () => {
                history.goBack();
            }
        },
        {
            key: "updateSubs1",
            label: `If users want to subscribe to your mass sends, how much do you want to charge? If you don't want to deal with subscribers, just set the price to $999,999. All prices are in US Dollars ($)${hasPriceAlready ? " Existing subscribers will not be subjected to a price increase" : ""}`,
            placeholder: "Ex: 9.99. Min 5.50 Max 999,999",
            value: subPrice,
            onChange: (e) => setSubPrice(e.target.value),
            inputType: "number",
            inputMode: "decimal",
            onNextClicked: () => {
                if (!subPrice || disabled) return;
                if (isNaN(subPrice) || subPrice < 5.50) {
                    alert("Your subscription price must be at least $5.50")
                    return;
                }
                setDisabled(true);
                return updateSubPrice(subPrice).then(data => {
                    setDisabled(false);
                    history.push("/settings");
                }).catch(_ => {
                    setDisabled(false);
                    alert("Something went wrong. Try again or make sure your price is at least $5.50");
                });
            },
            onPrevClicked: () => {
                setCurrentIdx(0);
            }
        }
    ];
    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>>
            <IonContent fullscreen className={"ion-text-center"}>
                <ManageStep step={step} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
}

export default ManageSubscriptionPricing;
