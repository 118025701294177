import React from 'react';
import {IonPage, IonHeader, IonToolbar, IonContent, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";

const ReferralLinkPage = ({}) => {
    const history = useHistory();
    const {id} = useParams();
    useIonViewDidEnter(() => {
        const existingReferrerUserId = localStorage.getItem("referral-path");
        if (!existingReferrerUserId) {
            localStorage.setItem("referral-path", "" + id);
        }
        setTimeout(() => {
            history.replace("/sign-up");
        }, 200);
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    );
}

export default ReferralLinkPage;
