import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const createSubscription = (creatorId, zipCode) => {
    return postRequest(getBaseUrl() + `/fan-subscriptions?creatorUserId=${creatorId}&zipCode=${zipCode}`).then(defaultResponseHandler);
}

export const followUser = (creatorId) => {
    return postRequest(getBaseUrl() + `/fan-subscriptions/follow?creatorUserId=${creatorId}`).then(defaultResponseHandler);
}

export const checkIsSubscribed = (creatorId) => {
    return getRequest(getBaseUrl() + `/fan-subscriptions/is-subscribed/${creatorId}`).then(defaultResponseHandler);
}

export const checkIsFollowing = (creatorId) => {
    return getRequest(getBaseUrl() + `/fan-subscriptions/is-following/${creatorId}`).then(defaultResponseHandler);
}

export const cancelSubscription = (creatorId) => {
    return postRequest(getBaseUrl() + `/fan-subscriptions/cancel/two-way?creatorId=${creatorId}`).then(defaultResponseHandler);
}

export const getSubscriberCount = (creatorId) => {
    return getRequest(getBaseUrl() + "/fan-subscriptions/count").then(defaultResponseHandler);
}

export const getFollowerCount = (creatorId) => {
    return getRequest(getBaseUrl() + "/fan-subscriptions/follower-count").then(defaultResponseHandler);
}

export const getUsersSubscriptions = () => {
    return getRequest(getBaseUrl() + "/fan-subscriptions/all").then(defaultResponseHandler);
}
