import React, {useState} from 'react';
import {getCurrentUsersHandle} from "../../requests/Handles/HandleRequests";
import {useHistory} from "react-router-dom";
import {isApp} from "../../Utils/Utils";

const ProfilePageButtons = ({currentUserId, magicLink}) => {
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    const [handle, setHandle] = useState();
    const onProfileClick = () => {
        if (disabled) return;
        setDisabled(true);
        getCurrentUsersHandle().then(data => {
            if (data) {
                setHandle(data);
                // window.open("https://fannumbers.com/" + data + "?showLinks=" + showLinks, "_blank");
            }
            setDisabled(false);
        }).catch(_ => {
            setDisabled(false);
        });
    }
    if (!handle) {
        return (
            <div className={'mt-10'}>
                <button className="btn login_btn btn-red-gradient color-white"
                        onClick={onProfileClick}>{"Manage Profile"}
                </button>
            </div>
        );
    }
    return (
        <>
            <div className={'mt-10'}>
                <button className="btn login_btn btn-red-gradient color-white"
                        onClick={() => {
                            // const route = "/link-management/" + currentUserId;
                            const route = "/p/" + handle + "?showLinks=" + "false";
                            if (isApp()) {
                                window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + route, "_blank");
                            } else {
                                history.push(route);
                            }
                        }}>{"View Profile Page"}
                </button>
            </div>
            <div className={'mt-10'}>
                <button className="btn login_btn btn-red-gradient color-white"
                        onClick={() => {
                            const route = "/link-management/" + currentUserId;
                            // const route = "/p/" + handle + "?showLinks=" + "false";
                            if (isApp()) {
                                window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + route, "_blank");
                            } else {
                                history.push(route);
                            }

                        }}>{"Set Profile Links"}
                </button>
            </div>
            <div className={'mt-10'}>
                <button className="btn login_btn btn-red-gradient color-white"
                        onClick={() => {
                            history.push("/manage-banner-photo");
                        }}>{"Set Background Photo"}
                </button>
            </div>
            <div className={'mt-10'}>
                <button className="btn login_btn btn-red-gradient color-white"
                        onClick={() => {
                            history.push("/manage-bio");
                        }}>{"Set Bio"}
                </button>
            </div>
        </>
    );
}

export default ProfilePageButtons;
