import React, {Component, createRef} from "react";
import {add} from "ionicons/icons";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonList,
    IonFabButton,
    IonIcon,
    IonFab,
    withIonLifeCycle,
    IonSpinner,
    IonRefresher,
    IonRefresherContent
} from '@ionic/react';
import './ChatIndex.css';
import {getUsersInbox} from "../../requests/Conversation/ConversationRequests";
import NewChatModal from "../../components/Chat/NewChatModal/NewChatModal";
import ChatSummary from "./ChatSummary";
import SubscriberSummary from "../../components/Subscribers/SubscriberSummary";
import {getUsersSubscriptions} from "../../requests/Subscriptions/SubscriptionRequests";
import {formConversationKey, generateUniqueID, isApp} from "../../Utils/Utils";
import {setLastReadMessage} from "../../requests/LastRead/LastReadMessageRequests";
import {createMagicLink} from "../../requests/MagicLink/MagicLinkRequests";
import SearchbarHeader from "../../components/Header/SearchbarHeader";

class ChatIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.modalRef = createRef();
        this.state = {
            loading: true,
            messages: {},
            userIds: {},
            currentUserId: "",
            hasAtLeastOneConvo: true,
            showSubscriberManagement: false,
            showNewChatModal: false,
            magicLink: null,
            searchVal: ''
        }
    }

    handleRefresh = () => {
        this.setState({
            loading: true,
            messages: {},
            userIds: {},
            currentUserId: "",
            hasAtLeastOneConvo: true,
            showSubscriberManagement: false,
            showNewChatModal: false,
            magicLink: null,
            searchVal: ''
        });
        setTimeout(this.setupPage, 200);
    }

    handleNewMessage = (newMsgJson, convoId) => {
        let copy = this.state.messages;
        if (!copy[convoId]) {
            copy[convoId] = JSON.parse(newMsgJson);
            this.setState({messages: copy});
        } else {
            let msg = JSON.parse(newMsgJson);
            console.log("msg created at " + msg.createdAt + " current created at " + copy[convoId].createdAt + "res " + copy[convoId].createdAt < msg.createdAt);
            if (copy[convoId].createdAt < msg.createdAt) {
                copy[convoId] = msg;
                this.setState({messages: copy});
            }
        }
        return setLastReadMessage().catch(_ => {
        })
    };

    ionViewDidEnter = () => {
        setTimeout(this.setupPage, 1500); // was 5000
    }

    setupPage = () => {
        getUsersInbox().then(inboxes => {
            const {lastMessages, otherUserIds, userId, otherUsersHandles} = inboxes;
            const hasAtLeastOneConvo = Object.keys(otherUserIds).length > 0;
            this.setState({
                messages: lastMessages,
                userIds: otherUserIds,
                currentUserId: userId,
                handles: otherUsersHandles,
                hasAtLeastOneConvo,
                loading: false
            });
            let hasAtLeastOnePayingCustomer = false;
            Object.keys(otherUserIds).forEach(convoId => {
                if (convoId.startsWith("conversation_" + userId)) {
                    const channel = this.props.pusher.subscribe("presence-cache-fo-convo-" + convoId);
                    channel.bind('new-message', (newMessage) => {
                        return this.handleNewMessage(newMessage, convoId);
                    });
                } else {
                    hasAtLeastOnePayingCustomer = true;
                }
                const channel = this.props.pusher.subscribe("presence-cache-convo-" + convoId);
                channel.bind("new-message", (newMessage) => {
                    return this.handleNewMessage(newMessage, convoId);
                });
            });
            this.setState({hasAtLeastOnePayingCustomer})
            const isMobilePlatform = isApp();
            if (isMobilePlatform) {
                try {
                    window.plugins.OneSignal.setExternalUserId(userId, (_) => {
                    });
                } catch (error) {
                }
            }
            getUsersSubscriptions().then(subs => {
                this.setState({showSubscriberManagement: generateUniqueID()});
                let now = new Date().getTime();
                const {subscriptions} = subs;
                Object.keys(subscriptions).forEach(creatorId => {
                    if (subscriptions[creatorId] > now) {
                        const channel = this.props.pusher.subscribe("presence-cache-subscribers-" + creatorId);
                        channel.bind('new-message', (newMessageJSON) => {
                            const conversationKey = formConversationKey(subs.userId, creatorId)
                            return this.handleNewMessage(newMessageJSON, conversationKey);
                        });
                    } else {
                        const channel = this.props.pusher.subscribe("presence-cache-followers-" + creatorId);
                        channel.bind('new-message', (newMessageJSON) => {
                            const conversationKey = formConversationKey(subs.userId, creatorId)
                            return this.handleNewMessage(newMessageJSON, conversationKey);
                        });
                    }
                });
                if (isMobilePlatform) {
                    createMagicLink().then(magicLink => {
                        this.setState({magicLink});
                    }).catch(_ => {

                    });
                }
            }).catch(_ => {
                if (isMobilePlatform) {
                    createMagicLink().then(magicLink => {
                        this.setState({magicLink});
                    }).catch(_ => {

                    });
                }
            });
        }).catch(error => {
        });
    }

    render = () => {
        const {
            messages,
            userIds,
            currentUserId,
            hasAtLeastOneConvo,
            handles,
            showSubscriberManagement,
            loading,
            showNewChatModal,
            magicLink,
            searchVal,
            hasAtLeastOnePayingCustomer
        } = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <HeaderLogo/>
                    </IonToolbar>
                    {
                        !loading ?
                            <SearchbarHeader setSearchVal={(v) => this.setState({searchValue: v})} magicLink={magicLink}/>
                            :
                            <></>
                    }
                </IonHeader>
                <IonContent>
                    {
                        loading ?
                            <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
                            :
                            <>
                                <IonRefresher slot="fixed" onIonRefresh={this.handleRefresh}>
                                    <IonRefresherContent></IonRefresherContent>
                                </IonRefresher>
                                <IonList className={'mt-10'}>
                                    {
                                        showSubscriberManagement ?
                                            <SubscriberSummary arbitraryId={showSubscriberManagement}
                                                               hasAtLeastOnePayingCustomer={hasAtLeastOnePayingCustomer}
                                                               magicLink={magicLink}/>
                                            : <></>
                                    }
                                    {!hasAtLeastOneConvo ?
                                        <p className={"ion-text-center width-80 m-0-auto mt-10"}>You haven't started any
                                            conversations yet. Start a conversation with your favorite experts and stars
                                            by clicking
                                            the '+' and typing their handle.<br/><br/> Here to make money? Click the
                                            'Settings' Tab
                                            to register as a star or to refer stars to the platform in exchange for a
                                            commission.
                                        </p>
                                        :
                                        Object.keys(userIds).sort((conversationId1, conversationId2) => {
                                            if (messages[conversationId1].createdAt < messages[conversationId2].createdAt) {
                                                return -1;
                                            } else if (messages[conversationId1].createdAt > messages[conversationId2].createdAt) {
                                                return 1;
                                            }
                                            return 0;
                                        }).filter((conversationId) => {
                                            const searchValLower = searchVal ? searchVal.toLowerCase() : searchVal;
                                            return (handles[conversationId] && handles[conversationId].includes && handles[conversationId].includes(searchValLower)) ||
                                                (messages[conversationId] && messages[conversationId] && messages[conversationId].body && messages[conversationId].body.toLowerCase().includes(searchValLower))
                                        })
                                            .map((conversationId, idx) => (
                                                <ChatSummary
                                                    key={'chat-summary-' + conversationId}
                                                    magicLink={magicLink}
                                                    userId={userIds[conversationId]}
                                                    otherUsersHandle={handles[conversationId]}
                                                    message={messages[conversationId]}
                                                    currentUserId={currentUserId}
                                                    conversationId={conversationId}
                                                />
                                            ))
                                    }
                                </IonList>
                                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                                    <IonFabButton
                                        onClick={() => {
                                            if (isApp() && magicLink) {
                                                window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + "/chats", "_blank")
                                            } else {
                                                this.setState({showNewChatModal: true});
                                            }
                                        }}>
                                        <IonIcon icon={add}/>
                                    </IonFabButton>
                                </IonFab>
                                <NewChatModal isOpen={showNewChatModal}
                                              arbitraryId={showSubscriberManagement}
                                              close={() => this.setState({showNewChatModal: false})}/>
                            </>
                    }
                </IonContent>
            </IonPage>
        );
    }
}

export default withIonLifeCycle(ChatIndex);
