import React from 'react';
import Badge from "../Badge";
import {getProfilePicturesBaseUrl, replaceAll} from "../../../../Utils/Utils";
import {useHistory} from "react-router-dom";

const MobileBadges = ({removeCategory, tags, liked}) => {
    const history = useHistory();
    return (
        <>
            <div className={"row col-lg-10 col-md-10 col-sm-12 small-screens-only row-of-badges w-100"}
                 style={{
                     margin: "0 auto",
                     justifyContent: "space-between"
                 }}>
                <div className={"row-of-badges mt-3"}>
                    {
                        (tags).map(tag => {
                            return (
                                <Badge key={"mobile-badge-badge-key-" + tag} text={replaceAll(tag,"%23", "#")} handleClick={() => removeCategory(tag)}/>
                            );
                        })
                    }
                </div>
                <div className={"row-of-badges mt-3"}>
                    {
                        (liked).map(liked => {
                            return <img
                                key={(liked ? liked.userId  : "") + "key"}
                                onClick={() => history.push("/p/" + liked.handle, "_blank")}
                                className={"transform-on-hover-5 cursor-pointer"}
                                style={{width: "50px", height: "50px", borderRadius: '50%', objectFit: "cover", padding: '0', margin: "3px"}}
                                src={getProfilePicturesBaseUrl() + liked.userId}/>;
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default MobileBadges;
