import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonPage,
    IonTextarea,
    IonToolbar,
    useIonViewDidEnter
} from "@ionic/react";
import {
    getCurrentUsersCreatorPreferences,
    updateMessagingPreferences, updateRequestPreferences
} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "./ManageStepComponent";

const ManageRequestPreferences = () => {
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [disabled, setDisabled] = useState();
    const [pricePerRequest, setPricePerRequest] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    const handleSuccess = _ => {
        history.push("/settings");
        setDisabled(false);
        setCurrentIdx(0);
    }
    const handleError = _ => {
        alert("Something went wrong. We were unable to update your content request preferences");
        setDisabled(false);
        setCurrentIdx(0);
    }
    useIonViewDidEnter(() => {
        getCurrentUsersCreatorPreferences().then(prefs => {
            if (prefs && prefs.minimumPriceForRequestInCents) {
                setPricePerRequest(prefs.minimumPriceForRequestInCents / 100);
            }
        }).catch(_ => {
            alert("Something went wrong. We were unable to get your current preferences.");
        })
    }, []);

    const steps = [
        {
            key: "updateRequestPref0",
            label: "Do you want to allow users to submit video requests?",
            placeholder: "Example $50.00. Min. $1",
            trueLabel: "Yes",
            falseLabel: "No",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                updateRequestPreferences(-1.0, false).then(handleSuccess).catch(handleError)
            },
            onPrevClicked: () => {
                history.push("/settings");
            }
        },
        {
            key: "updateRequestPref1",
            label: "What is the least you would charge for a video request? Min. $5.50. All prices in US Dollars ($)",
            placeholder: "Example $50.00",
            value: pricePerRequest,
            onChange: (e) => setPricePerRequest(e.target.value),
            inputType: "number",
            inputMode: "decimal",
            onNextClicked: () => {
                if (!pricePerRequest || isNaN(pricePerRequest) || pricePerRequest < 5.5) {
                    alert("You must provide a price that is greater than or equal to 5.50. e.g. 10")
                    return;
                }
                if (disabled) return;
                setDisabled(true);
                updateRequestPreferences(pricePerRequest, true).then(handleSuccess).catch(handleError);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        // {
        //     key: "updateRequestPref2",
        //     label: "Do you want to allow non-subscribers to submit video requests?",
        //     placeholder: "Example $50.00. Min. $1",
        //     trueLabel: "Yes",
        //     falseLabel: "No. Subscribers only",
        //     inputType: "bool",
        //     onTrue: () => {
        //         if (disabled) return;
        //         setDisabled(true);
        //         updateRequestPreferences(pricePerRequest, true).then(handleSuccess).catch(handleError)
        //     },
        //     onFalse: () => {
        //         if (disabled) return;
        //         setDisabled(true);
        //         updateRequestPreferences(pricePerRequest, false).then(handleSuccess).catch(handleError)
        //     },
        //     onPrevClicked: () => {
        //         setCurrentIdx(0);
        //     }
        // }
    ];
    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-text-center"}>
                <ManageStep step={step} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
}

export default ManageRequestPreferences;
