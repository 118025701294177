import React, {useRef, useState} from 'react';
import {IonModal} from '@ionic/react';

const HowItWorksVideo = () => {
    const vidRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    return (
        <>
            <div className={"color-white cursor-pointer translate-y-3px"} onClick={() => {
                vidRef.current.play();
                setIsPlaying(true);
            }}>
                How It Works
            </div>
            <video
                ref={vidRef}
                type={"video/mp4"}
                onClick={() => {
                    setIsPlaying(false);
                    vidRef.current.pause();
                }}
                muted={true}
                src={"https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/FanNumbersHowItWorksRotated.mp4"}
                style={isPlaying ? {
                    position: 'absolute',
                    width: "100vw",
                    height: "100vh",
                    top: 0,
                    left: 0
                } : {width: '0', height: '0', top: 0, left: 0, position: 'absolute'}}
                onPauseCapture={
                    () => {
                        vidRef.current.pause();
                        setIsPlaying(false);
                    }
                }
                onPause={() => {
                    vidRef.current.pause();
                    setIsPlaying(false);
                }}
            />
        </>
    )
}
export default HowItWorksVideo;
