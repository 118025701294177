import {postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {
    defaultResponseHandler,
    getBaseUrl,
    putRequest
} from "../BaseRequests/BaseRequests";

export const updateProfilePhoto = () => {
    return postRequest(getBaseUrl() + "/profile-images/avatar", {}, {}).then(defaultResponseHandler)
}

export const directUploadProfilePhoto = (directUploadUrl, file) => {
    return putRequest(directUploadUrl, file, {'Content-Type': file.type}).then(defaultResponseHandler);
}

export const updateBannerPhoto = () => {
    return postRequest(getBaseUrl() + "/profile-images/banner", {}, {}).then(defaultResponseHandler)
}

export const directUploadBannerPhoto = (directUploadUrl, file) => {
    return putRequest(directUploadUrl, file, {'Content-Type': file.type}).then(defaultResponseHandler);
}
