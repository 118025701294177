import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const getMessageBalance = (conversationId) => {
    return getRequest(getBaseUrl() + "/message-balances/" + conversationId).then(defaultResponseHandler);
}

export const purchaseMessages = (creatorId, tokenCount, isMms, zipCode) => {
    const url = getBaseUrl() + `/message-balances/add-to-balance?zipCode=${zipCode}&creatorId=${creatorId}&tokenCount=${tokenCount}&isMMS=${isMms}`;
    return postRequest(url).then(defaultResponseHandler);
}
