import React, {useState} from 'react';
import {
    IonPage,
    IonContent,
    useIonViewDidEnter
} from '@ionic/react';
import {getProfileBannersBaseUrl} from "../../Utils/Utils";
import {getCurrentUsersId} from "../../requests/Auth/AuthRequests";
import {directUploadBannerPhoto, updateBannerPhoto} from "../../requests/ProfileImages/ProfileImageRequests";
import {useHistory} from "react-router-dom";

const ManageBannerPhoto = () => {
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    const [currentUsersId, setCurrentUsersId] = useState();
    const [file, setFile] = useState();

    const updateBanner = () => {
        if (!file || disabled) return;
        setDisabled(true);
        return updateBannerPhoto().then(directUploadUrl => {
            return directUploadBannerPhoto(directUploadUrl, file).then(_ => {
                alert("Your banner photo has been updated. It may take a few minutes for the changes to be reflected on your end.")
                history.push("/settings");
                setDisabled(false);
            });
        }).catch(_ => {
            alert("Something went wrong. We were unable to update your banner photo");
            history.push("/settings");
            setDisabled(false);
        })
    }

    useIonViewDidEnter(() => {
        getCurrentUsersId().then(userId => {
            setCurrentUsersId(userId);
        }).catch(_ => {

        });
    }, []);
    if (!currentUsersId) {
        return <IonPage></IonPage>;
    }
    return (
        <IonPage>
            <IonContent>
                <img
                    src={file ? URL.createObjectURL(file) : getProfileBannersBaseUrl() + currentUsersId + `?=${new Date().getTime()}`}
                    style={{position: 'fixed', width: '100vw', height: '100vh', objectFit: 'cover'}}
                />
                <div className={"flex-column width-80 position-fixed"} style={{top: '50%', left: 'calc(50% - 100px)'}}>
                    <button className="btn login_btn btn-red-gradient"
                            style={{width: '200px'}}
                            onClick={() => {
                                document.getElementById("hidden-banner-photo-input").click();
                            }}>Select Banner Photo
                    </button>
                    {
                        !file ? <></> :
                            <>
                                <button className="btn login_btn btn-red-gradient mt-10"
                                        style={{width: '200px'}}
                                        onClick={updateBanner}>Save
                                </button>
                            </>
                    }
                    <input
                        id={'hidden-banner-photo-input'}
                        type={'file'} className={'display-none'}
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}

export default ManageBannerPhoto;
