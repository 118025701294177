import {defaultResponseHandler, getBaseUrl, getRequest} from "../BaseRequests/BaseRequests";
import {postRequest} from '../BaseRequests/AuthenticatedBaseRequests';

export const getUsersLinkTree = (userId) => {
    console.log("userId " + userId);
    return getRequest(getBaseUrl() + "/not-link-tree/" + userId).then(defaultResponseHandler);
}

export const updateLinkTree = (links, password) => {
    return postRequest(getBaseUrl() + "/not-link-tree/update", {
        notLinkTree: links,
        password
    }).then(defaultResponseHandler);
}

export const addLinkToLinkTree = (url, title) => {
    return postRequest(getBaseUrl() + "/not-link-tree/add", {url, title}).then(defaultResponseHandler);
}
