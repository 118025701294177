import React, {useState} from 'react';
import TinderCard from "react-tinder-card";
import {useHistory} from "react-router";
import {getProfilePicturesBaseUrl} from "../../Utils/Utils";

const TinderStack = ({
                         profiles,
                         onSwipe,
                         topCardRef,
                         setLastIdx,
                         setProfile
                     }) => {
    const [lastLeftIndex, setLastLeftIndex] = useState(profiles.length);
    const history = useHistory();
    return (
        <>
            <div id={"tinder-stack-component"}
                 style={{
                     position: "relative",
                     left: "calc(50% - 200px)"
                 }}>
                {
                    profiles
                        .map((profile, i) => {
                            return (
                                <TinderCard
                                    data-idx={i}
                                    onCardLeftScreen={() => {
                                        setLastLeftIndex(i)
                                        setProfile(profiles[i - 1]);
                                    }}
                                    ref={i === profiles.length - lastLeftIndex - 1 ? topCardRef : null}
                                    key={`${profile.userId}`}
                                    className={'pressable swipe cursor-pointer ' + (i % 2 === 0 ? " rotate-left " : " rotate-right")}
                                    onSwipe={(dir) => {
                                        onSwipe(dir, i);
                                        setLastLeftIndex(i);
                                    }}>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        // history.push("/" + profile.handle);
                                        return;
                                    }}>
                                        <div style={{
                                            width: "400px",
                                            height: "auto",
                                            position: "absolute",
                                            maxWidth: "400px",
                                            objectFit: "cover"
                                        }}>
                                            <div style={{
                                                width: "400px",
                                                height: "fit-content",
                                                transform: "translate(0%, 100%)",
                                                textAlign: "center"
                                            }}>
                                                <h2 className={"meme"} style={{
                                                    color: "white",
                                                    background: "black",
                                                    borderTopLeftRadius: "30px",
                                                    borderTopRightRadius: "30px"

                                                }}>@{profile.handle}</h2>
                                            </div>
                                            <img
                                                className={"transform-on-hover"}
                                                draggable={false}
                                                src={getProfilePicturesBaseUrl() + profile.userId}
                                                style={{
                                                    width: "inherit",
                                                    height: "inherit",
                                                    borderRadius: "30px",
                                                    maxHeight: "250px",
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </TinderCard>
                            );
                        })
                }

            </div>
        </>
    );
}

export default TinderStack;
