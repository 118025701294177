import {isPlatform} from "@ionic/react";
import {Clipboard} from '@capacitor/clipboard';

export const getSearchParams = () => {
    return new URLSearchParams(window.location.search);
}

export const getSearchParamOrDefault = (paramName, defaultVal) => {
    return getSearchParams().get(paramName) || defaultVal;
}

export const getProfilePicturesBaseUrl = () => {
    if (process.env.REACT_APP_ENV_TYPE === "PRODUCTION") {
        return "https://fan-numbers-v2-profile-photos-prod.s3.amazonaws.com/";
    }
    return "https://fan-numbers-v2-profile-photos-prod.s3.amazonaws.com/";
}

export const getProfileBannersBaseUrl = () => {
    if (process.env.REACT_APP_ENV_TYPE === "PRODUCTION") {
        return "https://fan-numbers-v2-banner-photos-prod.s3.amazonaws.com/";
    }
    return "https://fan-numbers-v2-banner-photos-prod.s3.amazonaws.com/";
}

export const getCreditCardPublicKey = () => {
    // if (process.env.REACT_APP_ENV_TYPE === "PRODUCTION") {
    //     console.log("Prod card public key");
    return "pk_live_51MVuUlImY9kvWEshwhhP7QDzZvEWJmKzXqXc9mp1BXvrkIReVE3cvbJkKcgW3axzi9XwCBE0FsJrGEVEJJUu4BMG00HNdNeYhI";
    // }
    // return "pk_live_51MVuUlImY9kvWEshwhhP7QDzZvEWJmKzXqXc9mp1BXvrkIReVE3cvbJkKcgW3axzi9XwCBE0FsJrGEVEJJUu4BMG00HNdNeYhI";
    // console.log("Dev credit card public key");

    // return "pk_test_51MVuUlImY9kvWEsh2QAYEGdpCWC9SuKfxQkOJuipj2I2xWPrDjZWRYFdbxJMiUkCd2zHvUNwsbvJYvj5L1hUMQiO00Ys1thPO4";
}

export const generateUniqueID = () => {
    const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export const formatDuration = (durationInSeconds) => {
    if (!durationInSeconds || isNaN(durationInSeconds) || durationInSeconds === -1) {
        return "N/A";
    }
    durationInSeconds = parseFloat(durationInSeconds);
    let formattedDuration = "";
    if (durationInSeconds >= (24 * 3600)) {
        let days = Math.floor(durationInSeconds / (3600 * 24));
        durationInSeconds = durationInSeconds - (days * (3600 * 24));
        return formattedDuration += (days + `day${days > 1 ? "s" : ""} `)
    }
    if (durationInSeconds >= 3600) {
        let hrs = Math.floor(durationInSeconds / 3600);
        durationInSeconds = durationInSeconds - hrs * 3600;
        if (hrs > 1) {
            formattedDuration += (hrs + "hrs ");
        } else {
            formattedDuration += (hrs + "hr ");
        }
        return formattedDuration;
    }
    if (durationInSeconds > 60) {
        let mins = Math.floor(durationInSeconds / 60);
        durationInSeconds = durationInSeconds - mins * 60;
        if (mins > 0) {
            formattedDuration += (mins + "min ");
        }
        return formattedDuration;
    }
    if (durationInSeconds >= 1) {
        formattedDuration += (durationInSeconds.toFixed(0) + "s");
    }
    if (formattedDuration) {
        return formattedDuration;
    }
    return 'N/A';
}

export const getFrontendBaseUrl = () => {
    if (process.env.REACT_APP_ENV_TYPE === "STAGING" || process.env.REACT_APP_ENV_TYPE === "PRODUCTION") {
        return "https://fannumbers.com"
    }
    return "http://localhost:8100";
}


export const formatCurrency = (amountInCents) => {
    console.log("Format Currency " + amountInCents);
    return (amountInCents / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export const getPusherAppKey = () => {
    return '48f6d89a5af548140c99';
}

export const toTimeString = (time) => {
    return new Date(time).toLocaleDateString() + " " + new Date(time).toLocaleTimeString();
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const replaceAll = (ogStr, find, replaceWith) => {
    return ogStr.replace(new RegExp(escapeRegExp(find), 'g'), replaceWith);
}

export const uniqueArr = (arr = []) => {
    let seen = {};
    let filtered = [];
    arr.forEach(el => {
        if (!seen[el]) {
            seen[el] = true;
            filtered.push(el);
        }
    });
    return filtered;
}

export const uniqueArrWithKeyFunction = (arr = [], fx) => {
    let seen = {};
    let filtered = [];
    arr.forEach(el => {
        let key = fx(el);
        if (!seen[key]) {
            seen[key] = true;
            filtered.push(el);
        }
    });
    return filtered;
}

export const formConversationKey = (fanUserId, creatorUserId) => {
    return "conversation_" + fanUserId + "__" + creatorUserId;
}

export const isApp = () => {
    return !(isPlatform("mobileweb") || isPlatform("desktop"));
}

const validUrlAndHandleChars = {
    "a": true,
    "b": true,
    "c": true,
    "d": true,
    "e": true,
    "f": true,
    "g": true,
    "h": true,
    "i": true,
    "j": true,
    "k": true,
    "l": true,
    "m": true,
    "n": true,
    "o": true,
    "p": true,
    "q": true,
    "r": true,
    "s": true,
    "t": true,
    "u": true,
    "v": true,
    "w": true,
    "x": true,
    "y": true,
    "z": true,
    ".": true,
    "-": true
};

export const isValidReferralLinkOrHandle = (link) => {
    if (!link || !link.trim()) return false;
    let c;
    for (let i = 0; i < link.length; i++) {
        c = link[i];
        if (!validUrlAndHandleChars[c]) {
            return false;
        }
    }
    return true;
}

export const isLoggedIn = () => {
    let tokenJson = localStorage.getItem("session-token");
    if (!tokenJson) {
        return false;
    } else {
        let token = JSON.parse(tokenJson);
        if (token.expiration < new Date().getTime()) {
            return false;
        } else {
            return true;
        }
    }
}

export const isCurrentUser = (userId) => {
    let tokenJson = localStorage.getItem("session-token");
    if (!tokenJson) {
        return false;
    } else {
        let token = JSON.parse(tokenJson);
        return token && token.token && token.token.startsWith(userId);
    }
}

export const writeToClipboard = (str) => {
    return Clipboard.write({
        string: str
    });
};
