import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const createVideoForSale = (price, messageBody, recipient = "") => {
    return postRequest(getBaseUrl() + "/videos-for-sale", {price, messageBody, recipient}).then(defaultResponseHandler);
}

export const getVideoForSale = (videoId) => {
    return getRequest(getBaseUrl() + "/videos-for-sale/" + videoId).then(defaultResponseHandler);
}

export const purchaseVideo = (videoId, zipCode) => {
    return postRequest(getBaseUrl() + "/videos-for-sale/purchase/" + videoId + "?zipCode=" + zipCode).then(defaultResponseHandler);
}

export const getVideoPlayback = (videoId) => {
    return getRequest(getBaseUrl() + "/videos-for-sale/playback/" + videoId).then(defaultResponseHandler);
}
