import React from 'react';
import {
    IonIcon,
    IonFabButton,
} from "@ionic/react";
import {
    calendarOutline,
    cashOutline,
    playOutline,
    videocamOutline,
    logoBitcoin, notificationsOutline, notificationsOffOutline
} from "ionicons/icons";
import {useHistory} from "react-router-dom";
import {isApp} from "../../Utils/Utils";

const AdditionalOptionsFab = ({
                                  creatorId,
                                  allowsBookings,
                                  allowsVideoChats,
                                  allowsContentRequests,
                              }) => {
    const history = useHistory();
    return (
        <>
            {
                allowsBookings ?
                    (
                        <IonFabButton
                            className={'mt-1'}
                            onClick={() => {
                                if (isApp()) {
                                    window.open(`https://fannumbers.com/create-chat-page/${creatorId}?redirect=${'/schedule-booking/' + creatorId}`, "_blank")
                                } else {
                                    history.push(`/create-chat-page/${creatorId}?redirect=${'/schedule-booking/' + creatorId}`);
                                }
                            }}>
                            <IonIcon icon={calendarOutline}/>
                        </IonFabButton>
                    )
                    :
                    <></>
            }
            {
                allowsVideoChats ?
                    (
                        <IonFabButton
                            className={'mt-1'}
                            onClick={() => {
                                if (isApp()) {
                                    window.open(`https://fannumbers.com/create-chat-page/${creatorId}?redirect=${"/schedule-video-chat/" + creatorId}`, "_blank")
                                } else {
                                    history.push(`/create-chat-page/${creatorId}?redirect=${"/schedule-video-chat/" + creatorId}`);
                                }
                            }}>
                            <IonIcon icon={videocamOutline}/>
                        </IonFabButton>
                    )
                    : <></>
            }
            {
                allowsContentRequests ?
                    <IonFabButton
                        className={'mt-1'}
                        onClick={() => {
                            if (isApp()) {
                                window.open(`https://fannumbers.com/create-chat-page/${creatorId}?redirect=${"/request-content/" + creatorId}`, "_blank")
                            } else {
                                history.push(`/create-chat-page/${creatorId}?redirect=${"/request-content/" + creatorId}`);
                            }
                        }}>
                        <IonIcon icon={playOutline}/>
                    </IonFabButton>
                    :
                    <></>
            }
            <IonFabButton
                className={'mt-1'}
                onClick={() => {
                    if (isApp()) {
                        window.open(`https://fannumbers.com/create-chat-page/${creatorId}?redirect=${"/tips/" + creatorId}`, "_blank")
                    } else {
                        history.push(`/create-chat-page/${creatorId}?redirect=${"/tips/" + creatorId}`);
                    }
                }}>
                <IonIcon icon={cashOutline}/>
            </IonFabButton>
        </>
    );
}

export default AdditionalOptionsFab;
