import {IonPage, IonContent} from '@ionic/react';
import './Webhome.css';
import {getFrontendBaseUrl} from "../../Utils/Utils";
import AppStoreAvailability from "../../components/Simulator/AppStoreAvailability";
import {getIsAvailableOnAndroid, getIsAvailableOnIos} from "../../requests/AppAvailability/AppAvailabilityRequests";
import {useState} from "react";

const Simulator = () => {
    const [hover, setHover] = useState(true);
    return (
        <IonPage>
            <IonContent>
                <div id="index">
                    <div className="desktop d-lg-block ">
                        <div className="container">
                            <div
                                className="row h-100vh align-items-center flex-row ion-justify-content-around ion-align-items-center">
                                <div className="col-lg-6 flex-column ion-justify-content-between small-screens-hidden">
                                    <AppStoreAvailability
                                        link={"https://apps.apple.com/us/app/fannumbers/id6447013965"}
                                        // imgUrl={"https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/app-store.png"}
                                        imgUrl={"https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/coming-to-the-app-store.png"}
                                        getRequest={getIsAvailableOnIos}
                                    />
                                    <AppStoreAvailability
                                        // imgUrl={"https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/play-store.png"}
                                        imgUrl={"https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/coming-to-the-play-store.png"}
                                        getRequest={getIsAvailableOnAndroid}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <div className={`phone ${hover ? 'floating' : ''}`}
                                        onMouseLeave={() => setHover(true)}
                                         onMouseEnter={() => setHover(false)}>
                                        <iframe src={getFrontendBaseUrl() + "/chats"} frameBorder="0"
                                                className="phone-frame"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Simulator;
