import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {getProfilePicturesBaseUrl} from "../../../Utils/Utils";
import SpecialMessageLeft from "./SpecialMessageLeft";

const LeftChatMessage = ({message, setShowDetailsModal, handles = {}}) => {
    const {id, senderId, body, createdAt, media, messageType, metadata} = message;
    const [isVideo, setIsVideo] = useState(false);
    const isNotTextMessage = messageType && messageType !== "MMS" && messageType !== "SMS";
    if (isNotTextMessage) {
        return <SpecialMessageLeft message={message} setShowDetailsModal={setShowDetailsModal} handles={handles}/>
    }
    return (
        <li id={id}>
            {
                media && !isVideo ?
                    <img src={media} className={"mb-3 width-85"}
                         onClick={() => {
                             window.open(media, "_blank");
                         }}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             setIsVideo(true);
                         }}
                    />
                    :
                    media && isVideo ?
                        <video
                            controls={true}
                            className={"mb-3 width-85"}
                        >
                            <source src={media} type="video/mp4"></source>
                        </video>
                        :
                        <></>
            }
            <div className="chat_message message_inner_wrapper incoming_msg">
                <Link to={"/p/" + handles[senderId]}>
                    <img className={"chat_picture border-2px-solid-green object-fit-cover"}
                         src={getProfilePicturesBaseUrl() + senderId} alt={""}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = "assets/id.png";
                         }}
                    />
                </Link>
                <div className="time_message_section">
                    <div className="chat_time"></div>
                    <div className="chat_message"
                         style={{border: '2px solid white', padding: '12px', borderRadius: '12px'}}>
                        {body}
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
            </div>
            <br/>
        </li>
    );
}

export default LeftChatMessage;
