import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonInput,
    IonPage,
    IonTextarea,
    useIonViewDidEnter,
    IonSpinner, IonItem, IonButton, IonItemDivider, IonList, IonButtons, IonBackButton
} from "@ionic/react";
import {
    getCurrentUsersCreatorPreferences,
    updateBookingPreferences,
} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "./ManageStepComponent";

const ManageBookingPreferences = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState();
    const [priceFH, setPriceFH] = useState();
    const [priceCH, setPriceCH] = useState();
    const [minDurationFH, setMinDurationFH] = useState();
    const [minDurationCH, setMinDurationCH] = useState();
    const [bookingQuestions, setBookingQuestions] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    const handleSuccess = _ => {
        history.push("/settings");
        setDisabled(false);
        setCurrentIdx(0);
    }
    const handleError = _ => {
        alert("Something went wrong. We were unable to update your booking preferences.");
        setDisabled(false);
        setCurrentIdx(0);
    }
    useIonViewDidEnter(() => {
        getCurrentUsersCreatorPreferences().then(prefs => {
            setLoading(false);
            setPriceCH(prefs.creatorHostedBookingPricePerTimeUnitInCents ? prefs.creatorHostedBookingPricePerTimeUnitInCents / 100 : undefined);
            setMinDurationCH(prefs.minCreatorHostedBookingDuration);
            setPriceFH(prefs.fanHostedBookingPricePerTimeUnitInCents ? prefs.fanHostedBookingPricePerTimeUnitInCents / 100 : undefined);
            setMinDurationFH(prefs.minFanHostedBookingDuration);
        }).catch(_ => {
            alert("Something went wrong. We were unable to update your preferences.");
        })
    }, []);

    const steps = [
        {
            key: "allowScheduleBookings",
            label: "Do you want to allow users to schedule bookings?",
            placeholder: "Example $50.00. Min. $1",
            trueLabel: "Yes",
            falseLabel: "No",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                updateBookingPreferences(-1.0, -1.0, -1.0, -1.0, false).then(handleSuccess).catch(handleError);
            },
            onPrevClicked: () => {
                history.push("/settings");
            }
        },
        {
            key: "allowScheduleBookings2",
            label: "Do you want to allow users to schedule bookings that you will host?",
            trueLabel: "Yes",
            falseLabel: "No",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                setCurrentIdx(prev => prev + 3);
                setPriceCH(-1);
                setMinDurationCH(-1);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        {
            key: "allowScheduleBookings3",
            label: "What is the shortest appointment you will accept when you will be the host? Min 15 minutes.",
            placeholder: "Ex: 60 for 60 min",
            value: minDurationCH,
            onChange: (e) => setMinDurationCH(e.target.value),
            inputType: "number",
            inputMode: "numeric",
            onNextClicked: () => {
                if (!minDurationCH || isNaN(minDurationCH) || minDurationCH < 15) {
                    alert("Duration must be at least 15 minutes. e.g. 15")
                    return;
                }
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        {
            key: "allowScheduleBookings4",
            label: `What would you charge for that amount of time when you are hosting? All prices are in US Dollars ($)`,
            placeholder: "Ex: 50 for $50",
            value: priceCH,
            onChange: (e) => setPriceCH(e.target.value),
            inputType: "number",
            inputMode: "decimal",
            onNextClicked: () => {
                if (!priceCH || isNaN(priceCH) || priceCH / minDurationCH < .5) {
                    alert("Price must be at least $0.50 per minute. e.g. " + (minDurationCH * .5))
                    return;
                }
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        {
            key: "allowScheduleBookings5",
            label: "Do you want to allow users to schedule bookings that they will host?",
            value: minDurationCH,
            trueLabel: "Yes",
            falseLabel: "No",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                setCurrentIdx(prev => prev + 3);
                setPriceFH(-1);
                setMinDurationFH(-1);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 3);
            }
        },
        {
            key: "allowScheduleBookings6",
            label: "What is the shortest appointment you will accept when the fan will host? Min 15 minutes.",
            placeholder: "Ex: 60 for 60 min",
            value: minDurationFH,
            onChange: (e) => setMinDurationFH(e.target.value),
            inputType: "number",
            inputMode: "numeric",
            onNextClicked: () => {
                if (!minDurationFH || isNaN(minDurationFH) || minDurationFH < 15) {
                    alert("Duration must be at least 15 minutes. e.g. 15")
                    return;
                }
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        {
            key: "allowScheduleBookings7",
            label: `What would you charge for that amount of time when the fan is hosting? All prices are in US Dollars ($)`,
            placeholder: "Ex: 50 for $50",
            value: priceFH,
            onChange: (e) => setPriceFH(e.target.value),
            inputType: "number",
            inputMode: "decimal",
            onNextClicked: () => {
                if (!priceFH || isNaN(priceFH) || priceFH / minDurationFH < .5) {
                    alert("Price must be at least $0.50 per minute e.g. " + (.5 * minDurationFH))
                    return;
                }
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        {
            key: "allowScheduleBookings8",
            label: "Do you want to require the user to answer any questions? If so, specify your questions below",
            placeholder: "Ex: What type of venue? How many people? Could you link your LinkedIn?",
            value: bookingQuestions,
            inputType: "textarea",
            onChange: (e) => setBookingQuestions(e.target.value),
            onNextClicked: () => {
                if (disabled) return;
                setDisabled(true);
                updateBookingPreferences(priceFH, priceCH, minDurationCH, minDurationFH, true, bookingQuestions).then(handleSuccess).catch(handleError);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 3);
            }
        },
        // {
        //     key: "allowScheduleBookings9",
        //     label: "Do you want to allow non-subscribers to schedule bookings?",
        //     trueLabel: "Yes",
        //     falseLabel: "No. Subscribers only",
        //     inputType: "bool",
        //     onTrue: () => {
        //         if (disabled) return;
        //         setDisabled(true);
        //         updateBookingPreferences(priceFH, priceCH, minDurationCH, minDurationFH, true, bookingQuestions).then(handleSuccess).catch(handleError);
        //     },
        //     onFalse: () => {
        //         if (disabled) return;
        //         setDisabled(true);
        //         updateBookingPreferences(priceFH, priceCH, minDurationCH, minDurationFH, false, bookingQuestions).then(handleSuccess).catch(handleError);
        //     },
        //     onPrevClicked: () => {
        //         setCurrentIdx(prev => prev - 1);
        //     }
        // }
    ];
    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-text-center"}>
                <ManageStep step={step} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
}

export default ManageBookingPreferences;
