import React, {useState} from 'react';
import {
    IonPage,
    IonToolbar,
    IonHeader,
    IonContent,
    IonBackButton,
    IonButtons,
    IonList,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonIcon,
    IonItem,
    IonLabel,
    IonReorderGroup,
    IonReorder,
    useIonViewDidEnter,
    IonFabButton,
    IonFab
} from "@ionic/react";
import {
    add,
    trash,
    openOutline,
    saveOutline,
} from 'ionicons/icons';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {getUsersLinkTree, updateLinkTree} from "../../requests/LinkTree/LinkTreeRequests";
import {isApp, uniqueArr} from "../../Utils/Utils";

const updateArrayOrder = (from, to, arr) => {
    let newArr = [];
    for (let i = 0; i < to; i++) {
        newArr.push(arr[i]);
    }
    newArr.push(arr[from]);
    for (let i = to; i < arr.length; i++) {
        if (i === from) {
            continue;
        }
        newArr.push(arr[i]);
    }
    return newArr;
}

const LinksManagementPage = ({}) => {
    const history = useHistory();
    const {id} = useParams();
    const [order, setOrder] = useState([]);
    const [newOrder, setNewOrder] = useState([]);
    const [newLinks, setNewLinks] = useState({});
    const [links, setLinks] = useState({});
    const [deleted, setDeleted] = useState({});
    const [hasChanged, setHasChanged] = useState();
    const [disabled, setDisabled] = useState();
    useIonViewDidEnter(() => {
        getUsersLinkTree(id).then(notALink => {
            setLinks(notALink.linkIdToLink);
            setOrder(notALink.order);
            setNewLinks(notALink.linkIdToLink);
            setNewOrder(notALink.order);
        }).catch(_ => {
            console.log("ERROR " + JSON.stringify(_));
        });
    });

    const handleReorder = (event) => {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
        const {from, to} = event.detail;
        setNewOrder(prev => uniqueArr(updateArrayOrder(from, to, prev)));
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        setHasChanged(true);
        event.detail.complete();
    }

    const removeLink = (linkUrl) => {
        setNewOrder(newOrder.filter(item => item !== linkUrl));
        setHasChanged(true);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                        {
                            newOrder.map((linkId) => {
                                return (
                                    <IonItemSliding key={linkId}>
                                        <IonItemOptions side="start">
                                            <IonItemOption color="secondary" onClick={() => {
                                                removeLink(linkId);
                                            }}>
                                                <IonIcon slot="icon-only" icon={trash}/>
                                            </IonItemOption>
                                            <IonItemOption color="primary" onClick={() => {
                                                window.open(linkId, "_blank");
                                            }}>
                                                <IonIcon slot="icon-only" icon={openOutline}/>
                                            </IonItemOption>
                                        </IonItemOptions>
                                        <IonItem>
                                            <IonLabel>{links[linkId].linkText}</IonLabel>
                                            <IonReorder slot="end"/>
                                        </IonItem>
                                    </IonItemSliding>
                                )
                            })
                        }
                    </IonReorderGroup>
                </IonList>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton
                        onClick={() => {
                            if (hasChanged && !disabled) {
                                setDisabled(true);
                                const linksFiltered = {};
                                newOrder.map(linkId => linksFiltered[linkId] = newLinks[linkId]);
                                updateLinkTree({linkIdToLink: linksFiltered, order: newOrder}).then(r => {
                                    setOrder(newOrder);
                                    setLinks(newLinks);
                                    setDisabled(false);
                                    setHasChanged(false);
                                }).catch(_ => {
                                    setDisabled(false);
                                    alert("Something went wrong. Try again.");
                                });
                            } else if (!disabled) {
                                history.push("/link-creation/" + id);
                            }
                        }}>
                        {
                            disabled ? <></> : <IonIcon icon={hasChanged ? saveOutline : add}/>
                        }
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
}

export default LinksManagementPage;
