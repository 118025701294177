import React, {useEffect, useState} from 'react';
import {getRegistrationLink} from "../../requests/ConnectedAccount/ConnectedAccountRequests";

const WithdrawalRegistrationButton = ({}) => {
    const [link, setLink] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        getRegistrationLink().then(regLink => {
            setLink(regLink);
        }).catch(_ => {
            setError(true);
        });
    }, []);
    if (!link) return <></>;
    return (
        <button className="btn login_btn btn-red-gradient width-100"
                onClick={() => {
                    window.open(link, "_blank");
                }}>
            {
                error ? "Something went wrong" :
                    "Register with Stripe to Withdrawal"
            }
        </button>
    );
}

export default WithdrawalRegistrationButton;
