import React, {useState} from 'react';
import {IonContent, IonItem} from "@ionic/react";
import {rateStar} from "../../../../requests/Rating/RatingRequests";

const RatingDetails = ({message, closeModal}) => {
    const [disabled, setDisabled] = useState(false);
    const {metadata} = message;
    const {ratingType, id} = metadata;
    const rateUser = (wouldRepeat) => {
        if (disabled) return;
        setDisabled(true);
        return rateStar(id, wouldRepeat, ratingType).then(_ => {
            setDisabled(false);
            closeModal();
        }).catch(_ => {
            alert("Something went wrong. You may have already rated your experience");
            setDisabled(false);
        })
    }
    return (
        <IonContent>
            <IonItem>
                Review:
            </IonItem>
            <IonItem>
                If you could go back in time and all other factors were the same, would you do it again?
            </IonItem>
            <br/>
            {disabled ? <></> :
                <>
                    <button className="btn login_btn btn-red-gradient width-100"
                            onClick={() => rateUser(true)}>
                        {`Would repeat`}
                    </button>
                    <br/>
                    <br/>
                    <button className="btn login_btn btn-red-gradient width-100"
                            onClick={() => rateUser(false)}>
                        {"Would not repeat"}
                    </button>
                    <br/>
                    <br/>
                </>
            }
        </IonContent>
    );
}

export default RatingDetails;
