import React from 'react';
import Settings from "./pages/Settings/Settings";
// import AgeVerification from "./pages/AgeVerification/AgeVerification";
import AgeVerificationIntro from "./pages/AgeVerification/AgeVerificationIntro";
import Chat from "./pages/Chat/Chat";
import ChatIndex from "./pages/ChatIndex/ChatIndex";
import RedirectPage from "./pages/RedirectPage/RedirectPage";
import CreatorSetup from "./pages/CreatorSetup/CreatorSetup";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import ManageBio from "./pages/SettingsManagement/ManageBio";
import ManageMessagingPreferences from "./pages/SettingsManagement/ManageMessagingPreferences";
import ManageSubscriptionPricing from "./pages/SettingsManagement/ManageSubscriptionPricing";
import ManageRequestPreferences from "./pages/SettingsManagement/ManageRequestPreferences";
import ManageVideoChatPreferences from "./pages/SettingsManagement/ManageVideoChatPreferences";
import ManageBookingPreferences from "./pages/SettingsManagement/ManageBookingPreferences";
import ProfilePage from "./pages/Profile/ProfilePage";
import ManageBannerPhoto from "./pages/SettingsManagement/ManageBannerPhoto";
import CreateChatPage from "./pages/CreateChat/CreateChatPage";
import AgeVerificationCode from "./pages/AgeVerification/AgeVerificationCode";
import ProfilePageRedirect from "./pages/ProfilePage/ProfilePageRedirect";
import TipsPage from "./pages/Gratuities/Gratuities";
import CreditCardPage from "./pages/Credit/CreditCardPage";
import CreateSubscription from "./pages/CreateSubscription/CreateSubscription";
import CancelSubscription from "./pages/CancelSubscription/CancelSubscriptionPage";
import ScheduleBooking from "./pages/ScheduleBooking/ScheduleBooking";
import ScheduleVideoChat from "./pages/ScheduleVideoChat/ScheduleVideoChat";
import ContentRequestsPage from "./pages/ContentRequests/ContentRequests";
import Logout from "./pages/Logout/Logout";
import CryptoGratuities from "./pages/Gratuities/CryptoGratuities";
import DailyHostedVideoChat from "./pages/HostedVideoChat/DailyHostedVideoChat";
import SubscriberManagement from "./pages/SubscriberManagement/SubscriberManagement";
import SellVideoPage from "./pages/SellVideo/SellVideo";
import WithdrawalsPage from "./pages/Withdrawal/WithdrawalPage";
import Simulator from "./pages/Simulator/Simulator";
import AppRedirectPage from "./pages/AppRedirect/AppRedirectPage";
import IdentityRedirectPage from "./requests/IdentityRedirect/IdentityRedirectPage";
import ReferralLinkPage from "./pages/ReferralLinks/ReferralLinkPage";
import ReferralLinkManagementPage from "./pages/ReferralLinkManagement/ReferralLinkManagementPage";
import TinderStyleSearch from "./components/TinderStyleSearch/TinderStyleSearch";
import SearchRedirect from "./components/Search/SearchRedirect";
import PaymentFinalSteps from "./pages/PaymentFinalSteps/PaymentFinalStepsPage";
import ManageLocationPage from "./pages/Location/ManageLocation";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import TermsAndConditions from "./pages/Legal/TermsAndConditions";
import LegalPage from "./pages/Legal/LegalPage";
import FollowUserPage from "./pages/FollowUser";
import HealthCheckPage from "./pages/HealthCheck/HealthCheckPage";
import ClearLocationPage from "./pages/Location/ClearLocation";
import EnsureHandle from "./pages/Handle/EnsureHandle";
import LinksManagementPage from "./pages/Links/BetterLinks";
import LinkCreationPage from "./pages/Links/BetterLinkCreation";
import BountyCreationPage from "./pages/Bounties/BountyCreation";
import CreatorBountyCreationPage from "./pages/Bounties/CreatorBountyCreation";
import BountyContribution from "./pages/Bounties/BountyContribution";
import BountyCompletion from "./pages/Bounties/BountyCompletion";
import SearchPage from "./pages/Search/SearchPage";
import NotificationPreferences from "./pages/Notifications/NotificationPreferences";
import ContactUsPage from "./pages/Contact/ContactUsPage";

export const ROUTES = [
    {route: "/withdrawals", exact: true, authProtected: true, component: <WithdrawalsPage/>},
    {route: "/logout", exact: true, authProtected: false, component: <Logout/>},
    {route: "/referral-links", exact: true, authProtected: true, component: <ReferralLinkManagementPage/>},
    {route: "/login", exact: true, authProtected: false, component: <Login/>},
    {route: "/sign-up", exact: true, authProtected: false, component: <SignUp/>},
    {route: "/contact-us", exact: true, authProtected: false, component: <ContactUsPage/>},
    {route: "/contact", exact: true, authProtected: false, component: <ContactUsPage/>},
    // {route: "/age-verification", exact: true, authProtected: true, component: <AgeVerification/>},
    {route: "/age-verification-code", exact: true, authProtected: true, component: <AgeVerificationCode/>},
    {route: "/settings", exact: true, authProtected: true, component: <Settings/>},
    {route: "/redirect", exact: true, authProtected: true, component: <RedirectPage/>},
    {route: "/identity-redirect", exact: true, authProtected: true, component: <IdentityRedirectPage/>},
    {route: "/age-verification-intro", exact: true, authProtected: true, component: <AgeVerificationIntro/>},
    {route: "/chats", exact: true, authProtected: true, component: <ChatIndex/>},
    {route: "/payment-final-steps", exact: true, component: <PaymentFinalSteps/>},
    {route: "/set-your-location", exact: true, authProtected: false, component: <ManageLocationPage/>},
    {route: "/creator-setup", exact: true, authProtected: true, component: <CreatorSetup/>},
    {route: "/manage-bio", exact: true, authProtected: true, component: <ManageBio/>},
    {route: "/manage-messaging", exact: true, authProtected: true, component: <ManageMessagingPreferences/>},
    {route: "/manage-subs", exact: true, authProtected: true, component: <ManageSubscriptionPricing/>},
    {route: "/payment-final-steps", exact: true, authProtected: true, component: <PaymentFinalSteps/>},
    {route: "/manage-requests", exact: true, authProtected: true, component: <ManageRequestPreferences/>},
    {route: "/manage-video-chats", exact: true, authProtected: true, component: <ManageVideoChatPreferences/>},
    {route: "/manage-bookings", exact: true, authProtected: true, component: <ManageBookingPreferences/>},
    {route: "/manage-banner-photo", exact: true, authProtected: true, component: <ManageBannerPhoto/>},
    {route: "/simulator", exact: true, authProtected: false, component: <Simulator/>},
    {route: "/subscriber-management", exact: true, authProtected: true, component: <SubscriberManagement/>},
    {route: "/ensure-handle", exact: true, authProtected: true, component: <EnsureHandle/>},
    {route: "/terms-and-conditions", exact: true, authProtected: false, component: <TermsAndConditions/>},
    {route: "/legal", exact: true, authProtected: false, component: <LegalPage/>},
    {route: "/privacy-policy", exact: true, authProtected: false, component: <PrivacyPolicy/>},
    {route: "/health-check", exact: true, authProtected: false, component: <HealthCheckPage/>},
    {route: "/clear-location", exact: true, authProtected: true, component: <ClearLocationPage/>},
    {route: "/notification-preferences", exact: true, authProtected: true, component: <NotificationPreferences/>},
    // parameterized below this line
    {route: "/search-redirect/:id", exact: true, component: <SearchRedirect/>},
    {route: "/swipe-search/:id", exact: true, component: <TinderStyleSearch/>},
    {route: "/search/:id", exact: true, component: <SearchPage/>},
    {route: "/p/:id", exact: true, authProtected: false, component: <ProfilePage/>},
    {route: "/r/:id", exact: true, authProtected: false, component: <ReferralLinkPage/>},
    {route: "/chat/:id", exact: true, authProtected: true, component: <Chat/>},
    {route: "/app-redirect/:id", exact: true, authProtected: false, component: <AppRedirectPage/>},
    {route: "/profile-redirect/:id", exact: true, authProtected: true, component: <ProfilePageRedirect/>},
    {route: "/create-chat-page/:id", exact: true, authProtected: true, component: <CreateChatPage/>},
    {route: "/create-subscription/:id", exact: true, authProtected: true, component: <CreateSubscription/>},
    {route: "/follow-user/:id", exact: true, authProtected: true, component: <FollowUserPage/>},
    {route: "/cancel-subscription/:id", exact: true, authProtected: true, component: <CancelSubscription/>},
    {route: "/schedule-booking/:id", exact: true, authProtected: true, component: <ScheduleBooking/>},
    {route: "/schedule-video-chat/:id", exact: true, authProtected: true, component: <ScheduleVideoChat/>},
    {route: "/request-content/:id", exact: true, authProtected: true, component: <ContentRequestsPage/>},
    {route: "/hosted-video-chat/:id", exact: true, authProtected: false, component: <DailyHostedVideoChat/>},
    {route: "/sell-a-video/:id", exact: true, authProtected: true, component: <SellVideoPage/>},
    {route: "/pay/:id", exact: true, authProtected: true, component: <CreditCardPage/>},
    {route: "/tips/:id", exact: true, authProtected: true, component: <TipsPage/>},
    {route: "/link-management/:id", exact: true, authProtected: true, component: <LinksManagementPage/>},
    {route: "/link-creation/:id", exact: true, authProtected: true, component: <LinkCreationPage/>},
    {route: "/bounty-creation/:id", exact: true, authProtected: true, component: <BountyCreationPage/>},
    {route: "/creator-bounty-creation/:id", exact: true, authProtected: true, component: <CreatorBountyCreationPage/>},
    {
        route: "/contribute-to-bounty/:userId/:bountyDesc",
        exact: true,
        authProtected: true,
        component: <BountyContribution/>
    },
    {route: "/complete-bounty/:userId/:bountyDesc", exact: true, authProtected: true, component: <BountyCompletion/>},
    {route: "/crypto-tips/:id", exact: true, authProtected: true, component: <CryptoGratuities/>}
];
