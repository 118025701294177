import React from 'react';
import {IonSpinner, useIonViewDidEnter} from "@ionic/react";
import {IonPage, IonHeader, IonToolbar, IonContent} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {getSearchParamOrDefault} from "../../Utils/Utils";
import {checkStatusOfStripePayment} from "../../requests/PaymentStatuses/PaymentRequests";
import {useHistory} from "react-router-dom";

const PaymentFinalSteps = ({}) => {
    const history = useHistory();
    const checkStatus = () => {
        const piId = getSearchParamOrDefault("payment_intent", "");
        setTimeout(() => {
            return checkStatusOfStripePayment(piId).then(status => {
                if (status === 'succeeded' || status === 'requires_capture') {
                    alert("Your payment succeeded!");
                    history.replace("/");
                } else if (status === 'payment_failed') {
                    alert("Whoops your payment didn't go through. We're sure it's nothing. Try again!");
                    history.replace("/");
                } else {
                    return checkStatus();
                }
            }).catch(_ => {
                return checkStatus();
            });
        }, 3000);
    }
    useIonViewDidEnter(() => {
        checkStatus();
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    )

}

export default PaymentFinalSteps;
