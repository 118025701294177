import React, {useEffect, useRef, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
    IonTextarea
} from "@ionic/react";
import {useHistory, useParams} from "react-router-dom";
import {getMessageBalance} from "../../requests/MessageBalance/MessageBalances";
import {image} from "ionicons/icons";
import PurchaseMessagesModal from "../../components/Chat/PurchaseMessages/PurchaseMessagesModal";
import {directUploadMMSPhoto, sendMessage, sendMMSMessage} from "../../requests/Conversation/ConversationRequests";
import {uploadFile} from "../../requests/MediaUploads/MediaUploads";

const SendMessageButton = ({fanUserId, currentUserId, creatorUserId, conversationId, areFeesPaused}) => {
    const {id} = useParams();
    const [isVideoUpload, setIsVideoUpload] = useState();
    const [mmsBudget, setMMSBudget] = useState();
    const [smsBudget, setSMSBudget] = useState();
    const [smsCount, setSMSCount] = useState();
    const [mmsCount, setMMSCount] = useState();
    const [disabled, setDisabled] = useState(false);
    const [messageToPurchaseType, setMessageToPurchaseType] = useState();
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState();
    const [mmsMessage, setMMSMessage] = useState();
    const [showPurchaseModal, setShowPurchaseModal] = useState();
    const [chatInput, setChatInput] = useState('');
    useEffect(() => {
        getMessageBalance(id).then(data => {
            setMMSBudget(data.mmsBudget);
            setSMSBudget(data.smsBudget);
            setSMSCount(data.smsCount);
            setMMSCount(data.mmsCount);
        }).catch(_ => {

        });
    }, [conversationId]);
    const handleSMSClick = () => {
        if (!chatInput || disabled) return;
        if (fanUserId === currentUserId) {
            if (smsBudget > smsCount || areFeesPaused) {
                return sendMessage(conversationId, {
                    body: chatInput,
                    media: ""
                }).then(_ => {
                    setChatInput("");
                    setSMSCount(prev => prev + 1);
                }).catch(_ => alert("Something went wrong. We were unable to send your message."));
            } else {
                setMessageToPurchaseType("sms");
                setShowPurchaseModal(true);
            }
        } else {
            return sendMessage(conversationId, {
                body: chatInput,
                media: ""
            }).then(_ => {
                setChatInput("");
            }).catch(_ => alert("Something went wrong. We were unable to send your message"));
        }
    };

    const handleImageClick = () => {
        if (disabled) return;
        if (fanUserId === currentUserId) {
            if (mmsBudget > mmsCount || areFeesPaused) {
                document.getElementById("send-photo-file-input").click();
            } else {
                setMessageToPurchaseType("mms");
                setShowPurchaseModal(true);
            }
        } else {
            document.getElementById("send-photo-file-input").click();
        }
    };

    return (
        <form
            className={'send-message-form'}
            style={{display: 'flex'}}
            onSubmit={(e) => {
                e.preventDefault();
                return handleSMSClick();
            }}>
            <textarea
                className={'special-text-area'}
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                style={{
                    width: "calc(100vw - 150px)",
                    color: 'white',
                    height: '50px',
                    fontSize: '14px',
                    padding: '5px'
                }}
            />
            <button
                type={'submit'}
                className={'btn-red-gradient'}
                style={{
                    width: '100px',
                    textAlign: 'center',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                Send
            </button>
            <button type={'button'}
                    onClick={handleImageClick}
                    style={{
                        width: '48px',
                        textAlign: 'center',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#F47B60',
                        background: 'rgba(107, 107, 107, 1)',
                        border: '2px solid #F47B60'
                    }}
            >
                <IonIcon icon={image}/><
            /button>
            <input
                id='send-photo-file-input'
                className={"display-none"}
                type={'file'}
                onChange={(e) => {
                    setFile(e.target.files[0]);
                    setMMSMessage(chatInput || '');
                    setShowModal(true);
                }}
            />
            {
                messageToPurchaseType ?
                    <PurchaseMessagesModal
                        type={messageToPurchaseType}
                        showModal={showPurchaseModal}
                        closeModal={() => setShowPurchaseModal(false)}
                        creatorUserId={creatorUserId}
                    />
                    : <></>
            }
            <IonModal isOpen={showModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => {
                                setShowModal(false);
                                setFile(null);
                                setMMSMessage("");
                            }}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Send MMS</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {
                    isVideoUpload ?
                        <video
                            controls
                            className={'mt-5px'}
                            style={{width: '200px'}}
                        >
                            <source src={file ? URL.createObjectURL(file) : ''}
                                    type={"video/mp4"}/>
                        </video>
                        :
                        <img className={'mt-5px'} src={file ? URL.createObjectURL(file) : ''}
                             style={{width: '100px', objectFit: 'cover'}}
                             onError={({currentTarget}) => {
                                 currentTarget.onerror = null;
                                 setIsVideoUpload(true);
                             }}
                             alt={'image preview'}
                        />
                }

                <IonTextarea
                    className={'color-white'}
                    onIonChange={(e) => setMMSMessage(e.target.value)}
                    value={mmsMessage}
                    style={{padding: '5px 10px'}}
                    maxlength={200}
                    placeholder={'Enter any message you want to send with your media here'}
                />
                <button
                    className={'btn btn-red-gradient'}
                    onClick={(e) => {
                        if (disabled) return;
                        setDisabled(true);
                        const fileExtension = file.name.split('.').pop();
                        return sendMMSMessage(conversationId, {body: mmsMessage ? mmsMessage : ""}, fileExtension === "mov").then(url => {
                            return directUploadMMSPhoto(url, file).then(_ => {
                                setShowModal(false);
                                setMMSMessage("");
                                setFile(null);
                                alert("Your message has been sent. We're checking it for viruses, so it may take a minute or two to appear in the chat.");
                                setDisabled(false);
                                setMMSCount(prev => prev + 1)
                            });
                        }).catch(_ => {
                            alert("Something went wrong. We were unable to send your media message.");
                            setShowModal(false);
                            setMMSMessage("");
                            setFile(null);
                            setDisabled(false);
                        })
                    }}
                >
                    {disabled ? "Loading..." : "Send MMS"}
                </button>
            </IonModal>
            <button className={'display-none'} id={'open-mms-modal'}/>
        </form>
    );
}

export default SendMessageButton;
