import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {beginSignUp, completeSignUp, verifyPhoneNumber} from "../../requests/Auth/AuthRequests";
import {getSearchParamOrDefault} from "../../Utils/Utils";
import './Auth.css';
import {IonPage} from '@ionic/react';
import HowItWorksVideo from "../../components/HowItWorks/HowItWorksVideo";

const SignUp = () => {
    const [phone, setPhone] = useState();
    const [disabled, setDisabled] = useState();
    const [otp, setOtp] = useState();
    const [stage, setStage] = useState("PHONE");
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const history = useHistory();

    const startSignUp = () => {
        if (!phone || disabled) return;
        setDisabled(true);
        return beginSignUp(phone).then(_ => {
            setStage("OTP");
            setDisabled(false);
        }).catch(_ => {
            alert("Something went wrong. Double check your phone number");
            setDisabled(false);
        });
    }

    const finishSignUp = () => {
        if (!phone || !password || !passwordConfirm || disabled) return;
        if (password !== passwordConfirm) {
            alert("Passwords don't match");
            return;
        }
        setDisabled(true);
        return completeSignUp(phone, password).then(data => {
            setStage("PHONE");
            setDisabled(false);
            const expiration = new Date().getTime() + (1000 * 60 * 60 * 24);
            localStorage.setItem("session-token", JSON.stringify({token: data, expiration}))
            const redirect = getSearchParamOrDefault("redirect", "/chats");
            history.replace(redirect);
        }).catch(_ => {
            alert("Something went wrong. Double check the code");
            setDisabled(false);
        });
    }

    const verifyNumber = () => {
        if (!phone || !otp || disabled) return;
        setDisabled(true);
        const referralPath = localStorage.getItem("referral-path");
        return verifyPhoneNumber(phone, otp, referralPath || "").then(data => {
            setStage("PASSWORD");
            setDisabled(false);
        }).catch(_ => {
            alert("Something went wrong. Double check the code");
            setDisabled(false);
        });
    }

    return (
        <IonPage>
            <section className="login login-alt position-relative overflow-scroll">
                <div className="container height-100vh">
                    <div className="col-md-10 m-auto" style={{width: '', overflowY: 'scroll'}}>
                        <div className="login-main shadow-lg p-md-5 p-4"
                        >
                            <div style={{display: 'flex', flexDirection: 'column', margin: '0 auto', width: '80%'}}>
                                <Link to="/chats">
                                    <img
                                        style={{width: "300px", marginBottom: "30px"}}
                                        src="assets/logo-sm-light.png"
                                        alt="logo"
                                        className="img-fluid"
                                    />
                                </Link>
                                <p className={"color-white"}>Sign Up</p>
                                <br/>
                                <input type="tel" className="form-control form-control-lg"
                                       inputMode={'tel'}
                                       placeholder="Phone number"
                                       value={phone}
                                       onChange={(e) => {
                                           e.preventDefault();
                                           if (stage === "PHONE") {
                                               setPhone(e.target.value.trim().replace(/\D/g, ''));
                                           }
                                       }}
                                />
                                {
                                    {
                                        "PHONE": <></>,
                                        "OTP": (
                                            <>
                                                <input type="text"
                                                       key={"otp"}
                                                       placeholder="One Time Code"
                                                       className="form-control form-control-lg"
                                                       value={otp}
                                                       onChange={(e) => setOtp(e.target.value)}
                                                />
                                            </>
                                        ),
                                        "PASSWORD": (
                                            <>
                                                <input type="password"
                                                       key={"pw"}
                                                       placeholder="Password"
                                                       className="form-control form-control-lg"
                                                       value={password}
                                                       onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <input type="password"
                                                       key={"pw2"}
                                                       placeholder="Confirm Password"
                                                       className="form-control form-control-lg"
                                                       value={passwordConfirm}
                                                       onChange={(e) => setPasswordConfirm(e.target.value)}
                                                />
                                            </>
                                        )
                                    }[stage]
                                }
                                <button className={"btn btn-red-gradient rounded-pill text-uppercase mt-3"}
                                        onClick={{
                                            "PHONE": startSignUp,
                                            "OTP": verifyNumber,
                                            "PASSWORD": finishSignUp
                                        }[stage]}
                                >
                                    {disabled ? "Loading..." : {
                                        "PHONE": "Proceed",
                                        "OTP": "Verify",
                                        "PASSWORD": "Sign Up"
                                    }[stage]}
                                </button>
                                <br/>
                                <Link to={"/login" + window.location.search} replace>
                                    Sign In
                                </Link>
                                <div style={{height: "10px"}}/>
                                <HowItWorksVideo/>
                                <div style={{height: "10px"}}/>
                                <Link to={'/legal'}>
                                    Legal
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </IonPage>
    );
}

export default SignUp;
