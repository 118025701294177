import React, {useState} from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {
    IonPage,
    IonHeader,
    IonContent,
    IonDatetimeButton,
    IonDatetime,
    IonModal,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonTextarea, useIonViewDidEnter, IonInput, IonToolbar, IonBackButton, IonButtons
} from "@ionic/react";
import {useHistory, useParams} from "react-router-dom";
import {checkIfHasConflict, requestBooking} from "../../requests/Meeting/MeetingRequests";
import {getCreatorPreferencesForUser} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {formatCurrency} from "../../Utils/Utils";
import {getSalesTaxRate} from "../../requests/SalesTax/SalesTaxRate";

const ScheduleBooking = ({}) => {
    const {id} = useParams();
    const [creatorsHostingPref, setCreatorsHostingPref] = useState();
    const [chPrice, setChPrice] = useState();
    const [fhPrice, setFhPrice] = useState();
    const [chDuration, setChDuration] = useState();
    const [fhDuration, setFhDuration] = useState();
    const [disabled, setDisabled] = useState();
    const [startTime, setStartTime] = useState();
    const [hours, setHours] = useState();
    const [min, setMin] = useState();
    const [hostPreference, setHostPreference] = useState();
    const [address, setAddress] = useState('');
    const [questions, setQuestions] = useState('');
    const [answers, setAnswers] = useState('');
    const [expiration, setExpiration] = useState();
    const [zipCode, setZipCode] = useState(19711);
    const [rate, setRate] = useState(1);
    const history = useHistory();
    useIonViewDidEnter(() => {
        getCreatorPreferencesForUser(id).then(prefs => {
            setCreatorsHostingPref(prefs.hostingPreference);
            setChDuration(prefs.minCreatorHostedBookingDuration);
            setChPrice(prefs.creatorHostedBookingPricePerTimeUnitInCents)
            setFhDuration(prefs.minFanHostedBookingDuration);
            setFhPrice(prefs.fanHostedBookingPricePerTimeUnitInCents);
            setQuestions(prefs.bookingQuestions);
        }).catch(_ => {

        });
    });
    const bookMeeting = () => {
        if (disabled) return;
        setDisabled(true);
        const thirtyMinFromNow = new Date().getTime() + 30 * 60 * 1000;
        if (!startTime || startTime < thirtyMinFromNow) {
            alert("You must specify a start time");
            setDisabled(false);
            return;
        }
        if (!hours && !min) {
            alert("You must specify a duration in either hours or minutes or both");
            setDisabled(false);
            return;
        }
        if (!hostPreference) {
            alert("You must specify a hosting preference");
            setDisabled(false);
            return;
        }
        if (hostPreference === "me" && !address) {
            alert("You must specify an address if you will be hosting");
            setDisabled(false);
            return;
        }
        if (!expiration || expiration < thirtyMinFromNow) {
            alert("You must specify when you need an answer by. You must allow at least thirty minutes from now");
            setDisabled(false);
            return;
        }
        const duration = (hours ? hours * 60 : 0) + (min ? min : 0);
        return checkIfHasConflict(id, startTime, duration).then(hasConflict => {
            if (hasConflict) {
                alert("Sorry. But there's a conflict!");
                setDisabled(false);
                return;
            }
            return requestBooking(id, startTime, hostPreference, address, duration, expiration, answers).then(paymentSecret => {
                history.push("/pay/" + paymentSecret);
                setDisabled(false);
            });
        }).catch(_ => {
            alert("Something went wrong. Try again or refresh the page/app");
            setDisabled(false);
        });
    }
    const duration = (hours ? hours * 60 : 0) + (min ? min : 0);
    const price = !hostPreference || !duration ? "" : formatCurrency((hostPreference === "STAR_HOSTS" ? chPrice / chDuration : fhPrice / fhDuration) * duration * rate);
    const allowsCreatorHosted = chPrice && chDuration && (creatorsHostingPref === "BOTH" || creatorsHostingPref === "STAR_HOSTS");
    const allowsFanHosted = fhPrice && fhDuration && (creatorsHostingPref === "BOTH" || creatorsHostingPref === "BUYER_HOSTS");
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={'width-100'}>
                <IonItem>
                    <IonLabel>Schedule a Booking</IonLabel>
                </IonItem>
                {allowsCreatorHosted ?
                    <IonItem>
                        Rates: {formatCurrency(chPrice)}/ {chDuration}min if the star hosts
                    </IonItem>
                    : <></>
                }
                {allowsFanHosted ?
                    <IonItem>
                        Rates: {formatCurrency(fhPrice)}/ {fhDuration}min if you host
                    </IonItem>
                    : <></>
                }
                <IonItem className={'width-100'}>
                    <IonLabel>When</IonLabel>
                    <IonDatetimeButton datetime={'datetime'}/>
                </IonItem>
                <IonItem>
                    <IonLabel>How Long:</IonLabel>
                </IonItem>
                <IonItem className={'width-100'}>
                    <IonSelect className={'width-100'}
                               interface="popover"
                               placeholder="# of Hours?"
                               onIonChange={(e) => setHours(e.detail.value)}
                    >
                        {Array(169).fill(0).map((_, idx) => {
                            return <IonSelectOption className={'width-100'}
                                                    value={idx}>{idx} {idx > 1 ? "hrs" : "hr"}</IonSelectOption>
                        })}
                    </IonSelect>
                </IonItem>
                <IonItem className={'width-100'}>
                    <IonSelect className={'width-100'} interface="popover" placeholder="# of minutes?"
                               onIonChange={(e) => setMin(e.detail.value)}
                    >
                        {
                            Array(60).fill(0).map((_, idx) => {
                                return <IonSelectOption className={'width-100'} value={idx}>
                                    {idx} {idx > 1 ? "mins" : "min"}
                                </IonSelectOption>
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect className={'width-100'} interface="popover" placeholder="Who Hosts?"
                               onIonChange={(e) => setHostPreference(e.detail.value)}
                    >
                        {allowsFanHosted ?
                            <IonSelectOption className={'width-100'} value="BUYER_HOSTS">Buyer hosts</IonSelectOption>
                            : <></>
                        }
                        {
                            allowsCreatorHosted ?
                                <IonSelectOption className={'width-100'} value="STAR_HOSTS">Star hosts</IonSelectOption>
                                : <></>
                        }
                    </IonSelect>
                </IonItem>
                {
                    hostPreference === "BUYER_HOSTS" ?
                        <>
                            <IonItem>
                                <IonLabel className={'mt-2'}>Enter Address Below:</IonLabel>
                            </IonItem>
                            <IonItem className={'width-100'}>
                                Heads up: If you give a bad or incomplete address, you will lose your deposit. Do not
                                omit the zip
                                code, forget the office or suite number, etc. We do not pre-check the correctness of
                                addresses, and
                                you have now been warned of the consequences.
                            </IonItem>
                            <IonItem>
                                <IonTextarea placeholder={"Enter the complete address here"} value={address}
                                             onIonChange={(e) => setAddress(e.target.value)}/>
                            </IonItem>
                        </>
                        :
                        <></>
                }
                {questions ?
                    <>
                        <IonItem>
                            Please answer the following question(s):
                        </IonItem>
                        <IonItem>
                            {questions}
                        </IonItem>
                        <IonItem>
                            <IonTextarea placeholder={'Enter your answer(s) here'}
                                         value={answers} onIonChange={(e) => setAnswers(e.target.value)}
                            />
                        </IonItem>
                    </>
                    : <></>}

                <IonItem>
                    When do you need an answer by
                </IonItem>
                <IonItem>
                    <IonDatetimeButton datetime={'datetime2'}/>
                </IonItem>
                <IonItem>
                    Zip Code (for sales tax):
                </IonItem>
                <IonItem>
                    <IonInput type={'text'}
                              value={zipCode}
                              onIonChange={(e) => {
                                  e.preventDefault();
                                  const {value} = e.target;
                                  setZipCode(value);
                                  if (value.length === 5 && !isNaN(value)) {
                                      getSalesTaxRate(value).then(taxRate => {
                                          if (taxRate) {
                                              setRate(1 + taxRate.rate);
                                          }
                                      }).catch(_ => {

                                      });
                                  }
                              }}
                              placeholder={"Enter your zip code"}
                    />
                </IonItem>
                <IonItem className={'width-100'}>
                    By clicking the button below, you acknowledge that you are only paying for the ability to spend time
                    with the star. If the star does not want to sing or do some other activity, that is up to the star.
                    You are paying solely for the ability to spend time with the star. If you spend time with the star
                    and have a negative experience, your only recourse is a negative review. By clicking the button
                    below, you are accepting these terms. If you do not wish to accept these terms, do not click the
                    button below.
                </IonItem>
                <br/>
                <button className="btn login_btn btn-red-gradient width-100"
                        onClick={bookMeeting}>
                    Deposit {price ? price : ""} & Send Request
                </button>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        id="datetime"
                        className={'color-white'}
                        onIonChange={e => {
                            setStartTime(new Date(e.target.value).getTime())
                        }}
                    />
                </IonModal>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        id="datetime2"
                        className={'color-white'}
                        onIonChange={e => {
                            setExpiration(new Date(e.target.value).getTime())
                        }}
                    />
                </IonModal>
            </IonContent>
        </IonPage>
    );
}

export default ScheduleBooking;
