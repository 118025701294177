import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {IonPage, IonHeader, IonToolbar, IonContent, useIonViewDidEnter, useIonViewDidLeave} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import SinglePaymentCardInput from "../../components/CreditCard/SinglePaymentCardInput";

const CreditCardPage = () => {
    const {id} = useParams();
    const [visible, setVisible] = useState(false);
    const [clientSecret, setClientSecret] = useState(id);
    useIonViewDidEnter(() => {
        setClientSecret(window.location.pathname.split("/pay/")[1]);
    });
    useIonViewDidLeave(() => {
        setClientSecret(null);
    })
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {!clientSecret ? <></> :
                    <>
                        <section className="login login-alt position-relative">
                            <div className="container">
                                <div className="col-md-10 m-auto">
                                    <div className="login-main shadow-lg p-md-5 p-4">
                                        <Link to="/chats" className="dark-mode-logo">
                                            <img
                                                style={{width: "300px", marginBottom: "30px"}}
                                                src="assets/logo-sm-light.png"
                                                alt="logo"
                                                className="img-fluid"
                                            />
                                        </Link>
                                        <SinglePaymentCardInput buttonText={"Pay"} clientSecret={id}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </IonContent>
        </IonPage>
    )
}

export default CreditCardPage;
