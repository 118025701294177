import React from 'react';
import {IonAvatar, IonImg, IonItem, IonLabel} from "@ionic/react";
import {getProfilePicturesBaseUrl} from "../../../Utils/Utils";
import {useHistory} from "react-router-dom";

const SearchResult = ({profile, closeModal, isSearchResultsPage = false}) => {
    const history = useHistory();
    return (
        <IonItem
            className={'cursor-pointer'}
            key={"key-search-result-" + profile.userId}
            onClick={() => {
                if (isSearchResultsPage) {
                    history.push("/p/" + profile.handle);
                } else {
                    closeModal()
                    history.push("/create-chat-page/" + profile.userId);
                }

            }}>
            <IonAvatar slot="start">
                <IonImg src={getProfilePicturesBaseUrl() + profile.userId}
                        style={{objectFit: 'cover'}}
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "assets/id.png";
                        }}
                />
            </IonAvatar>
            <IonLabel>
                <h2 className={'m-l-16px'}>@{profile.handle}</h2>
                <p className={'m-l-16px'}>{profile.bio}</p>
            </IonLabel>
        </IonItem>
    );
}

export default SearchResult;
