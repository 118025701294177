import React from 'react';
import {IonPage, IonHeader, IonToolbar, IonContent, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import HeaderLogo from "../Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";

const SearchRedirect = ({}) => {
    const history = useHistory();
    const {id} = useParams();
    useIonViewDidEnter(() => {
        setTimeout(() => {
            history.replace("/swipe-search/" + id);
            window.location.reload();
        }, 500);
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    )
}

export default SearchRedirect;
