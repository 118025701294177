import React, {useState} from 'react';
import {IonBackButton, IonButtons, IonHeader, IonToolbar, IonPage, IonContent, useIonViewDidEnter} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {getPrivacyPolicyStatements} from "../../requests/Legal/LegalRequests";

const PrivacyPolicy = ({}) => {
    const [statements, setStatements] = useState([]);
    useIonViewDidEnter(() => {
        getPrivacyPolicyStatements().then(res => {
            setStatements(res);
        }).catch(_ => {

        });
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/legal"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{width: '80vw', margin: '0 auto', padding: "20px"}}>
                    <p>
                        At our company, we take your privacy seriously and we are committed to protecting your personal
                        information. We do not sell your data to third-party companies or advertisers. This Privacy
                        Policy
                        describes how we collect, use, and share the personal information we collect from you.
                    </p>
                    {statements.map(el => {
                        return (
                            <>
                                <h6>{el.heading}</h6>
                                <>
                                    {el.paragraph}
                                </>
                            </>
                        )
                    })}
                    If you have any questions or concerns about our Privacy Policy, please contact us at <a
                    href={"mailto:fan.numbers.dot.com@gmail.com"}>
                    fan.numbers.dot.com@gmail.com
                </a>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default PrivacyPolicy;
