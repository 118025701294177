import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {checkIfCurrentUserHasHandle} from "../../../requests/Handles/HandleRequests";
import HandlesModal from "../../Handle/HandlesModal";
import NewChatUserSearch from "./NewChatUserSearch";

const NewChatModal = ({isOpen, close, arbitraryId}) => {
    const [userHasHandle, setUserHasHandle] = useState();
    const [search, setSearch] = useState('');
    useEffect(() => {
        checkIfCurrentUserHasHandle().then(handle => {
            setUserHasHandle(!!handle);
        }).catch(_ => {
            alert("Something went wrong. Try refreshing the page or restarting the app");
            console.log("ERROR " + JSON.stringify(_));
        })
    }, [arbitraryId]);
    return (
        <IonModal isOpen={isOpen}>
            {!userHasHandle ? <HandlesModal closeModal={close} setHasHandle={setUserHasHandle}/> :
                <>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={close}>Cancel</IonButton>
                            </IonButtons>
                            <IonTitle>New Chat</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <NewChatUserSearch closeModal={close}/>
                    </IonContent>
                </>
            }
        </IonModal>
    )
}

export default NewChatModal;
