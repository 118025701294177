import React, {useState} from 'react';
import {
    IonPage,
    IonHeader,
    IonContent,
    IonInput,
    useIonViewDidEnter,
    IonButtons,
    IonButton,
    IonToolbar, IonBackButton
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {verifyWithCode} from "../../requests/AgeVerifications/AgeVerificationRequests";
import {useHistory} from "react-router-dom";

const AgeVerificationCode = ({}) => {

    const [code, setCode] = useState();
    const history = useHistory();
    const [disabled, setDisabled] = useState();

    useIonViewDidEnter(() => {
        setTimeout(() => {
            document.getElementById("otp-code-input").focus();
        }, 300)
    });

    const handleSubmit = () => {
        if (disabled) return;
        setDisabled(true);
        return verifyWithCode(code).then(_ => {
            history.push("/creator-setup")
            setDisabled(false);
        }).catch(_ => {
            alert("It looks like the code you entered was invalid. Keep in mind, the code we sent you is case sensitive. Try again!")
            setDisabled(false);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/age-verification-intro"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <p className={'width-80 mt-3 m-0-auto ion-text-center'}>Enter the age verification bypass code we sent
                    you.
                    If we did not send you a code, you will need to go through the traditional age verification
                    process. Keep in mind: the code we sent you is case-sensitive</p>
                <IonInput
                    id={'otp-code-input'}
                    className={'width-100 m-0-auto ion-text-center'}
                    value={code} onIonChange={(e) => setCode(e.target.value)} placeholder={"Enter your code here"}
                    autofocus/>
                <br/>
                <div className={"width-100 flex-row justify-content-center"}>
                    <button className={"btn btn-red-gradient m-0-auto width-50 mt-10"}
                            onClick={handleSubmit}
                    >
                        Submit Code
                    </button>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default AgeVerificationCode;
