import React from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem, IonLabel,
    IonList,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {
    cashOutline,
    videocamOutline

} from "ionicons/icons";
import MessagingFeesToggle from "./MessagingFeesToggle";
import {useHistory} from "react-router-dom";

const CreatorOptionsModal = ({closeModal, fanUserId, conversationId, isAllSubscribers}) => {
    const history = useHistory();
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={closeModal}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>More Options</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem
                        className={'cursor-pointer'}
                        onClick={() => {
                            closeModal();
                            history.push("/sell-a-video/" + (isAllSubscribers ? "ALL" : fanUserId));
                        }}>
                        <IonIcon className={"m-r-16px"} slot={'start'} icon={videocamOutline}/>
                        <IonLabel>
                            <h2>Sell {isAllSubscribers ? "Everyone" : "User"} a video</h2>
                        </IonLabel>
                    </IonItem>
                    {
                        isAllSubscribers ?
                            <IonItem>
                                <IonIcon className={"m-r-16px"} slot={'start'} icon={cashOutline}/>
                                <IonLabel onClick={() => {
                                    closeModal();
                                    history.push("/withdrawals");
                                }}>
                                    <h2>Withdrawal</h2>
                                </IonLabel>
                            </IonItem>
                            :
                            <></>
                    }
                    {
                        isAllSubscribers ? <></> :
                            <MessagingFeesToggle conversationId={conversationId}/>
                    }
                </IonList>
            </IonContent>
        </>
    );
}

export default CreatorOptionsModal;
