import React, {useState} from 'react';
import {
    IonContent,
    IonItem,
    IonInput, IonToolbar, IonTitle, IonHeader
} from '@ionic/react';
import {formatCurrency, formatDuration, toTimeString} from "../../../../Utils/Utils";
import {acceptMeeting} from "../../../../requests/Meeting/MeetingRequests";

const MeetingDetails = ({message, closeModal, currentUserId, isVideoChat = false, isViewOnly = false}) => {
    const {metadata} = message;
    const {
        id,
        startTime,
        endTime,
        hostingPreference,
        address,
        durationInSeconds,
        creatorId,
        fanId,
        accepted,
        offerExpiration,
        priceInCents,
        type,
        paymentIntentId,
        linkedinUrl
    } = JSON.parse(isVideoChat ? metadata.videoChat : metadata.booking);
    const isFanViewing = fanId === currentUserId;
    const isCreatorHosting = hostingPreference === "STAR_HOSTS";
    const [disabled, setDisabled] = useState();
    const [hostAddress, setHostAddress] = useState('');
    const acceptBooking = () => {
        if (disabled) return;
        setDisabled(true);
        if (isCreatorHosting && !hostAddress) {
            alert("You must provide your address or simply write something like 'Will provide via message' in the address field if you want to communicate the details on your own");
            setDisabled(false);
            return;
        }
        return acceptMeeting(id).then(_ => {
            closeModal();
            setDisabled(false);
        }).catch(_ => {
            alert("Something went wrong. The offer may have expired.");
            setDisabled(false);
            closeModal();
        });
    }

    return (
        <IonContent>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{isVideoChat ? "Video Chat" : "Booking"} Request</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonItem>
                Start: {toTimeString(startTime)}
            </IonItem>
            <IonItem>
                Duration: {formatDuration(durationInSeconds)}
            </IonItem>
            <IonItem>
                Offer Expiration: {toTimeString(offerExpiration)}
            </IonItem>
            {!isVideoChat ?
                <IonItem>
                    Who Hosts: The&nbsp;{isCreatorHosting ? "Star Hosts" : "Buyer Hosts"}
                </IonItem>
                : <></>
            }
            {
                isCreatorHosting || isVideoChat ? <></> :
                    <IonItem>
                        {"Where: " + address}
                    </IonItem>
            }
            <IonItem>
                Price: {formatCurrency(priceInCents)}
            </IonItem>
            {!isVideoChat ?
                <IonItem>
                    Answer(s) to your question(s): {linkedinUrl}
                </IonItem>
                :
                <></>
            }
            {!isVideoChat && isCreatorHosting && !isViewOnly ?
                <IonItem>
                    If you choose to accept this offer, when the fan arrives, you will need to mark their arrival. The
                    fan will then receive a message telling them to confirm their arrival. Once they have confirmed
                    their arrival, you will get paid, and the fan will get a chance to leave a review. We will message
                    you once the fan has confirmed their arrival. If we do not message you after you mark the fan's
                    arrival, tell the fan to check this app for the 'Confirm arrival' message. If you still do not get a
                    message saying everything has been confirmed, tell them to leave, as we are not going to be able to
                    pay you without the confirmation.
                </IonItem>
                : <></>}
            {!isVideoChat && !isCreatorHosting && !isViewOnly ?
                <IonItem>
                    If you choose to accept this offer, once you arrive at the address above, you will need to mark your
                    arrival. The fan will then receive a message telling them to confirm your arrival. Once they have
                    confirmed your arrival, you will get paid, and the fan will get a chance to leave a review. We will
                    message you once they have confirmed your arrival. If we do not message you after you mark your
                    arrival, tell them to check this app for the 'Confirm arrival' message. If you still do not get a
                    message saying everything has been confirmed, you should leave, as we are not going to be able to
                    pay you without the confirmation.
                </IonItem>
                : <></>}
            {
                isViewOnly && isCreatorHosting ?
                    <IonItem>
                        Address: {address}
                    </IonItem>
                : <></>
            }
            {!isVideoChat && isCreatorHosting && !isViewOnly ?
                <>
                    <IonItem>

                    </IonItem>
                    <IonItem>
                        We need the address of where you will be hosting. If you wish to communicate this on your own, you may so by simply messaging them in the app. Having said that, we remind you that you only get paid once the fan has confirmed your presence, so if you forget, you will not get paid.
                    </IonItem>
                    <IonItem>
                        Address:
                    </IonItem>
                    <IonItem>
                        <IonInput
                            value={hostAddress}
                            onIonChange={(e) => {
                                setHostAddress(e.target.value)
                            }}
                            placeholder={"Enter the address or write 'later'"}
                        />
                    </IonItem>
                </>
                : <></>
            }
            {isViewOnly ? <></>
                :
                <>
                    <IonItem>
                        Note: If you have any questions for the fan before accepting this booking, you may ask the fan your questions by messaging the fan in the chat. Because the fan may not not want to pay to answer your questions, it is possible for you to temporarily suspend charges by clicking the "..." at the top of the chat and then clicking "Pause Messaging Fees". While messaging fees are paused, you will be able to communicate with each other freely, however you will not be paid for your replies. When you want to resume charging the user for your replies, click the "..." again and then click "Resume Messaging Fees." To be very clear, you will not get paid anything while messaging fees are paused.
                    </IonItem>
                    <br/>
                    <button className="btn login_btn btn-red-gradient width-100"
                            onClick={acceptBooking}>
                        {disabled ? "Loading..." : `Accept ${isVideoChat ? "Video Chat" : "Booking"}`}
                    </button>
                    <br/>
                    <br/>
                </>
            }
        </IonContent>
    );
}

export default MeetingDetails;
