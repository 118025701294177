import React from 'react';
import LinkTreeLink from "./LinkTreeLink";
import LinkTreeEditButton from "./LinkTreeEditButton";

const LinkTree = ({linkOrdering, links, userId}) => {

    return (
        <section className="mb-5">
            <div className="container-fluid">
                <div className="upload-video py-5">
                    <LinkTreeEditButton userId={userId}/>
                    <br/>
                    <div className="container">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row gx-5 gy-3 d-flex flex-column align-items-center">
                                {linkOrdering.map((linkId, idx) => (
                                    <LinkTreeLink
                                        key={"link-id-" + linkId}
                                        index={idx}
                                        currentIndex={-1}
                                        setCurrentIndex={() => {
                                        }}
                                        isEditMode={false}
                                        link={links[linkId]}
                                        setLink={(_) => {
                                        }}
                                        remove={() => {
                                        }}
                                    />
                                ))}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LinkTree;
