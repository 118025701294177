import {getRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const getIsRegistered = () => {
    return getRequest(getBaseUrl() + "/connected-accounts/is-registered").then(defaultResponseHandler);
}

export const getRegistrationLink = () => {
    return getRequest(getBaseUrl() + "/connected-accounts/registration-link").then(defaultResponseHandler);
}
