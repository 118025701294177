import React, {useState} from 'react';
import {
    IonPage,
    IonHeader,
    IonContent,
    IonItem,
    IonInput,
    useIonViewDidEnter,
    IonToolbar,
    IonBackButton, IonButtons
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {createSubscription} from "../../requests/Subscriptions/SubscriptionRequests";
import {getCreatorPreferencesForUser} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {formatCurrency} from "../../Utils/Utils";
import {getSalesTaxRate} from "../../requests/SalesTax/SalesTaxRate";

const CreateSubscriptionPage = () => {
    const {id} = useParams();
    const history = useHistory();
    const [zipCode, setZipCode] = useState("19702");
    const [disabled, setDisabled] = useState();
    const [preTaxRate, setPreTaxRate] = useState()
    const [taxRate, setTaxRate] = useState(1.0);
    useIonViewDidEnter(() => {
        getCreatorPreferencesForUser(id).then(prefs => {
            setPreTaxRate(prefs.twoWayPrice);
        });
    })
    const handleClick = () => {
        if (!id) {
            history.goBack();
        } else {
            if (disabled) return;
            if (!zipCode || zipCode.length < 5) {
                alert("You must supply a zip code. The government says so.");
                return;
            }
            setDisabled(true);
            createSubscription(id, zipCode).then(paymentId => {
                if (!paymentId) {
                    alert("Something went wrong. Try subscribing again!");
                    history.goBack();
                }
                setDisabled(false);
                history.replace("/pay/" + paymentId);
            }).catch(_ => {
                setDisabled(false);
                history.goBack();
            });
        }
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    Subscribe
                </IonItem>
                <IonItem>
                    Enter your Zip Code (for sales tax)
                </IonItem>
                <IonItem>
                    <IonInput
                        autoFocus={true}
                        placeholder={"Enter zip code here"}
                        value={zipCode}
                        onIonChange={(e) => {
                            e.preventDefault();
                            const {value} = e.target;
                            setZipCode(value);
                            if (value.length === 5 && !isNaN(value)) {
                                getSalesTaxRate(value).then(taxRate => {
                                    if (taxRate) {
                                        setTaxRate(1 + taxRate.rate);
                                    }
                                }).catch(_ => {

                                });
                            }
                        }}
                    />
                </IonItem>
                <br/>
                <button className="btn login_btn btn-red-gradient width-100"
                        onClick={handleClick}
                >
                    {disabled ? "Loading..." : ((preTaxRate && zipCode && zipCode.length === 5 ? "Subscribe for " + formatCurrency(preTaxRate * taxRate) : "Enter your zip code"))}
                </button>
            </IonContent>
        </IonPage>
    );
}

export default CreateSubscriptionPage;
