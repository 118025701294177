import React from 'react';
import {IonPage, IonHeader, IonContent, IonButtons, IonBackButton, IonToolbar, IonList, IonItem} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";

const ContactUsPage = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        Questions? Concerns? Want to invest?
                    </IonItem>
                    <IonItem>
                        <a href={"mailto:fan.numbers.dot.com@gmail.com"}>fan.numbers.dot.com@gmail.com</a>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default ContactUsPage;
