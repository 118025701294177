import React, {useState} from 'react';
import {isApp, replaceAll} from "../../Utils/Utils";
import {IonSearchbar} from "@ionic/react";
import {useHistory} from "react-router-dom";

const SearchbarHeader = ({setSearchVal, magicLink}) => {
    const history = useHistory();
    const [s, setS] = useState('');
    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            if (!s) return;
            let route;
            if (s.includes("#")) {
                route = "/search-redirect/" + replaceAll(s, "#", "%23");
            } else {
                route = "/search/" + s;
            }
            if (isApp()) {
                window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + `${route}`, "_blank")
            } else {
                history.push(route);
            }

            // if (s.startsWith("@")) {
            //     if (isApp()) {
            //         window.open("https://fannumbers.com/p/" + s.split(" ").shift(), "_blank");
            //     } else {
            //         history.push("/p/" + s.split(" ").shift());
            //     }
            //     return;
            // }
            // // let join = s.split(" ").map(el => el && (el.charAt(0) === '#' || el.charAt(0) === '@') ? "%23" + el.substring(1) : "%23" + el).join(" ");
            // let join = s;
            // if (isApp()) {
            //     window.open("https://fannumbers.com/search-redirect/" + join, "_blank");
            // } else {
            //     history.push("/search-redirect/" + join);
            // }
        }}>
            <IonSearchbar placeholder={"Search by hashtag or @"}
                          value={s}
                          className={'bg-black'}
                          onIonChange={({detail}) => {
                              setS(detail.value);
                              setSearchVal(detail.value);
                          }}

            />
        </form>
    );
}

export default SearchbarHeader;
