import React from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {IonPage, IonHeader, IonToolbar, IonContent, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import {useHistory} from "react-router-dom";

const HomePage = ({}) => {
    const history = useHistory();
    useIonViewDidEnter(() => {
        if (window.screen.width && window.screen.width < 992) {
            history.replace("/chats");
        } else if (window.screen) {
            history.replace("/simulator");
        }
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    );
}

export default HomePage;
