import {defaultResponseHandler, getBaseUrl, getRequest as noAuthGet} from "../BaseRequests/BaseRequests";
import {postRequest, getRequest} from "../BaseRequests/AuthenticatedBaseRequests";

export const getUsersBounties = (userId) => {
    return noAuthGet(getBaseUrl() + "/bounties/" + userId, {}).then(defaultResponseHandler);
}

export const contributeToBounty = (creatorId, amountInDollars, bountyDescription) => {
    return postRequest(getBaseUrl() + "/bounties/contribute", {
        creatorId,
        amountInDollars,
        bountyDescription
    }).then(defaultResponseHandler);
}

export const createBounty = (creatorId, amountInDollars, bountyDescription) => {
    return postRequest(getBaseUrl() + "/bounties", {
        creatorId,
        amountInDollars,
        bountyDescription
    }).then(defaultResponseHandler);
}

export const creatorCreateBounty = (bountyDescription) => {
    return postRequest(getBaseUrl() + "/bounties/creator-create-bounty", {bountyDescription}).then(defaultResponseHandler);
}

export const finishBounty = (bountyId) => {
    return postRequest(getBaseUrl() + "/bounties/complete/" + bountyId, {}).then(defaultResponseHandler);
}

export const getBountyPlayback = (bountyId, bountySubId) => {
    return getRequest(getBaseUrl() + "/bounties/playback/" + bountyId + "/" + bountySubId).then(defaultResponseHandler);
}
