import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import React from "react";
import {useHistory} from "react-router-dom";

const LegalPage = () => {
    const history = useHistory();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className={"flex-column"} style={{justifyContent: 'center', alignItems: 'center'}}>
                    <div className={'mt-10'}>
                        <button className="btn login_btn btn-red-gradient color-white"
                                onClick={() => {
                                    history.push("/terms-and-conditions");
                                }}>
                            Terms & Conditions
                        </button>
                    </div>
                    <div className={'mt-10'}>
                        <button className="btn login_btn btn-red-gradient color-white"
                                onClick={() => {
                                    history.push("/privacy-policy");
                                }}>
                            Privacy Policy
                        </button>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default LegalPage;
