import React from 'react';
import {IonImg} from "@ionic/react";
import {Link} from 'react-router-dom';

const HeaderLogo = () => {
    return (
        <Link to={"/chats"}>
            <IonImg
                src={"assets/logo-sm-light.png"}
                    style={{height: "50px", width: "100px", margin: '0 auto'}}/>
            <IonImg className={"dark-mode-hidden"} src={"assets/logo-sm-dark.png"} style={{height: "50px", width: "100px", margin: '0 auto'}}/>
        </Link>
    );
}

export default HeaderLogo;
