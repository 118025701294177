import React, {useEffect, useState} from 'react';
import './ExpandedVideos.css';
import {PostCard} from "../PostCard/PostCard";
import {getPostsByUser} from "../../../requests/Posts/PostRequests";

const ExpandedVideos = ({userId}) => {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        getPostsByUser(userId).then(posts => {
            setPosts(posts);
        }).catch(_ => {
        })
    }, [userId]);
    return (
        <>
            <div className={"mobile-only"}>
                <br/>
            </div>
            <div id={"expanded-videos-container"}>
                {posts.reverse().map(post => {
                    return <PostCard key={"post-card-" + post.img} post={post}/>
                })}
            </div>
        </>
    )

}

export default ExpandedVideos
