import React, {useState} from "react";
import {Link} from 'react-router-dom';
import {getProfilePicturesBaseUrl} from "../../../Utils/Utils";
import SpecialMessageRight from "./SpecialMessageRight";

const RightChatMessage = ({message, handles = {}}) => {
    const {id, senderId, body, createdAt, media, messageType, metadata} = message;
    const [isVideo, setIsVideo] = useState(false);
    const isNotTextMessage = messageType && messageType !== "MMS" && messageType !== "SMS";
    if (isNotTextMessage) {
        return <SpecialMessageRight message={message} handles={handles}/>;
    }
    return (
        <li id={id}>
            {
                media && !isVideo ?
                    <img src={media} className={"mb-3 width-85"}
                         onClick={() => {
                             window.open(media, "_blank");
                         }}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             setIsVideo(true);
                         }}
                    />
                    :
                    media && isVideo ?
                        <video
                               controls={true}
                               className={"mb-3 width-85"}
                        >
                            <source src={media} type="video/mp4"></source>
                        </video>
                        :
                        <></>
            }
            <div className="chat_message message_inner_wrapper outgoing_msg">
                <Link to={"/p/" + handles[senderId]}>
                    <img className={"chat_picture border-2px-solid-green object-fit-cover"}
                         src={getProfilePicturesBaseUrl() + senderId} alt={""}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = "assets/id.png";
                         }}
                    />
                </Link>
                <div className="time_message_section">
                    <div className="chat_time">
                    </div>
                    <div className="chat_message">
                        {body}
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
            </div>
        </li>
    );
}

export default RightChatMessage;
