import React from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {
    calendarOutline,
    cashOutline,
    notificationsOutline,
    personCircleOutline,
    playOutline,
    videocamOutline,
    logoBitcoin, notificationsOffOutline
} from "ionicons/icons";
import {useHistory} from "react-router-dom";
import CreatorOptionsModal from "./CreatorOptionsModal";

const AdditionalOptionsModal = ({
                                    modalRef,
                                    creatorId,
                                    isSubscribed,
                                    currentUserId,
                                    conversationId,
                                    fanUserId,
                                    subPrice,
                                    allowsBookings,
                                    allowsVideoChats,
                                    allowsContentRequests
                                }) => {
    const history = useHistory();
    if (currentUserId === creatorId) {
        return (
            <CreatorOptionsModal closeModal={() => modalRef.current?.dismiss()}
                                 isAllSubscribers={false}
                                 conversationId={conversationId}
                                 fanUserId={fanUserId}
            />
        );
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => modalRef.current?.dismiss()}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>More Options</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {
                        !isSubscribed ?
                            <IonItem
                                className="cursor-pointer"
                                onClick={() => {
                                    history.push(`/${subPrice ? `create-subscription/${creatorId}` : `follow-user/${creatorId}?redirect=${window.location.pathname + window.location.search}`}`);
                                    modalRef.current?.dismiss();
                                }}>
                                <IonIcon className={"m-r-16px"} slot={'start'} icon={notificationsOutline}/>
                                <IonLabel>
                                    <h2>{!subPrice ? "Follow" : "Sub for " + (subPrice ? subPrice / 100 : 0).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) + " (+ tax)"}</h2>
                                </IonLabel>
                            </IonItem>
                            :
                            isSubscribed ?
                                <IonItem
                                    className="cursor-pointer"
                                    onClick={() => {
                                        history.push("/cancel-subscription/" + creatorId);
                                        modalRef.current?.dismiss();

                                    }}>
                                    <IonIcon className={"m-r-16px"} slot={'start'} icon={notificationsOffOutline}/>
                                    <IonLabel>
                                        <h2>{!subPrice ? `Stop Following` : `Cancel Subscription`}</h2>
                                    </IonLabel>
                                </IonItem>
                                : <></>
                    }
                    {
                        allowsBookings ?
                            (
                                <IonItem
                                    className={'cursor-pointer'}
                                    onClick={() => {
                                        modalRef.current?.dismiss();
                                        history.push('/schedule-booking/' + creatorId);
                                    }}>
                                    <IonIcon className={"m-r-16px"} slot={'start'} icon={calendarOutline}/>
                                    <IonLabel>
                                        <h2>Schedule a Booking</h2>
                                    </IonLabel>
                                </IonItem>
                            )
                            :
                            <></>
                    }
                    {
                        allowsVideoChats ?
                            (
                                <IonItem
                                    className={'cursor-pointer'}
                                    onClick={() => {
                                        modalRef.current?.dismiss();
                                        history.push("/schedule-video-chat/" + creatorId);
                                    }}>
                                    <IonIcon className={"m-r-16px"} slot={'start'} icon={videocamOutline}/>
                                    <IonLabel>
                                        <h2>Schedule a Video Chat</h2>
                                    </IonLabel>
                                </IonItem>
                            )
                            : <></>
                    }
                    {
                        allowsContentRequests ?
                            <IonItem
                                className={'cursor-pointer'}
                                onClick={() => {
                                    modalRef.current?.dismiss();
                                    history.push("/request-content/" + creatorId);
                                }}>
                                <IonIcon className={"m-r-16px"} slot={'start'} icon={playOutline}/>
                                <IonLabel>
                                    <h2>Request a Video</h2>
                                </IonLabel>
                            </IonItem>
                            :
                            <></>
                    }
                    <IonItem
                        className={'cursor-pointer'}
                        onClick={() => {
                            modalRef.current?.dismiss();
                            history.push("/tips/" + creatorId);
                        }}>
                        <IonIcon className={"m-r-16px"} slot={'start'} icon={cashOutline}/>
                        <IonLabel>
                            <h2>Send a tip</h2>
                        </IonLabel>
                    </IonItem>
                    {/*<IonItem*/}
                    {/*    className={'cursor-pointer'}*/}
                    {/*    onClick={() => {*/}
                    {/*        modalRef.current?.dismiss();*/}
                    {/*        history.push("/crypto-tips/" + creatorId);*/}
                    {/*    }}>*/}
                    {/*    <IonIcon className={"m-r-16px"} slot={'start'} icon={logoBitcoin}/>*/}
                    {/*    <IonLabel>*/}
                    {/*        <h2>Tip with Crypto</h2>*/}
                    {/*    </IonLabel>*/}
                    {/*</IonItem>*/}
                    <IonItem
                        className={'cursor-pointer'}
                        onClick={() => {
                            history.push("/profile-redirect/" + creatorId);
                            modalRef.current?.dismiss();
                        }}>
                        <IonIcon className={"m-r-16px"} slot={'start'} icon={personCircleOutline}/>
                        <IonLabel>
                            <h2>View Profile</h2>
                        </IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </>
    );
}

export default AdditionalOptionsModal;
