import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {isCurrentUser} from "../../Utils/Utils";

const LinkTreeEditButton = ({userId}) => {
    const [isUsersPage, setIsUsersPage] = useState();
    useEffect(() => {
        setIsUsersPage(isCurrentUser(userId));
    }, [userId]);
    if (!isUsersPage) return <></>;
    return (
        <Link to={"/link-management/" + userId} className={"w-100 d-flex flex-row justify-content-end cursor-pointer"}>
            <i className={"fa fa-edit edit-mode-toggle cursor-pointer"}/>
        </Link>
    );
}

export default LinkTreeEditButton;
