import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const createAgeVerification = () => {
    return postRequest(getBaseUrl() + "/age-verifications").then(defaultResponseHandler);
}

export const getUsersAgeVerification = () => {
    return getRequest(getBaseUrl() + "/age-verifications").then(defaultResponseHandler);
}

export const verifyWithCode = (code) => {
    return postRequest(getBaseUrl() + "/age-verifications/code/" + code).then(defaultResponseHandler);
}
