import React, {createRef, Component, useState} from 'react';
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonContent,
    IonAvatar,
    IonIcon,
    IonButton,
    IonModal,
    IonFooter,
    withIonLifeCycle
} from "@ionic/react";
import {
    ellipsisHorizontal,
    ellipsisVertical,
} from 'ionicons/icons';
import AdditionalOptionsModal from "../../components/AdditionalOptions/AdditionalOptionsModal";
import RightChatMessage from "../../components/Chat/messages/RightChatMessage";
import LeftChatMessage from "../../components/Chat/messages/LeftChatMessage";
import './Chat.css';
import SendMessageButton from "./SendMessageButton";
import {getProfilePicturesBaseUrl, uniqueArr} from "../../Utils/Utils";
import {getChatById} from "../../requests/Conversation/ConversationRequests";
import MessageDetailsModal from "../../components/Chat/messages/Details/MessageDetailsModal";
import {checkIsFollowing, checkIsSubscribed} from "../../requests/Subscriptions/SubscriptionRequests";
import {setLastReadMessage} from "../../requests/LastRead/LastReadMessageRequests";
import {getFeeStatus} from "../../requests/MessagingFees/MessagingFeeRequests";
import {getCreatorPreferencesForUser} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";

class Chat extends Component {

    constructor(props) {
        super(props);
        this.modalRef = createRef();
        this.state = {
            messageOrder: [],
            userId: "",
            messages: {},
            chatInput: '',
            handles: {}
        };
    }

    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({behavior: "smooth"});
        }
    }

    ionViewDidEnter = () => {
        const chatId = window.location.pathname.split("/chat/")[1];
        getChatById(chatId).then(data => {
            if (!data) {
                this.props.history.push("/settings");
                return;
            }
            const {convo, userId} = data;
            const {messageOrder, messages, fanUserId, creatorUserId, handles} = convo;
            this.setState({userId, messageOrder: uniqueArr(messageOrder), messages, fanUserId, creatorUserId, handles: handles || {}});
            const {pusher} = this.props;
            const handleNewMessage = (newMsgJson) => {
                let newMsg = JSON.parse(newMsgJson);
                if (!this.state.messages[newMsg.id]) {
                    const {messageOrder, messages} = this.state;
                    messageOrder.push(newMsg.id);
                    messages[newMsg.id] = newMsg;
                    this.setState({messages, messageOrder: uniqueArr(messageOrder)})
                    return setLastReadMessage().catch(_ => {
                    })
                }
            };
            if (userId === fanUserId) {
                const channel = pusher.subscribe("presence-cache-fo-convo-" + chatId);
                channel.bind('new-message', handleNewMessage);
                getCreatorPreferencesForUser(creatorUserId).then(prefs => {
                    const hasPaidSubs = prefs && prefs.twoWayPrice;
                    const isSubscribedRequest = hasPaidSubs ? checkIsSubscribed : checkIsFollowing;
                    isSubscribedRequest(creatorUserId).then(isSubscribed => {
                        this.setState({isSubscribed});
                        if (isSubscribed) {
                            const subsChannel = pusher.subscribe(`presence-cache-${hasPaidSubs ? "subscribers" : "followers"}-` + creatorUserId);
                            subsChannel.bind('new-message', handleNewMessage);
                        }
                    }).catch(_ => {

                    });
                    console.log("Accepts Requests" + prefs);
                    this.setState({
                        subPrice: prefs.twoWayPrice,
                        allowsBookings: prefs.acceptsAppointments,
                        allowsVideoChats: prefs.acceptsVideoChats,
                        allowsContentRequests: prefs.acceptsRequests
                    });
                });
                getFeeStatus(chatId).then(isPausedStatus => {
                    this.setState({areFeesPaused: isPausedStatus});
                }).catch(_ => {

                })
            }
            setTimeout(() => {
                this.scrollToBottom();
            }, 1000);
            const standardChannel = pusher.subscribe("presence-cache-convo-" + chatId);
            standardChannel.bind("new-message", handleNewMessage);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollToBottom();
    }

    setShowDetailsModal = (message) => {
        this.setState({showDetailsModal: null});
        if (message) {
            setTimeout(() => {
                this.setState({showDetailsModal: message});
            }, 300)
        }
    }

    render = () => {
        const chatID = window.location.pathname.split("/chat/")[1];
        const {
            messageOrder,
            userId,
            messages,
            chatInput,
            fanUserId,
            creatorUserId,
            showDetailsModal,
            areFeesPaused,
            handles,
            allowsBookings,
            allowsVideoChats,
            subPrice,
            allowsContentRequests
        } = this.state;
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="secondary">
                            <IonBackButton color={"white"} defaultHref={"/chats"}/>
                        </IonButtons>
                        <IonButtons slot="primary">
                            <IonButton id={'open-additional-options-modal'}>
                                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
                            </IonButton>
                        </IonButtons>
                        <div>
                            <IonAvatar
                                slot="" style={{
                                margin: '0 auto',
                                position: 'absolute',
                                top: "5px",
                                left: "calc(50% - 15px)"
                            }}>
                                {
                                    creatorUserId ?
                                        <img
                                            style={{
                                                width: "30px",
                                                height: '30px',
                                                borderRadius: '50%',
                                                objectFit: 'cover'
                                            }}
                                            onError={({currentTarget}) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = "assets/id.png";
                                            }}
                                            src={creatorUserId ? getProfilePicturesBaseUrl() + (fanUserId === userId ? creatorUserId : fanUserId) : ''}/>
                                        : <></>
                                }
                            </IonAvatar>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="chat_message_wrapper" id={'cm-wrapper'}>
                        <ul>
                            {messageOrder.map((messageId) => messages[messageId]).map((message) => (
                                message.senderId === userId ?
                                    <RightChatMessage message={message} handles={handles}/>
                                    :
                                    <LeftChatMessage message={message}
                                                     setShowDetailsModal={this.setShowDetailsModal}
                                                     handles={handles}
                                    />
                            ))}
                        </ul>
                    </div>
                    <div style={{float: "left", clear: "both"}}
                         ref={(el) => {
                             this.messagesEnd = el;
                         }}>
                    </div>
                </IonContent>
                <IonFooter>
                    <SendMessageButton
                        areFeesPaused={areFeesPaused}
                        conversationId={chatID}
                        creatorUserId={creatorUserId}
                        fanUserId={fanUserId}
                        currentUserId={userId}
                        currentMessage={chatInput}
                    />
                </IonFooter>
                <IonModal ref={this.modalRef} trigger={'open-additional-options-modal'}>
                    <AdditionalOptionsModal
                        modalRef={this.modalRef}
                        creatorId={creatorUserId}
                        currentUserId={userId}
                        conversationId={chatID}
                        fanUserId={fanUserId}
                        isSubscribed={this.state.isSubscribed}
                        allowsVideoChats={allowsVideoChats}
                        allowsBookings={allowsBookings}
                        allowsContentRequests={allowsContentRequests}
                        subPrice={subPrice}

                    />
                </IonModal>
                <IonModal isOpen={!!showDetailsModal}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={() => this.setShowDetailsModal(null)}>Cancel</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <MessageDetailsModal
                        closeModal={() => this.setShowDetailsModal(null)}
                        message={showDetailsModal}
                        currentUserId={userId}
                    />
                </IonModal>
            </IonPage>
        );
    }
}

export default withIonLifeCycle(Chat);
