import React from 'react';
import {defaultResponseHandler, getBaseUrl, getRequest} from "../BaseRequests/BaseRequests";
import {postRequest} from "../BaseRequests/AuthenticatedBaseRequests";

export const getMagicLink = (linkId) => {
    return getRequest(getBaseUrl() + "/magic-links/" + linkId).then(defaultResponseHandler);
}

export const createMagicLink = () => {
    return postRequest(getBaseUrl() + "/magic-links").then(defaultResponseHandler);
}
