import React, {useEffect, useState} from 'react';
import {getProfileBannersBaseUrl} from "../../Utils/Utils";

const ProfileBanner = ({userId, id}) => {
    return (
        <div className="profile-banner" style={{
            backgroundImage: `url(${getProfileBannersBaseUrl() + userId + `?dummy=${id}`})`
        }}></div>
    );
}
export default ProfileBanner;
