import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    useIonViewDidEnter,
} from "@ionic/react";
import {
    getCurrentUsersCreatorPreferences,
    updateMessagingPreferences
} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "./ManageStepComponent";

const ManageMessagingPreferences = () => {
    const [smsPrice, setSmsPrice] = useState();
    const [mmsPrice, setMMSPrice] = useState();
    const [publicSMS, setPublicSMS] = useState();
    const [publicMMS, setPublicMMS] = useState();
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    useIonViewDidEnter(() => {
        getCurrentUsersCreatorPreferences().then(prefs => {
            setSmsPrice(prefs.smsPriceInCents ? prefs.smsPriceInCents / 100 : prefs.smsPriceInCents);
            setPublicSMS(prefs.allowsPublicSMS);
            setMMSPrice(prefs.mmsPriceInCents ? prefs.mmsPriceInCents / 100 : prefs.mmsPriceInCents);
            setPublicMMS(prefs.allowsPublicMMS);
        })
    }, []);

    const successHandler = _ => {
        history.push("/settings");
        setCurrentIdx(0);
        setDisabled(false);
    };

    const failureHandler = _ => {
        alert("Something went wrong. We were unable to update your messaging preferences.");
        setDisabled(false);
    }

    const steps = [
        {
            key: "updateMsg0",
            label: "What do you want to charge per message in USD ($)?",
            placeholder: "Example $50.00. Min. $1",
            value: smsPrice,
            onChange: (e) => setSmsPrice(e.target.value),
            inputType: "number",
            inputMode: "decimal",
            onNextClicked: () => {
                if (!smsPrice || isNaN(smsPrice)) {
                    alert("Price must be a number greater than 0 e.g. 10");
                    return;
                }
                setCurrentIdx(prev => prev + 1);
                setPublicSMS(true);
            },
            onPrevClicked: () => {
                history.push("/settings");
            }
        },
        // {
        //     key: "updateMsg1",
        //     label: "Allow non-subscribers to buy and send messages too?",
        //     placeholder: "Example $50.00. Min. $1",
        //     value: smsPrice,
        //     trueLabel: "Yes",
        //     falseLabel: "No. Subscribers Only",
        //     inputType: "bool",
        //     onTrue: () => {
        //         setPublicSMS(true);
        //         setCurrentIdx(prev => prev + 1);
        //     },
        //     onFalse: () => {
        //         setPublicSMS(false);
        //         setCurrentIdx(prev => prev + 1);
        //     },
        //     onPrevClicked: () => {
        //         setCurrentIdx(0);
        //     }
        // },
        {
            key: "updateMsg2",
            label: "Do you want to allow users to buy and send media messages?",
            placeholder: "Example $50.00. Min. $1",
            trueLabel: "Yes",
            falseLabel: "No Media Messages",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                if (disabled) return;
                setDisabled(true);
                setMMSPrice(null);
                setPublicMMS(false);
                updateMessagingPreferences(smsPrice, -1.0, publicSMS, false).then(successHandler).catch(failureHandler);
            },
            onPrevClicked: () => {
                setCurrentIdx(0);
            }
        },
        {
            key: "updateMsg3",
            label: "What do you want to charge per media message in USD ($)?",
            placeholder: "Example $50.00. Min. $1",
            value: mmsPrice,
            onChange: (e) => setMMSPrice(e.target.value),
            inputType: "number",
            inputMode: "decimal",
            onNextClicked: () => {
                if (!mmsPrice || isNaN(mmsPrice)) {
                    alert("Price must be a number greater than 0 e.g. 10");
                    return;
                }
                setPublicMMS(true);
                updateMessagingPreferences(smsPrice, mmsPrice, publicSMS, true).then(successHandler).catch(failureHandler);
            },
            onPrevClicked: () => {
                setCurrentIdx(prev => prev - 1);
            }
        },
        // {
        //     key: "updateMsg4",
        //     label: "Allow non-subscribers to buy and send media messages too?",
        //     trueLabel: "Yes",
        //     falseLabel: "No. Subscribers Only",
        //     inputType: "bool",
        //     onTrue: () => {
        //         setPublicMMS(true);
        //         updateMessagingPreferences(smsPrice, mmsPrice, publicSMS, true).then(successHandler).catch(failureHandler);
        //     },
        //     onFalse: () => {
        //         if (disabled) return;
        //         setDisabled(true);
        //         setMMSPrice(null);
        //         setPublicSMS(false);
        //         updateMessagingPreferences(smsPrice, mmsPrice, publicSMS, false).then(successHandler).catch(failureHandler);
        //     },
        //     onPrevClicked: () => {
        //         setCurrentIdx(prev => prev - 1);
        //     }
        // }
    ];
    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-text-center"}>
                <ManageStep step={step} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
}

export default ManageMessagingPreferences;
