// Call this function when your app starts
import OneSignal from "onesignal-cordova-plugin";

export function OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId("087fc958-f8c0-4a2a-a349-8d03e5a44375");
    OneSignal.setNotificationOpenedHandler(function(_) {

    });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function(_) {

    });
}
