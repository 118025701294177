import React, {useEffect, useState} from 'react';
import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {pauseOutline, playOutline} from "ionicons/icons";
import {getFeeStatus, setFeeStatus} from "../../requests/MessagingFees/MessagingFeeRequests";


const MessagingFeesToggle = ({conversationId}) => {
    const [isPaused, setIsPaused] = useState();
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        getFeeStatus(conversationId).then(isPausedStatus => {
            setIsPaused(isPausedStatus);
        }).catch(_ => {
            setHasError(true);
            console.log("ERROR " + JSON.stringify(_));
        })
    }, []);
    const [disabled, setDisabled] = useState();
    const toggleStatus = () => {
        if (disabled) return;
        setDisabled(true);
        setFeeStatus(conversationId, !isPaused).then(resultingStatus => {
           setIsPaused(resultingStatus);
            setDisabled(false);
        }).catch(_ => {
            alert(`Something went wrong. We could not ${!isPaused ? "resume" : "pause"} fees.`);
            console.log("ERROR " + JSON.stringify(_));
            setDisabled(false);
        });
    }
    if (hasError) {
        return <></>;
    }
    return (
        <IonItem onClick={toggleStatus} className={'cursor-pointer'}>
            <IonIcon className={"m-r-16px"} slot={'start'} icon={isPaused ? playOutline : pauseOutline}/>
            <IonLabel>
                <h2>{isPaused ? "Resume" : "Pause"} Messaging Fees</h2>
            </IonLabel>
        </IonItem>
    );
}

export default MessagingFeesToggle;
