import React, {useState} from 'react';
import {IonPage, IonHeader, IonContent, IonButtons, IonBackButton, IonToolbar} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "../SettingsManagement/ManageStepComponent";
import {useHistory} from "react-router-dom";
import {blockNotifications, setNotificationPreferences} from "../../requests/Notifications/NotificationRequests";

const NotificationPreferences = () => {
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    const [frequency, setFrequency] = useState();
    const steps = [
        {
            key: "updateNotifications0",
            label: "Do you want to receive push notifications?",
            placeholder: "Example $50.00. Min. $1",
            value: null,
            trueLabel: "Yes!",
            falseLabel: "No",
            inputType: "bool",
            onTrue: () => {
                setCurrentIdx(prev => prev + 1);
            },
            onFalse: () => {
                if (disabled) return;
                setDisabled(true);
                blockNotifications().then(_ => {
                    setDisabled(false);
                    history.push("/settings");
                    alert("Your preferences have been set");
                }).catch(_ => {
                    alert("Something went wrong. Try again");
                    setDisabled(false);
                });
            },
            onPrevClicked: () => {
                history.goBack();
            }
        },
        {
            key: "updateNotifications1",
            label: `At most, how often do you want to get notified in minutes?`,
            placeholder: "e.g. 15 for once every 15 min",
            value: frequency,
            onChange: (e) => setFrequency(e.target.value),
            inputType: "number",
            inputMode: "numeric",
            onNextClicked: () => {
                if (!frequency || disabled) return;
                if (isNaN(frequency) || frequency < 0) {
                    alert("Your value must be greater than or equal to 1");
                    return;
                }
                setDisabled(true);
                return setNotificationPreferences(frequency).then(_ => {
                    setDisabled(false);
                    history.push("/settings");
                    setCurrentIdx(0);
                    setFrequency(null);
                    alert("Your preferences have been set");
                }).catch(_ => {
                    setDisabled(false);
                    alert("Something went wrong. Try again!");
                });
            },
            onPrevClicked: () => {
                if (disabled) return;
                setCurrentIdx(0);
            }
        }
    ];
    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ManageStep step={step} disabled={disabled}/>
            </IonContent>
        </IonPage>
    )
}

export default NotificationPreferences;
