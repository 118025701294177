import React from 'react';
import {Link} from "react-router-dom";
import {getProfilePicturesBaseUrl} from "../../../Utils/Utils";
import {
    SPECIAL_MESSAGE_ICON,
    SPECIAL_MESSAGE_BUTTON_TEXT,
    SPECIAL_MESSAGE_LABEL_FUNCTIONS
} from "./SpecialMessageConstants";

const SpecialMessageLeft = ({message, setShowDetailsModal, handles}) => {
    const {id, senderId, body, createdAt, media, messageType, metadata} = message;
    return (
        <li id={id}>
            <div className="chat_message message_inner_wrapper incoming_msg">
                <Link to={"/p/" + handles[senderId]}>
                    <img className={"chat_picture border-2px-solid-green object-fit-cover"}
                         src={getProfilePicturesBaseUrl() + senderId} alt={""}
                         onError={({currentTarget}) => {
                             currentTarget.onerror = null;
                             currentTarget.src = "assets/id.png";
                         }}
                    />
                </Link>
                <div className="time_message_section">
                    <div className="chat_time">
                    </div>
                    <div
                        onClick={() => {
                            if (messageType !== "TIP" && messageType !== "ARRIVAL_ALL_SET") {
                                setShowDetailsModal(message)
                            }
                        }}
                        className="chat_message flex-column justify-content-center cursor-pointer"
                        style={{height: '100px', border: '2px solid white', padding: '16px', borderRadius: '12px'}}
                    >
                        <div className={'flex-row align-items-center'} style={{height: '50px'}}>
                            {SPECIAL_MESSAGE_ICON[messageType]}
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            {SPECIAL_MESSAGE_LABEL_FUNCTIONS[messageType](message)}
                        </div>
                        <div className={'underline'} style={{height: '50px'}}>
                            {SPECIAL_MESSAGE_BUTTON_TEXT[messageType]}
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
            </div>
            <br/>
        </li>
    );
}

export default SpecialMessageLeft;
