import React, {useState} from 'react';
import {
    IonPage,
    IonToolbar,
    IonHeader,
    IonContent,
    IonBackButton,
    IonButtons,
    useIonViewDidEnter
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import ManageStep from "../SettingsManagement/ManageStepComponent";
import {creatorCreateBounty} from "../../requests/Bounties/BountyRequests";
import {getSearchParamOrDefault} from "../../Utils/Utils";

const CreatorBountyCreationPage = ({}) => {
    const {id} = useParams();
    const history = useHistory();
    const [description, setDescription] = useState('');
    const [currentIdx, setCurrentIdx] = useState(0);
    const [disabled, setDisabled] = useState();
    const steps = [
        {
            key: 'link-setup-0',
            label: "What might you do?",
            placeholder: "e.g. Do a collaboration with...",
            value: description,
            onChange: (e) => setDescription(e.target.value),
            inputType: "text",
            inputMode: 'text',
            onNextClicked: () => {
                if (!description || disabled) return;
                setDisabled(true);
                creatorCreateBounty(description).then(data => {
                    const redirect = getSearchParamOrDefault("redirect", "/chats");
                    history.replace(redirect);
                    setDisabled(false);
                }).catch(_ => {
                    alert("Something went wrong. Try again!");
                    setDisabled(false);
                });
            },
            onPrevClicked: () => {
                history.goBack();
            }
        }
    ];
    useIonViewDidEnter(() => {
        setCurrentIdx(0);
        setDescription('');
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ManageStep step={steps[currentIdx]} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
}

export default CreatorBountyCreationPage;
