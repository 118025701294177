import React, {useState} from 'react';
import {
    IonContent,
    IonItem
} from '@ionic/react';
import {confirmArrival, markArrival} from "../../../../requests/Meeting/MeetingRequests";
const ConfirmArrival = ({message, closeModal, currentUserId}) => {
    const {metadata} = message;
    const {id, hostingPreference, fanId, creatorId} = JSON.parse(metadata.booking);
    const isCreatorHosting = hostingPreference === "STAR_HOSTS";
    const [disabled, setDisabled] = useState(false);
    const confirmArrived = () => {
        if (disabled) return;
        setDisabled(true);
        confirmArrival(id).then(_ => {
            setDisabled(false);
            closeModal();
        }).catch(_ => {
            alert("Something went wrong. We could not confirm your arrival. Try again.");
            setDisabled(false);
        })
    }
    if (creatorId === currentUserId) {
        return (
            <IonContent>
                <IonItem>
                    {`Once the fan has marked confirmed your presence, you will get paid. You will not get paid if they do not confirm your presence, so don't do anything until we tell you your presence has been confirmed.`}
                </IonItem>
            </IonContent>
        );
    }
    return (
        <IonContent>
            <IonItem>
                Confirm Arrival
            </IonItem>
            <IonItem>
                {`Confirm the star is in your presence. You should do this right away. They will ${isCreatorHosting ? "tell you to" : ""} leave if you don't.`}
            </IonItem>
            <br/>
            <button className="btn login_btn btn-red-gradient width-100"
                    onClick={confirmArrived}>
                {disabled ? "Loading..." : `Confirm Arrival`}
            </button>
        </IonContent>
    );
}

export default ConfirmArrival;
