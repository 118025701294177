import React, {useEffect, useRef, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
    IonTextarea,
    IonCheckbox,
    IonLabel,
    IonItem
} from "@ionic/react";
import {useHistory} from "react-router-dom";
import {image} from "ionicons/icons";
import {createMassSend, createMMSMassSend, directUploadMassMMS} from "../../requests/MassSends/MassSendRequests";

const SubscriberSendMessageButton = ({}) => {
    const history = useHistory();
    const [postToWall, setPostToWall] = useState(false);
    const [isVideoUpload, setIsVideoUpload] = useState();
    const [disabled, setDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState();
    const [mmsMessage, setMMSMessage] = useState();
    const [chatInput, setChatInput] = useState('');
    const triggerMassSend = (messageStr, urlStr) => {
        createMassSend(messageStr, urlStr).then(message => {
            setDisabled(false);
            setChatInput("");
            alert("Your message is being sent! It will appear on this page once your subscribers have started to receive it.")
            history.push("/chats");
        }).catch(_ => {
            alert("Something went wrong. We were not able to message your subscribers.");
            setDisabled(false);
        });
    }
    const handleSMSClick = () => {
        if (!chatInput || disabled) return;
        setDisabled(true);
        triggerMassSend(chatInput);
    };

    const handleImageClick = () => {
        if (disabled) return;
        document.getElementById("send-photo-file-input").click();
    };

    return (
        <form
            className={'send-message-form'}
            style={{display: 'flex'}}
            onSubmit={(e) => {
                e.preventDefault();
                return handleSMSClick();
            }}>
            <textarea
                className={'special-text-area'}
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                style={{
                    width: "calc(100vw - 150px)",
                    color: 'white',
                    height: '50px',
                    fontSize: '14px',
                    padding: '5px'
                }}
            />
            <button
                type={'submit'}
                className={'btn-red-gradient'}
                style={{
                    width: '100px',
                    textAlign: 'center',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                Send
            </button>
            <button type={'button'}
                    onClick={handleImageClick}
                    style={{
                        width: '48px',
                        textAlign: 'center',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#F47B60',
                        background: 'rgba(107, 107, 107, 1)',
                        border: '2px solid #F47B60'
                    }}
            >
                <IonIcon icon={image}/><
            /button>
            <input
                id='send-photo-file-input'
                className={"display-none"}
                type={'file'}
                onChange={(e) => {
                    setFile(e.target.files[0]);
                    setMMSMessage(chatInput || '');
                    setShowModal(true);
                }}
            />
            <IonModal isOpen={showModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => {
                                setShowModal(false);
                                setFile(null);
                                setMMSMessage("");
                            }}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Send Mass MMS</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {
                    isVideoUpload ?
                        <video
                            controls
                            className={'mt-5px'}
                            style={{width: '200px'}}
                        >
                            <source src={file ? URL.createObjectURL(file) : ''}
                                    type={"video/mp4"}></source>
                        </video>
                        :
                        <img className={'mt-5px'} src={file ? URL.createObjectURL(file) : ''}
                             style={{width: '100px', objectFit: 'cover'}}
                             onError={({currentTarget}) => {
                                 currentTarget.onerror = null;
                                 setIsVideoUpload(true);
                             }}
                        />
                }

                <IonTextarea
                    className={'color-white'}
                    onIonChange={(e) => setMMSMessage(e.target.value)}
                    value={mmsMessage}
                    style={{padding: '5px 10px'}}
                    placeholder={'Enter any message you want to send with your media here'}
                />
                <br/>
                <IonItem>
                    <IonCheckbox slot="start"
                                 checked={postToWall}
                                 onIonChange={(e) => {
                                     setPostToWall(prev => !prev);
                                 }}/>
                    <IonLabel>&nbsp;&nbsp;&nbsp;Post to your public wall</IonLabel>
                </IonItem>
                <br/>
                <button
                    className={'btn btn-red-gradient'}
                    onClick={() => {
                        if (disabled) return;
                        setDisabled(true);
                        return createMMSMassSend(mmsMessage, postToWall).then(directUploadLink => {
                            return directUploadMassMMS(directUploadLink, file).then(_ => {
                                setDisabled(false);
                                setShowModal(false);
                                alert("Your message has successfully sent. It may take a few minutes to appear on this page, as we need to scan it first for viruses, etc.");
                                history.push("/chats");
                            })
                        }).catch(_ => {
                            alert("Something went wrong. We were not able to send your media message to all of your subscribers")
                            setDisabled(false);
                        });
                    }}
                >
                    {disabled ? "Loading..." : `Send MMS${postToWall ? " & Post" : ""}`}
                </button>
            </IonModal>
            <button className={'display-none'} id={'open-mms-modal'}/>
        </form>
    );
}

export default SubscriberSendMessageButton;
