import React from 'react';
import {IonButton, IonInput, IonItem, IonItemDivider, IonList, IonSpinner, IonTextarea} from "@ionic/react";

const ManageStep = ({step, disabled}) => {
    return (
        <IonList>
            {disabled ? <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/> :
                <>
                    <IonItem className={"ion-text-center"}>
                        {step.label}
                    </IonItem>
                    {
                        step.inputType === "textarea" ?
                            <IonItem key={step.key}>
                                <IonTextarea id={"ion-step-input"}
                                             className={'mt-10'}
                                             type={step.inputType}
                                             value={step.value}
                                             onIonChange={step.onChange}
                                             placeholder={step.placeholder}
                                             autoFocus={true}
                                />
                            </IonItem>
                            :
                            step.inputType === "bool" ?
                                <>
                                    <IonItem>
                                        <IonButton
                                            className={"m-0-auto"}
                                            onClick={step.onTrue}>
                                            {step.trueLabel}
                                        </IonButton>
                                    </IonItem>
                                    <IonItem>
                                        <IonButton
                                            className={"m-0-auto"}
                                            onClick={step.onFalse}
                                        >
                                            {step.falseLabel}
                                        </IonButton>
                                    </IonItem>
                                    <IonItemDivider>
                                    </IonItemDivider>
                                </>
                                :
                                <IonItem>
                                    <IonInput
                                        key={step.key}
                                        id={"ion-step-input"}
                                        className={"ion-text-center"}
                                        type={step.inputType}
                                        value={step.value}
                                        onIonChange={step.onChange}
                                        autoFocus={true}
                                        inputMode={step.inputMode || "text"}
                                        placeholder={step.placeholder}
                                    />
                                </IonItem>
                    }
                    {
                        step.inputType === "bool" ? <></> :
                            <IonItem>
                                <IonButton className={'m-0-auto'}
                                           onClick={step.onNextClicked}
                                >Next</IonButton>
                            </IonItem>
                    }
                    <IonItem>
                        <IonButton className={'m-0-auto'}
                                   onClick={step.onPrevClicked}
                        >
                            Go Back
                        </IonButton>
                    </IonItem>
                </>
            }
        </IonList>
    )
}
export default ManageStep;
