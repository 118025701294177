import React, {useEffect} from 'react';
import {getSearchParamOrDefault} from "../../Utils/Utils";
import {useHistory} from "react-router-dom";
import {IonPage, useIonViewDidEnter} from "@ionic/react";

const RedirectPage = () => {
    const history = useHistory();
    useIonViewDidEnter(() => {
        const redirect = getSearchParamOrDefault("redirect", "/chats");
        setTimeout(() => {
            history.push(redirect);
        }, 1000);
    }, []);
    return (
      <IonPage></IonPage>
    );
}

export default RedirectPage;
