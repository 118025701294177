import React, {useState} from 'react';
import {
    IonPage,
    IonContent,
    IonItem,
    IonTextarea,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonHeader,
    IonInput
} from "@ionic/react";
import {useHistory, useParams} from "react-router-dom";
import {formatCurrency} from "../../Utils/Utils";
import * as UpChunk from "@mux/upchunk";
import {createVideoForSale} from "../../requests/VideosForSale/VideosForSaleRequests";

const SellVideoPage = ({}) => {
    const {id} = useParams();
    const history = useHistory();
    const [text, setText] = useState();
    const [file, setFile] = useState();
    const [price, setPrice] = useState(5.51);
    const [disabled, setDisabled] = useState();
    const handleSellVideoClicked = () => {
        if (!price || isNaN(price) || price < 5.5) {
            alert("You must charge at least $5.50 for the video");
            return;
        }
        if (!file) {
            alert("You must select a video");
            return;
        }
        if (disabled) return;
        setDisabled(true);
        const handleError = (_) => {
            setDisabled(false);
            alert("Something went wrong. You may have selected an invalid file type");
        }
        const handleSuccess = (_) => {
            setDisabled(false);
            setFile(null);
            alert(`Your upload has succeeded!${id !== "ALL" ? " It may take a minute to appear in your fan's inbox" : " It may take a few minutes to appear in your fans' inboxes"}`);
            history.push("/chats");
        }
        createVideoForSale(price, text, id === "ALL" ? "" : id).then(directUploadUrl => {
            const upload = UpChunk.createUpload({
                endpoint: directUploadUrl, // Authenticated url
                file: file, // File object with your video file’s properties
                chunkSize: 5120, // Uploads the file in ~5mb chunks
            });
            upload.on('error', handleError);
            upload.on('success', handleSuccess);
        });
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => {
                            setDisabled(false)
                            setFile(null);
                            setText("");
                            history.goBack();
                        }}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>Sell {id === "ALL" ? "Your Subscribers" : "User"} A Video</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    How much to charge? (Min $5.50)
                </IonItem>
                <IonItem>
                    <IonInput
                        type={'number'}
                        min={5.50}
                        step={0.01}
                        placeholder={'Set price here'}
                        value={price} onIonChange={e => setPrice(e.target.value)}/>
                </IonItem>
                <IonItem>
                    <IonTextarea
                        type={'text'}
                        maxlength={200}
                        placeholder={'Enter a message to send with the video here (optional)'}
                        value={text}
                        onIonChange={(e) => setText(e.target.value ? e.target.value.substr(0, 200) : e.target.value)}/>
                </IonItem>
                {!file ? <></> :
                    <IonItem>
                        <video src={URL.createObjectURL(file)}
                               width={'200px'}
                               controls={true}
                        />
                    </IonItem>
                }
                <br/>
                <button
                    className={'btn btn-red-gradient m-0-auto width-100'}
                    onClick={() => {
                        document.getElementById('sell-video-file-input').click();
                    }}
                >
                    {"Upload Video"}
                </button>
                <input className={'display-none'}
                       type={'file'}
                       id={'sell-video-file-input'}
                       onChange={(e) => {
                           setFile(e.target.files[0]);
                       }}

                />
                {!file ? <></> :
                    <>
                        <br/>
                        <br/>
                        <button
                            className={'btn btn-red-gradient width-100'}
                            onClick={handleSellVideoClicked}
                        >
                            {disabled ? "Loading... Be patient" : "Sell Video for " + formatCurrency(price * 100)}
                        </button>
                    </>
                }
            </IonContent>
        </IonPage>
    );
}

export default SellVideoPage;
