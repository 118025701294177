import {postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const createTip = (creatorId, amount) => {
    return postRequest(getBaseUrl() + `/tips?amount=${amount}&creatorId=${creatorId}`).then(defaultResponseHandler);
}

export const createCryptoTip = (creatorId, amount) => {
    return postRequest(getBaseUrl() + `/crypto-tips?amount=${amount}&creatorId=${creatorId}`).then(defaultResponseHandler);
}

export const testCryptoTip = () => {
    return postRequest(getBaseUrl() + `/crypto-tips/crypto-test`).then(defaultResponseHandler);
}
