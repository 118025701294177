import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonInput
} from "@ionic/react";
import {checkHandleAvailability, saveHandle} from "../../requests/Handles/HandleRequests";

const HandlesModal = ({closeModal, setHasHandle, hideHeader = false}) => {
    const [newHandle, setNewHandle] = useState('');
    const [disabled, setDisabled] = useState();
    const handleSaveClicked = () => {
        if (disabled || !newHandle) return;
        const formattedNewHandle = newHandle.trim().replace(/[^a-z0-9]/gi, "")
        if (!formattedNewHandle) return;
        setDisabled(true);
        return checkHandleAvailability(formattedNewHandle).then(handleIsAvailable => {
            if (handleIsAvailable) {
                return saveHandle(formattedNewHandle).then(_ => {
                    setHasHandle(true);

                }).catch(_ => {
                    alert("Something went wrong. Make sure your handle only includes letters and numbers")
                    setDisabled(false);
                });
            } else {
                alert("The handle you specified is unavailable");
                setNewHandle("");
                setDisabled(false);
            }
        });
    }

    return (
        <>
            {hideHeader ? <></> :
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={closeModal}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Create Your Handle</IonTitle>
                    </IonToolbar>
                </IonHeader>
            }
            <IonContent>
                <IonInput
                    id={'handle-first-input'}
                    className={'ion-text-center'}
                    value={newHandle}
                    onIonInput={(e) => {
                        e.preventDefault();
                        setNewHandle(e.target.value.trim().replace(/[^a-z0-9]/gi, ""));
                    }}
                    placeholder={"Enter a handle name. This cannot be changed"}
                    autofocus={true}
                />
                <br/>
                <div className={"d-flex ion-justify-content-center"}>
                    <button className="btn login_btn btn-red-gradient" onClick={handleSaveClicked}>Save Handle</button>
                </div>
            </IonContent>
        </>
    );
}

export default HandlesModal;
