import React from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {IonPage, IonHeader, IonToolbar, IonContent, IonSpinner, useIonViewDidEnter} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {setLocation} from "../../requests/Location/LocationRequests";
const ManageLocationPage = ({}) => {
    const history = useHistory();
    useIonViewDidEnter(() => {
        navigator.geolocation.getCurrentPosition(({coords}) => {
            const latitude = coords.latitude;
            const longitude = coords.longitude;
            setLocation(latitude, longitude).then(_ => {
                setTimeout(() => {
                    alert("Your location has been set!");
                    history.replace("/settings");
                }, 300);
            }).catch(_ => {
                alert("Something went wrong, we were unable to find your latitude or longitude. Please check your browser settings and refresh the page.");
            })
        }, () => {
            alert("Something went wrong, we were unable to find your latitude or longitude. Please check your browser settings and refresh the page.");
        }, {enableHighAccuracy: true});
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    )
}
export default ManageLocationPage;
