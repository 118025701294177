import React, {useState, useEffect, useRef} from 'react';
import './VideoChat.css';
import {IonPage, IonContent} from '@ionic/react';
import DailyIframe from "@daily-co/daily-js";
import styled from "styled-components";
import {getSearchParamOrDefault} from "../../Utils/Utils";
import {useParams} from "react-router-dom";
import {startVideoChat} from "../../requests/Meeting/MeetingRequests";

const CALL_OPTIONS = {
    iframeStyle: {
        width: "100%",
        height: "100%",
        border: "1px solid #e6eaef",
        borderRadius: "6px 6px 0 0",
    },
    showLeaveButton: true,
    showFullscreenButton: true,
      showLocalVideo: true,
      showParticipantsBar: true,
};

const DailyHostedVideoChat = () => {
    const videoRef = useRef(null);
    const [height, setHeight] = useState(400);
    const [callframe, setCallframe] = useState(null);
    const [callUrl, setCallUrl] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [password, setPassword] = useState('');
    const {id} = useParams();
    const handleStartVideoChat = () => {
        if (disabled) return;
        const userId = getSearchParamOrDefault("userId", "");
        setDisabled(true);
        return startVideoChat(id, userId, password).then(res => {
            setCallUrl(res.urlWithToken);
            setTimeout(() => {
                if (!videoRef || !videoRef?.current || callframe) return;
                CALL_OPTIONS.url = res.urlWithToken;
                const newCallframe = DailyIframe.createFrame(
                    videoRef.current,
                    CALL_OPTIONS
                );
                newCallframe.join().then(() => {
                    setHeight((videoRef?.current?.clientWidth || 500) * 0.75);
                    setCallframe(newCallframe);
                });
                setDisabled(false);
            }, 200);
        }).catch(_ => {
            alert("Something went wrong. Try again or refresh the app/ page.");
            setDisabled(false);
        });
    }

    if (callUrl) {
        return (
            <>
                <VideoContainer height={height}>
                    <Callframe ref={videoRef} />
                </VideoContainer>
            </>
        )
    }

    return (
        <IonPage>
            <section className="login login-alt position-relative">
                <div className="container">
                    <div className="col-md-10 m-auto">
                        <div className={`login-main shadow-lg p-md-5 p-4 `}>
                            <div className="d-block">
                                <img
                                    style={{height: "50px", marginBottom: '50px'}}
                                    src="assets/fav-icon-white.png"
                                    alt="logo"
                                    className="img-fluid d-inline"
                                />
                                {
                                    callUrl ? <></> :
                                        <>
                                            <p className={'color-white'}>Enter your password to join the chat</p>
                                            <br/>
                                            <input type="password"
                                                   placeholder="Password"
                                                   className="form-control form-control-lg"
                                                   value={password}
                                                   onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <button className={"btn btn-red-gradient rounded-pill text-uppercase mt-3"}
                                                    onClick={handleStartVideoChat}
                                            >
                                                {disabled ? "Loading..." : "Start video chat"}
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </IonPage>
    )

}

const Header = styled.div`
 font-size: 36px;
 text-align: center;
 padding: 24px;
`;
const VideoContainer = styled.div`
 margin: auto;
 //max-width: 1000px;
  height: 100vh;
`;
const Callframe = styled.div`
 width: 100%;
 height: 100%;
`;

export default DailyHostedVideoChat;
