import React from 'react';
import {IonPage, IonHeader, IonContent, IonItem, IonToolbar} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";

const UpdateRequiredPage = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    We are truly sorry to inconvenience you but you need to update your app.
                </IonItem>
            </IonContent>
        </IonPage>
    )
}

export default UpdateRequiredPage;
