import React, {useEffect, useState} from 'react';
import {getProfilePicturesBaseUrl} from "../../Utils/Utils";
// import {getUserProfilePhoto} from "../../Requests/ProfileRequests";

const ProfileAvatar = ({userId}) => {

    return (
        <img alt="user-avatar"
             src={getProfilePicturesBaseUrl() + userId + "?dummy=" + new Date().getUTCMinutes()}
             onError={({currentTarget}) => {
                 currentTarget.onerror = null;
                 currentTarget.src = "assets/id.png";
             }}
             className="img-fluid shadow-lg object-fit-cover"/>
    );
}

export default ProfileAvatar;
