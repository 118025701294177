import React, {useState} from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonInput,
    IonItem,
    IonList,
    IonButton,
    IonItemDivider,
    useIonViewDidEnter, IonSpinner, IonBackButton, IonButtons
} from "@ionic/react";
import {getUsersReferralLinks, saveReferralLink} from "../../requests/Referral/ReferralRequests";
import {useHistory} from "react-router-dom";
import {isValidReferralLinkOrHandle} from "../../Utils/Utils";
import ExistingReferralLink from "../../components/ReferralLinks/ExistingReferralLink";

const ReferralLinkManagement = ({}) => {
    const history = useHistory();
    const [links, setLinks] = useState([]);
    const [newLink, setNewLink] = useState();
    const [disabled, setDisabled] = useState();
    useIonViewDidEnter(() => {
        getUsersReferralLinks().then(res => {
            setLinks(res.links);
        }).catch(_ => {
            history.goBack();
        });
    });

    const saveNewLink = () => {
        if (!newLink || disabled) return;
        setDisabled(true);
        if (!isValidReferralLinkOrHandle((newLink ? newLink.toLowerCase() : ""))) {
            alert("Your link may only contain letters, numbers, periods (.), and hyphens/dashes (-)");
            setDisabled(false);
            return;
        }
        return saveReferralLink(newLink).then(_ => {
            setLinks(prev => prev.concat(newLink));
            setDisabled(false);
            setNewLink('');
        }).catch(_ => {
            setDisabled(false);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {disabled ? <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/> :
                    <IonList>
                        <IonItem>
                            Your Referral Links: (click to copy)
                        </IonItem>
                        <IonItem>
                            Earn 5% of our take of the non-referral earnings of every star/ creator you refer to the
                            platform indefinitely by getting the star to sign up using one of your referral links.
                            Referral earnings will be paid out indefinitely, but we reserve the right to cancel the
                            referral program and/ or its associated payouts at any time.
                        </IonItem>
                        {
                            links.map(link => (
                                <ExistingReferralLink link={"https://fannumbers.com/r/" + link}/>
                            ))
                        }
                        <IonItemDivider/>
                        <IonItem>
                            Enter your desired referral links using the input below. You may create 10 or fewer unique
                            links. Note: links are not case-sensitive.
                        </IonItem>
                        <IonItemDivider/>
                        {
                            links && links.length ? <></> :
                                <>
                                    <IonItem>
                                        To clarify, if you enter
                                        'easy-money' into the input, your link will be
                                        'https://fannumbers.com/r/easy-money'. If you
                                        enter 'passive-income', your link will be
                                        'https://fannumbers.com/r/passive-income'. Only letters, numbers, periods, and
                                        hyphens are allowed. No spaces.
                                    </IonItem>
                                    <IonItemDivider/>
                                    <IonItem>
                                        If you are wondering why we are doing this, the typical consumer facing company
                                        spends 5-20% of its revenues on marketing. That marketing could turn out to be
                                        completely ineffective and yet the money will be gone. We prefer certainty. An
                                        ad is just a way of informing people about a product, so if you can do that
                                        well, you stand to share in our success. But if you can't, we don't lose a cent.
                                    </IonItem>
                                </>
                        }
                        <IonItem>
                            <IonInput
                                placeholder={"Enter link here e.g. my-custom-link"}
                                inputMode={"text"}
                                type={"text"}
                                value={newLink}
                                onIonChange={(e) => {
                                    if (disabled) return;
                                    setNewLink(e.target.value)
                                }}
                            />
                        </IonItem>
                        <IonItem>
                            <IonButton className={"m-0-auto"} disabled={disabled} onClick={saveNewLink}>Save
                                Link</IonButton>
                        </IonItem>
                    </IonList>
                }
            </IonContent>
        </IonPage>
    )
};

export default ReferralLinkManagement;
