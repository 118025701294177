import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {getRequest as noAuthGet} from "../BaseRequests/BaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const checkIfCurrentUserHasHandle = () => {
    return getRequest(getBaseUrl() + "/handles/has-handle").then(defaultResponseHandler);
}

export const getCurrentUsersHandle = () => {
    return getRequest(getBaseUrl() + '/handles').then(defaultResponseHandler);
}

export const saveHandle = (handle) => {
    return postRequest(getBaseUrl() + "/handles?handle=" + handle, {}).then(defaultResponseHandler);
}

export const checkHandleAvailability = (handle) => {
    return getRequest(getBaseUrl() + "/handles/availability?handle=" + handle).then(defaultResponseHandler);
}

export const getUserIdForHandle = (handle) => {
    return noAuthGet(getBaseUrl() + "/handles/user-id/" + handle).then(defaultResponseHandler);
}

export const getHandleForUserId = (userId) => {
    return noAuthGet(getBaseUrl() + "/handles/handle/" + userId, {}).then(defaultResponseHandler);
}
