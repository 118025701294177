import React, {useState} from 'react';
import {
    IonPage,
    IonToolbar,
    IonHeader,
    IonContent,
    IonBackButton,
    IonButtons,
    IonList,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonIcon,
    IonItem,
    IonLabel,
    IonReorderGroup,
    IonReorder, useIonViewDidEnter, IonFabButton, IonFab, useIonViewDidLeave
} from "@ionic/react";
import {add, trash} from 'ionicons/icons';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {addLinkToLinkTree, getUsersLinkTree} from "../../requests/LinkTree/LinkTreeRequests";
import {isApp} from "../../Utils/Utils";
import ManageStep from "../SettingsManagement/ManageStepComponent";
import {createBounty} from "../../requests/Bounties/BountyRequests";

const BountyCreationPage = ({}) => {
    const {id} = useParams();
    const history = useHistory();
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(5.99);
    const [currentIdx, setCurrentIdx] = useState(0);
    const [disabled, setDisabled] = useState();
    const steps = [
        {
            key: 'link-setup-0',
            label: "What do you want to see?",
            placeholder: "e.g. Do a collaboration with...",
            value: description,
            onChange: (e) => setDescription(e.target.value),
            inputType: "text",
            inputMode: 'text',
            onNextClicked: () => {
                if (!description) return;
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                history.goBack();
            }
        },
        {
            key: 'link-setup-1',
            label: "What are you willing to pledge?",
            placeholder: "e.g. 50 for $50",
            value: price,
            onChange: (e) => setPrice(e.target.value),
            inputType: "number",
            inputMode: 'decimal',
            onNextClicked: () => {
                if (disabled || !price || price < 5.99) return;
                setDisabled(true);
                createBounty(id, price, description).then(paymentSecret => {
                    history.replace("/pay/" + paymentSecret);
                    setDisabled(false);
                    setCurrentIdx(0);
                    setDescription('');
                    setPrice(5.99);
                }).catch(_ => {
                    alert("Something went wrong. Try again");
                    setDisabled(false);
                })
            },
            onPrevClicked: () => {
                setCurrentIdx(0);
            }
        }
    ];
    useIonViewDidEnter(() => {
        setCurrentIdx(0);
        setDescription('');
        setPrice(5.99);
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ManageStep step={steps[currentIdx]} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
}

export default BountyCreationPage;
