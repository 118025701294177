import React, {useEffect, useState} from 'react';
import TinderStack from "./TinderStack";
import './TinderSearch.css'
import ExpandedVideos from "./ExpandedVideos/ExpandedVideos";
import MobileBadges from "./Badges/Mobile/MobileBadges";
import WebBadges from "./Badges/Desktop/WebBadges";
import SwipeButtons from "./SwipeButtons/SwipeButtons";
import CategoriesSelector from "./CategoriesSelector/CategoriesSelector";
import Badge from "./Badges/Badge";
import WebSearchSlidersWrapper from "./SearchSliders/Desktop/WebSearchSlidersWrapper";
import MobileSearchSlidersWrapper from "./SearchSliders/Mobile/MobileSearchSlidersWrapper";
import {searchUsers} from "../../requests/Requests/SearchRequests";
import {IonPage, IonContent, useIonViewDidEnter} from "@ionic/react";
import {useHistory, useParams} from "react-router-dom";
import {replaceAll} from "../../Utils/Utils";
import DesktopHeader from "../Header/DesktopHeader";
import MobileHeader from "../Header/MobileHeader";
//season 7, episode 10 L&O CI <-- I forgot why I wrote this but something ridiculous happened during this episode

const TinderStyleSearch = () => {
    const history = useHistory();
    let {id} = useParams();
    id = replaceAll(id, "%23", "#");
    const [phone, setPhone] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [profile, setProfile] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    const [filterValue, setFilterValue] = useState();
    const [liked, setLiked] = useState([]);
    const [disliked, setDisliked] = useState([]);
    const [sortedTags, setSortedTags] = useState([]);
    const [showSliders, setShowSliders] = useState();
    const topCardRef = React.createRef();
    const [tags, setTags] = useState(window.location.pathname.split("/").pop().replaceAll("%20", " ").split(" "));
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [maxDistance, setMaxDistance] = useState(null);
    const [lastIdx, setLastIdx] = useState(0);

    const [maxPriceForRequests, setMaxPriceForRequests] = useState(null);
    const [maxPriceForVideoChats, setMaxPriceForVideoChats] = useState(null);
    const [maxPriceForBookings, setMaxPriceForBookings] = useState(null);
    const [maxPriceForSubscribers, setMaxPriceForSubscribers] = useState(null);
    const [maxPriceForTextMessage, setMaxPriceForTextMessage] = useState(null);
    const [maxPriceForMMS, setMaxPriceForMMS] = useState(null);
    const [minApprovalRating, setMinApprovalRating] = useState(null);
    const [mustAcceptRequests, setMustAcceptRequests] = useState(false);
    const [mustAcceptBookings, setMustAcceptBookings] = useState(false);
    const [mustAcceptVideoChats, setMustAcceptVideoChats] = useState(false);

    useEffect(() => {
        setTags(id.replaceAll("%20", " ").split(" "));
        performNewSearch(
            id.replaceAll("%20", " ").split(" "),
            lat,
            lng,
            maxPriceForSubscribers,
            maxPriceForTextMessage,
            maxPriceForMMS,
            maxPriceForRequests,
            maxPriceForVideoChats,
            maxPriceForBookings,
            minApprovalRating,
            mustAcceptRequests,
            mustAcceptBookings,
            mustAcceptVideoChats,
            maxDistance
        ).then(r => {
        }).catch(_ => {
        });
    }, [id]);

    useIonViewDidEnter(() => {
        performNewSearch(
            id.replaceAll("%20", " ").split(" "),
            lat,
            lng,
            maxPriceForSubscribers,
            maxPriceForTextMessage,
            maxPriceForMMS,
            maxPriceForRequests,
            maxPriceForVideoChats,
            maxPriceForBookings,
            minApprovalRating,
            mustAcceptRequests,
            mustAcceptBookings,
            mustAcceptVideoChats,
            maxDistance
        ).then(r => {
        }).catch(_ => {
        });
    });

    const reset = () => {
        setTags([]);
        window.location.reload();
    }


    const handleRemoveCategory = (newTag) => {
        if (newTag === window.location.pathname.split("/").pop()) {
            return;
        }
        let copy = [].concat(tags);
        let idx = copy.indexOf(newTag);
        if (idx !== -1) {
            copy.splice(idx, 1);
        }
        if (newTag.startsWith("#")) {
            setTags(copy);
        } else {
            return performNewSearch(
                copy,
                lat,
                lng,
                maxPriceForSubscribers,
                maxPriceForTextMessage,
                maxPriceForMMS,
                maxPriceForRequests,
                maxPriceForVideoChats,
                maxPriceForBookings,
                minApprovalRating,
                mustAcceptRequests,
                false,
                mustAcceptVideoChats,
                maxDistance
            );
            setTags(copy);
            if (newTag === "Open to Bookings") {
                setMustAcceptBookings(false);
                return performNewSearch(
                    copy,
                    lat,
                    lng,
                    maxPriceForSubscribers,
                    maxPriceForTextMessage,
                    maxPriceForMMS,
                    maxPriceForRequests,
                    maxPriceForVideoChats,
                    maxPriceForBookings,
                    minApprovalRating,
                    mustAcceptRequests,
                    false,
                    mustAcceptVideoChats,
                    maxDistance
                );
            } else if (newTag === "Open to Video Chats") {
                setMustAcceptVideoChats(false);
                return performNewSearch(
                    copy,
                    lat,
                    lng,
                    maxPriceForSubscribers,
                    maxPriceForTextMessage,
                    maxPriceForMMS,
                    maxPriceForRequests,
                    maxPriceForVideoChats,
                    maxPriceForBookings,
                    minApprovalRating,
                    mustAcceptRequests,
                    mustAcceptBookings,
                    false,
                    maxDistance
                );
            } else if (newTag === "Open to Requests") {
                setMustAcceptRequests(false);
                return performNewSearch(
                    copy,
                    lat,
                    lng,
                    maxPriceForSubscribers,
                    maxPriceForTextMessage,
                    maxPriceForMMS,
                    maxPriceForRequests,
                    maxPriceForVideoChats,
                    maxPriceForBookings,
                    minApprovalRating,
                    false,
                    mustAcceptBookings,
                    mustAcceptVideoChats,
                    maxDistance
                );
            }
        }
    }

    const isNotValidPhoneNumber = (phoneNum) => {
        return !phoneNum || phoneNum.length < 10 || (phoneNum.length !== 10 && (phoneNum.length !== 12 || !phoneNum.startsWith("+1")));
    }

    const handleSignUp = () => {
        if (disabled) return;
        if (isNotValidPhoneNumber(phone)) {
            alert("Your phone number doesn't look right");
            return;
        }
        setDisabled(true);
    }

    const performNewSearch = (
        tags,
        lat,
        lng,
        maxSubscriptionPrice,
        maxSMSPrice,
        maxMMSPrice,
        maxPriceForRequests,
        maxPriceForVideoChats,
        maxPriceForBookings,
        approvalRate,
        mustAcceptRequests,
        mustAcceptBookings,
        mustAcceptVideoChats,
        maxDistance
    ) => {
        let copyOfTags = tags;
        return searchUsers(
            tags,
            lat,
            lng,
            maxSubscriptionPrice,
            maxSMSPrice,
            maxMMSPrice,
            maxPriceForRequests,
            maxPriceForVideoChats,
            maxPriceForBookings,
            approvalRate,
            mustAcceptRequests,
            mustAcceptBookings,
            mustAcceptVideoChats,
            maxDistance,
            0,
            36
        ).then(searchResults => {
            console.log(`Search Results for ${JSON.stringify(copyOfTags)}` + JSON.stringify(searchResults));
            setProfiles(searchResults);
            setProfile(searchResults[searchResults.length - 1]);
            let counts = {};
            const existingTags = {};
            tags.forEach(tag => {
                existingTags[tag] = true;
            });
            searchResults.forEach(profile => {
                profile.tags.forEach(tag => {
                    if (!existingTags[tag]) {
                        let count = counts[tag] || 0;
                        count += 1;
                        counts[tag] = count;
                    }
                });
            });
            const betterTags = Object.keys(counts).sort((a, b) => a === b ? 0 : a > b ? 1 : -1);
            setSortedTags(betterTags);
        }).catch(_ => {
            // alert("Something went wrong. Refresh the page");
        });
    }

    const onSidebarSelection = (newTag) => {
        if (newTag.startsWith("#")) {
            setTags((prev) => prev.concat(newTag))
            return performNewSearch(
                [newTag].concat(tags),
                lat,
                lng,
                maxPriceForSubscribers,
                maxPriceForTextMessage,
                maxPriceForMMS,
                maxPriceForRequests,
                maxPriceForVideoChats,
                maxPriceForBookings,
                minApprovalRating,
                mustAcceptRequests,
                mustAcceptBookings,
                mustAcceptVideoChats,
                maxDistance
            );
        } else {
            if (newTag === "Open to Bookings") {
                setMustAcceptBookings(true);
                return performNewSearch(
                    [newTag].concat(tags),
                    lat,
                    lng,
                    maxPriceForSubscribers,
                    maxPriceForTextMessage,
                    maxPriceForMMS,
                    maxPriceForRequests,
                    maxPriceForVideoChats,
                    maxPriceForBookings,
                    minApprovalRating,
                    mustAcceptRequests,
                    true,
                    mustAcceptVideoChats,
                    maxDistance
                );
            } else if (newTag === "Open to Video Chats") {
                setMustAcceptVideoChats(true);
                return performNewSearch(
                    [newTag].concat(tags),
                    lat,
                    lng,
                    maxPriceForSubscribers,
                    maxPriceForTextMessage,
                    maxPriceForMMS,
                    maxPriceForRequests,
                    maxPriceForVideoChats,
                    maxPriceForBookings,
                    minApprovalRating,
                    mustAcceptRequests,
                    mustAcceptBookings,
                    true,
                    maxDistance
                );
            } else if (newTag === "Open to Requests") {
                setMustAcceptRequests(true);
                return performNewSearch(
                    [newTag].concat(tags),
                    lat,
                    lng,
                    maxPriceForSubscribers,
                    maxPriceForTextMessage,
                    maxPriceForMMS,
                    maxPriceForRequests,
                    maxPriceForVideoChats,
                    maxPriceForBookings,
                    minApprovalRating,
                    true,
                    mustAcceptBookings,
                    mustAcceptVideoChats,
                    maxDistance
                );
            }
        }
    }

    const unique = (ids) => {
        const seen = {};
        ids.forEach(el => seen[el.userId] = el);
        return Object.keys(seen).map(el => seen[el]);
    }

    const onSwipe = (dir, idx) => {
        setCurrentIdx(prev => prev + 1);
        switch (dir) {
            case "down":
            case "left":
                setDisliked(prevLiked => unique(prevLiked.concat(idx)));
                // setCurrentIdx(prev => prev + 1);
                return;
            case "right":
            case "up":
                setLiked(prevLiked => unique(prevLiked.concat(profiles[idx])));
                // setCurrentIdx(prev => prev + 1);
                return;
        }
    }

    const handleNewSearchSearched = () => {
        if (!searchVal) return;
        if (searchVal.startsWith("@")) {
            history.push("/p/" + searchVal.split(" ").shift());
            return;
        }
        let join = searchVal.split(" ").map(el => el && (el.charAt(0) === '#' || el.charAt(0) === '@') ? "%23" + el.substring(1) : "%23" + el).join(" ");
        // history.push("/search-redirect/" + `${searchVal.startsWith("#") ? "" : "%23"}` + replaceAll(searchVal, "#", "%23"));
        history.push("/search-redirect/" + join);
    }

    return (
        <IonPage>
            <IonContent>
                <DesktopHeader
                    reset={reset}
                    phone={phone}
                    setPhone={setPhone}
                    disabled={disabled}
                    handleSignUp={handleSignUp}
                    scrollIntoView={null}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    performNewSearch={handleNewSearchSearched}

                />
                <MobileHeader
                    reset={reset}
                    phone={phone}
                    setPhone={setPhone}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    scrollIntoView={null}
                    handleSignUp={handleSignUp}
                    setShowSliders={setShowSliders}
                    performNewSearch={handleNewSearchSearched}
                />
                <section className="mb-5" style={{overflow: "hidden"}}>
                    <div className="container-fluid">
                        <div className="row col-12 small-screens-only " style={{
                            height: "10px",
                            margin: "0 auto"
                        }}>
                            <div className={"col-12"} style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}>
                            </div>
                        </div>
                        <div className="row col-12 mb-4 small-screens-only " style={{
                            height: "fit-content",
                            margin: "0 auto"
                        }}>
                            <div className={"col-12"} style={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "scroll"
                            }}>
                                {
                                    sortedTags
                                        .filter(val => !filterValue || val.toLowerCase().includes(filterValue.toLowerCase()))
                                        .map((category, idx) => {
                                            return (
                                                <div style={{
                                                    width: "100px !important"
                                                }} key={"key-key-key" + idx}>
                                                    <Badge
                                                        handleClick={() => onSidebarSelection(category)}
                                                        text={category}
                                                        color={idx % 3 === 0 ? "purple" : (idx % 3 === 1 ? "blue" : "yellow")}
                                                        fontSize={"7px"}/>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                        {
                            showSliders ?
                                <MobileSearchSlidersWrapper
                                    performNewSearch={() => performNewSearch(
                                        tags,
                                        lat,
                                        lng,
                                        maxPriceForSubscribers,
                                        maxPriceForTextMessage,
                                        maxPriceForMMS,
                                        maxPriceForRequests,
                                        maxPriceForVideoChats,
                                        maxPriceForBookings,
                                        minApprovalRating,
                                        mustAcceptRequests,
                                        mustAcceptBookings,
                                        mustAcceptVideoChats,
                                        maxDistance
                                    )}
                                    lat={lat}
                                    setLat={setLat}
                                    lng={lng}
                                    setLng={setLng}
                                    maxPriceForRequests={maxPriceForRequests}
                                    setMaxPriceForRequests={setMaxPriceForRequests}
                                    maxPriceForVideoChats={maxPriceForVideoChats}
                                    setMaxPriceForVideoChats={setMaxPriceForVideoChats}
                                    maxPriceForBookings={maxPriceForBookings}
                                    setMaxPriceForBookings={setMaxPriceForBookings}
                                    minApprovalRating={minApprovalRating}
                                    setMinApprovalRating={setMinApprovalRating}
                                    maxPriceForSubscribers={maxPriceForSubscribers}
                                    setMaxPriceForSubscribers={setMaxPriceForSubscribers}
                                    maxPriceForTextMessage={maxPriceForTextMessage}
                                    setMaxPriceForTextMessage={setMaxPriceForTextMessage}
                                    maxPriceForMMS={maxPriceForMMS}
                                    setMaxPriceForMMS={setMaxPriceForMMS}
                                    maxDistance={maxDistance}
                                    setMaxDistance={setMaxDistance}
                                />
                                : <></>
                        }
                        {/*<br/>*/}
                        <div
                            className={"row small-screens-only g-4 mb-3 position-relative"} style={{}}>
                            {/*    Spacer*/}
                        </div>
                        <div className="row col-lg-12 col-md-12 col-sm-12 position-relative mb-4" style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                            height: "300px",
                            maxHeight: "410px"
                        }}>
                            <CategoriesSelector
                                sortedTags={sortedTags}
                                filterValue={filterValue}
                                setFilterValue={setFilterValue}
                                onSidebarSelection={onSidebarSelection}
                            />
                            <WebBadges
                                liked={liked}
                                tags={tags}
                                removeCategory={handleRemoveCategory}
                            />
                            <div className={"col-lg-4 col-md-8 col-sm-10 position-relative"}
                                 style={{height: "300px"}}>
                                <TinderStack
                                    profiles={profiles}
                                    topCardRef={topCardRef}
                                    onSwipe={onSwipe}
                                    setLastIdx={setLastIdx}
                                    setProfile={setProfile}
                                />
                            </div>
                            <WebSearchSlidersWrapper
                                performNewSearch={() => performNewSearch(
                                    tags,
                                    lat,
                                    lng,
                                    maxPriceForSubscribers,
                                    maxPriceForTextMessage,
                                    maxPriceForMMS,
                                    maxPriceForRequests,
                                    maxPriceForVideoChats,
                                    maxPriceForBookings,
                                    minApprovalRating,
                                    mustAcceptRequests,
                                    mustAcceptBookings,
                                    mustAcceptVideoChats,
                                    maxDistance
                                )}
                                lat={lat}
                                setLat={setLat}
                                lng={lng}
                                setLng={setLng}
                                maxPriceForRequests={maxPriceForRequests}
                                setMaxPriceForRequests={setMaxPriceForRequests}
                                maxPriceForVideoChats={maxPriceForVideoChats}
                                setMaxPriceForVideoChats={setMaxPriceForVideoChats}
                                maxPriceForBookings={maxPriceForBookings}
                                setMaxPriceForBookings={setMaxPriceForBookings}
                                minApprovalRating={minApprovalRating}
                                setMinApprovalRating={setMinApprovalRating}
                                maxPriceForSubscribers={maxPriceForSubscribers}
                                setMaxPriceForSubscribers={setMaxPriceForSubscribers}
                                maxPriceForTextMessage={maxPriceForTextMessage}
                                setMaxPriceForTextMessage={setMaxPriceForTextMessage}
                                maxPriceForMMS={maxPriceForMMS}
                                setMaxPriceForMMS={setMaxPriceForMMS}
                                maxDistance={maxDistance}
                                setMaxDistance={setMaxDistance}
                            />
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <SwipeButtons topCardRef={topCardRef} setFilterValue={setFilterValue}
                                  profileHandle={profile ? profile.handle : profile}
                                  lastIdx={lastIdx}
                                  profile={profile}
                    />
                    <MobileBadges
                        liked={liked}
                        tags={tags}
                        removeCategory={handleRemoveCategory}
                    />
                    <div className="row col-12 mt-4" style={{background: "none !important", height: "fit-content"}}>
                        <div className={"col-1"}></div>
                        <div className={"col-11"} style={{
                            margin: "0 auto",
                            display: "flex",
                            justifyContent: "space-evenly"
                        }}>
                            {
                                profiles && profiles.length > 0 && currentIdx < profiles.length ?
                                    <ExpandedVideos
                                        userId={profiles[profiles.length - currentIdx - 1].userId}
                                    />
                                    : <></>
                            }
                        </div>
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}

export default TinderStyleSearch;
