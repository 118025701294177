import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import ProfilePosts from "../../components/Profile/ProfilePosts";
import LinkTreeClone from "../../components/LinkTree/LinkTreeClone";
import ProfileAvatar from "../../components/Profile/ProfileAvatar";
import ProfileBio from "../../components/Profile/ProfileBio";
import ProfileBanner from "../../components/Profile/ProfileBanner";
import {getUserIdForHandle} from "../../requests/Handles/HandleRequests";
import {
    IonPage,
    IonContent,
    useIonViewDidEnter,
    IonHeader,
    IonToolbar,
    IonSearchbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonFabButton,
    IonFab, IonSpinner,
} from "@ionic/react";
import {
    search,
    close,
    chatbubbleOutline,
    ellipsisHorizontal,
    notificationsOffOutline,
    notificationsOutline
} from 'ionicons/icons';
import {generateUniqueID, getSearchParamOrDefault, isApp, isLoggedIn, replaceAll} from "../../Utils/Utils";
import HeaderLogo from "../../components/Header/HeaderLogo";
import AdditionalOptionsFab from "../../components/AdditionalOptions/AdditionalOptionsFab";
import {getCreatorPreferencesForUser} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {checkIsFollowing, checkIsSubscribed} from "../../requests/Subscriptions/SubscriptionRequests";

const ProfileRedirect = () => {
    const history = useHistory();
    useIonViewDidEnter(() => {
        const id = replaceAll(replaceAll(window.location.pathname, "@", ""), "/", "");
        setTimeout(() => {
            getUserIdForHandle(id).then(exists => {
                if (exists) {
                    history.replace("/p/" + id);
                    window.location.reload();
                    return;
                }
                history.replace("/chats");
            }).catch(_ => {
                alert(_);
                history.replace("/chats");
            });
        }, 333);
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    );
}

export default ProfileRedirect;
