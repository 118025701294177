import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";


const AuthProtected = ({children}) => {
    const [isSignedIn, setIsSignedIn] = useState();
    const history = useHistory()
    useEffect(() => {
        let tokenJson = localStorage.getItem("session-token");
        const redirect = window.location.pathname + (window.location.search || "");
        if (!tokenJson) {
            setTimeout(() => {
                history.push("/login?redirect=" + redirect);
            }, 400);
        } else {
            let token = JSON.parse(tokenJson);
            if (token.expiration < new Date().getTime()) {
                localStorage.removeItem("session-token");
                setTimeout(() => {
                    history.push("/login?redirect=" + redirect);
                }, 400);
            } else {
                setIsSignedIn(true);
            }
        }
    }, []);

    if (isSignedIn) {
        return <>{children}</>;
    }

    return <></>;
}

export default AuthProtected;
