import React, {useEffect, useState} from 'react';
import {formatCurrency, generateUniqueID, isCurrentUser} from "../../Utils/Utils";
import {useHistory} from "react-router-dom";

const BountyLink = ({bountyDescription, bountyPrice, userId, bountyId}) => {
    const history = useHistory();
    const handleClick = () => {
        if (isCurrentUser(userId)) {
            history.push("/complete-bounty/" + userId + "/" + bountyId);
        } else {
            history.push("/contribute-to-bounty/" + userId + "/" + bountyId);
        }
    }

    return (
        <>
            <div id={'link' + bountyDescription} className="col-md-6 text-align-center w-100" onClick={handleClick}>
                <div
                    className={"d-flex text-align-center flex-row link-button form-control form-control-lg cursor-pointer align-items-center"}
                    style={{borderRadius: "25px", display: "flex", justifyContent: "center"}}
                >
                    <p><span
                        style={{color: "#F47B60"}}>{formatCurrency(bountyPrice)}</span>&nbsp;&nbsp;&nbsp;{bountyDescription}
                    </p>
                </div>
            </div>

        </>
    );
}
export default BountyLink;
