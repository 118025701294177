import React, {useState} from 'react';
import {
    IonContent,
    IonItem
} from '@ionic/react';
import {getFrontendBaseUrl} from "../../../../Utils/Utils";

const JoinVideoChat = ({message, currentUserId, closeModal, isFansTurn = false}) => {
    const [disabled, setDisabled] = useState();
    const {metadata} = message;
    const {
        creatorId,
        fanId,
        id
    } = JSON.parse(metadata.videoChat);
    const joinVideoChat = () => {
        if (disabled) return;
        setDisabled(true);
        window.open(getFrontendBaseUrl() + `/hosted-video-chat/${id}?userId=${currentUserId}`, "_blank");
    }
    if (!isFansTurn && currentUserId === fanId) {
        return (
            <IonContent>
                <IonItem>
                    Video Chat
                </IonItem>
                <IonItem>
                    The Video chat has not started yet. When it does, you will receive a message instructing you to
                    join.
                </IonItem>
            </IonContent>
        );
    }
    return (
        <IonContent>
            <IonItem>
                {isFansTurn ? "Join" : "Start"} the Video Chat
            </IonItem>
            <IonItem>
                When you click the button below, you will be redirected to our website, which is where the video chat
                {isFansTurn ? " is being" : " will be"} hosted.
                In order to join the chat, you will need to enter your password.
                If you get disconnected at any point, you can simply return to this app and click this message once more.
                If you are having trouble with your audio or video, double check your browser settings
                and ensure your settings allow 'Camera' and 'Microphone' access. We highly recommend using either Safari or Chrome.
            </IonItem>
            <br/>
            {
                isFansTurn || currentUserId === creatorId ?
                    <button className="btn login_btn btn-red-gradient width-100"
                            onClick={joinVideoChat}>
                        {isFansTurn ? "Join Video Chat" : "Start Video Chat"}
                    </button>
                    : <></>
            }
        </IonContent>
    );
}

export default JoinVideoChat;
