import React, {useState} from 'react';
import {
    IonContent,
    IonPage,
    IonHeader,
    IonItem,
    IonItemDivider,
    IonLabel,
    useIonViewDidEnter, IonToolbar, IonBackButton, IonButtons
} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {getEarningsBalance} from "../../requests/Earnings/EarningsRequests";
import {formatCurrency} from "../../Utils/Utils";
import {getIsRegistered, getRegistrationLink} from "../../requests/ConnectedAccount/ConnectedAccountRequests";
import {createWithdrawal, getWithdrawalHistory} from "../../requests/Withdrawals/WithdrawalRequests";
import {useHistory} from "react-router-dom";
import WithdrawalRegistrationButton from "../../components/Withdrawals/WithdrawalRegistrationButton";

const WithdrawalsPage = ({}) => {
    const history = useHistory();
    const [earnings, setEarnings] = useState();
    const [disabled, setDisabled] = useState();
    const [pastWithdrawals, setPastWithdrawals] = useState([]);
    const [hasRegistered, setHasRegistered] = useState(false);
    useIonViewDidEnter(() => {
        getEarningsBalance().then(balance => {
            setEarnings(balance);
        }).catch(_ => {

        });
        getIsRegistered().then(isRegistered => {
            setHasRegistered(isRegistered);
            if (isRegistered) {
                getWithdrawalHistory().then(history => {
                    setPastWithdrawals(
                        history.ordered.map(id => {
                            return history.withdrawalSummaries[id];
                        })
                    );
                });
            }
        }).catch(_ => {

        });
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    Current Balance: {formatCurrency(earnings)}
                </IonItem>
                <IonItemDivider>
                    <IonLabel>
                        &nbsp;&nbsp;&nbsp;Withdrawals
                    </IonLabel>
                </IonItemDivider>
                <div>
                    {
                        pastWithdrawals.length === 0 ?
                            <IonItem>
                                No Past Withdrawals
                            </IonItem>
                            :
                            pastWithdrawals.slice(0, 5).map((withdrawal, index) => (
                                <IonItem lines={'full'} key={'withdrawal-key-' + withdrawal.initiatedAt + "" + index}>
                                    <IonLabel detail={true}>
                                        <h3>{formatCurrency(withdrawal.amount)}</h3>
                                        <p>Initiated: {new Date(withdrawal.initiatedAt).toLocaleString()}</p>
                                        <p>Completed: {withdrawal.completedAt === -1 ? "Pending" : new Date(withdrawal.completedAt).toLocaleString()}</p>
                                    </IonLabel>
                                </IonItem>
                            ))
                    }
                </div>
                {
                    hasRegistered ?
                        <>
                            {!earnings ? <></>
                                :
                                <>
                                    <br/>
                                    <button className="btn login_btn btn-red-gradient width-100"
                                            onClick={() => {
                                                if (disabled || !earnings) return;
                                                setDisabled(true);
                                                return createWithdrawal().then(_ => {
                                                    alert("Everything is all set! Please allow 3 - 5 days for the transfer to be completed. Once completed, the funds will be in your Stripe account.")
                                                    setDisabled(false);
                                                    history.push("/chats")
                                                }).catch(_ => {
                                                    alert("Something went wrong. Try again!");
                                                    setDisabled(false);
                                                })
                                            }}>
                                        {disabled ? "Loading..." : `Withdrawal`}
                                    </button>
                                    <br/>
                                </>
                            }
                            <br/>
                            <button className="btn login_btn btn-red-gradient width-100"
                                    onClick={() => {
                                        window.open("https://connect.stripe.com/login", "_blank");
                                    }}>
                                View Your Account In Stripe
                            </button>
                            <br/>
                            <br/>
                        </>
                        : <></>
                }
                {
                    hasRegistered ? <></> :
                        <>
                            <IonItem>
                                We have partnered with Stripe to facilitate the paying out of funds. In order to
                                withdrawal, you will need to register with them. When you click the button below, you
                                will be redirected to their site. Upon completing registration, you will be redirected
                                back to FanNumbers and will be able to initiate a withdrawal.
                            </IonItem>
                            <br/>
                            <WithdrawalRegistrationButton/>
                            <br/>
                        </>
                }
            </IonContent>
        </IonPage>
    );
}

export default WithdrawalsPage;
