import React, {useState} from 'react';
import {
    IonContent,
    IonItem
} from '@ionic/react';
import {markArrival} from "../../../../requests/Meeting/MeetingRequests";
const MarkArrival = ({message, closeModal, currentUserId}) => {
    const {metadata} = message;
    const {id, hostingPreference, fanId, creatorId} = JSON.parse(metadata.booking);
    const isCreatorHosting = hostingPreference === "STAR_HOSTS";
    const [disabled, setDisabled] = useState(false);
    const markArrived = () => {
        if (disabled) return;
        setDisabled(true);
        markArrival(id).then(_ => {
            setDisabled(false);
            closeModal();
        }).catch(_ => {
            alert("Something went wrong. Try again");
            setDisabled(false);
        })
    }
    if (fanId === currentUserId) {
        return (
            <IonContent>
                <IonItem>
                    {`Once the star has marked ${isCreatorHosting ? "your" : "their"} arrival, you will receive a message asking you to confirm their presence.`}
                </IonItem>
            </IonContent>
        );
    }
    return (
        <IonContent>
            <IonItem>
                Mark Arrival
            </IonItem>
            <IonItem>
                {`Once you have marked ${isCreatorHosting ? "their" : "your"} arrival, wait for the fan to confirm your presence. Once the fan has confirmed you are present, you will get paid, the fan will be able to leave a review, and you will be good to proceed with the meeting.`}
            </IonItem>
            <br/>
            <button className="btn login_btn btn-red-gradient width-100"
                    onClick={markArrived}>
                {disabled ? "Loading..." : `Mark Arrival`}
            </button>
        </IonContent>
    );
}

export default MarkArrival;
