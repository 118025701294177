import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonBackButton,
    IonButton, IonButtons,
    IonContent,
    IonHeader,
    IonInput, IonItem, IonList,
    IonPage,
    IonSpinner,
    IonTextarea,
    IonToolbar,
    useIonViewDidEnter
} from "@ionic/react";
import {getCurrentUsersBio, updateBio} from "../../requests/Bio/BioRequests";
import HeaderLogo from "../../components/Header/HeaderLogo";
import ManageStep from "./ManageStepComponent";

const ManageBio = () => {
    const [bio, setBio] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [disabled, setDisabled] = useState();
    const [currentIdx, setCurrentIdx] = useState(0);
    useIonViewDidEnter(() => {
        getCurrentUsersBio().then(biography => {
            if (biography) {
                setBio(biography.bio)
            }
            setLoading(false);
        });
    }, []);

    const steps = [
        {
            key: "updateBio0",
            label: "What do you want your bio to say?",
            label2: "Your profile is searchable by any hashtags you include in your bio",
            placeholder: "Your profile is searchable by any hashtags (#) you include",
            value: bio,
            onChange: (e) => setBio(e.target.value),
            inputType: "textarea",
            inputMode: "text",
            onNextClicked: () => {
                if (disabled) return;
                setDisabled(true);
                return updateBio(bio).then(data => {
                    history.push("/settings");
                    setDisabled(false);
                }).catch(_ => {
                    alert('Something went wrong. We were unable to update your bio.');
                    setDisabled(false);
                });
            },
            onPrevClicked: () => {
                history.push("/settings");
            }
        }
    ];
    const step = steps[currentIdx];
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-text-center"}>
                <IonList>
                    {disabled ? <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/> :
                        <>
                            <IonItem className={"ion-text-center"}>
                                {step.label}
                            </IonItem>
                            {
                                step.inputType === "textarea" ?
                                    <IonItem key={step.key}>
                                        <IonTextarea id={"ion-step-input"}
                                                     className={'mt-10'}
                                                     type={step.inputType}
                                                     value={step.value}
                                                     onIonChange={(e) => setBio(e.target.value)}
                                                     placeholder={step.placeholder}
                                                     autoFocus={true}
                                        />
                                    </IonItem>
                                    : <></>
                            }
                            {
                                step.inputType === "bool" ? <></> :
                                    <IonItem>
                                        <IonButton className={'m-0-auto'}
                                                   onClick={step.onNextClicked}
                                        >Next</IonButton>
                                    </IonItem>
                            }
                            <IonItem>
                                <IonButton className={'m-0-auto'}
                                           onClick={step.onPrevClicked}
                                >
                                    Go Back
                                </IonButton>
                            </IonItem>
                        </>
                    }
                </IonList>
            </IonContent>
        </IonPage>
    );
}

export default ManageBio;
