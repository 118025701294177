import React, {useEffect, useState} from 'react';

const AppStoreAvailability = ({getRequest, imgUrl, link = "#"}) => {
    const [isAvailable, setIsAvailable] = useState(true);
    // useEffect(() => {
    //     getRequest().then(res => {
    //         setIsAvailable(res === 'true' || res === true);
    //     }).catch(_ => {
    //
    //     });
    // }, [])
    if (isAvailable) {
        return (
            <a href={link} className="mb-3 translate-y-3"><img
                src={imgUrl}
                alt="download"
                className="img-fluid"/></a>
        );
    }
    return <></>;
}

export default AppStoreAvailability;
