import React from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {IonPage, IonHeader, IonToolbar, IonContent, useIonViewDidEnter, IonSpinner} from '@ionic/react';
import {useHistory} from "react-router-dom";

const Logout = () => {
    const history = useHistory();
    useIonViewDidEnter(() => {
        localStorage.removeItem("session-token");
        setTimeout(() => {
            history.push("/login");
            window.location.reload();
        }, 300);
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    )
}

export default Logout;
