import React from 'react';
import {IonPage, IonContent, IonHeader, IonToolbar, IonSpinner, useIonViewDidEnter} from '@ionic/react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {getUsersAgeVerification} from "../AgeVerifications/AgeVerificationRequests";
import {checkIfCurrentUserHasCreatorPreferences} from "../CreatorPreferences/CreatorPreferencesRequests";
import {useHistory} from "react-router-dom";

const IdentityRedirect = ({}) => {
    const history = useHistory();
    useIonViewDidEnter(() => {
        setTimeout(() => {
            getUsersAgeVerification().then(res => {
                const {isAdult} = res;
                if (isAdult) {
                    checkIfCurrentUserHasCreatorPreferences().then(hasPrefs => {
                        if (!hasPrefs) {
                            history.replace("/creator-setup");
                        } else {
                            history.replace("/settings");
                        }
                    });
                } else {
                    history.replace("/settings");
                }
            }).catch(_ => {
                alert("Something went wrong " + JSON.stringify(_));
            });
        }, 10000)
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    );
}

export default IdentityRedirect;
