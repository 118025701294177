import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {IonPage, IonHeader, IonToolbar, IonContent, IonButton, IonItem, useIonViewDidEnter} from "@ionic/react";
import {testCryptoTip} from "../../requests/Tips/TipRequests";
import {getHealthCheck, getStripeTestPayment} from "../../requests/Health/HealthCheckRequests";
import {getBaseUrlExternal} from "../../requests/BaseRequests/BaseRequests";

const HealthCheckPage = () => {
    const history = useHistory();
    const [url, setUrl] = useState();
    const [disabled, setDisabled] = useState();
    const [resJson, setResJson] = useState();
    const createLink = () => {
        if (disabled) return;
        setDisabled(true);
        testCryptoTip().then(newUrl => {
            setUrl(newUrl);
            setDisabled(false);
        }).catch(_ => {
            setDisabled(false);
        });
    }
    useIonViewDidEnter(() => {
        getHealthCheck().then(res => {
            setResJson(JSON.stringify(res));
        }).catch(error => {
            setResJson("Error: " + JSON.stringify(error));
        });
    })
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    {url ? <></> :
                        <IonButton fill={'outline'} onClick={createLink}>Create Bitcoin Invoice Url</IonButton>
                    }
                    {
                        url ?
                            <IonButton fill={'outline'} onClick={() => window.open(url, "_blank")}>Open Invoice
                                Url</IonButton>
                            : <></>
                    }
                </IonItem>
                <IonItem>
                    <Link>
                        <IonButton fill={'outline'} onClick={() => {
                            getStripeTestPayment().then(clientSecret => history.push("/pay/" + clientSecret.clientSecret)).catch(_ => {
                                alert("Something went wrong " + JSON.stringify(_));
                            });
                        }}>Stripe</IonButton>
                    </Link>
                </IonItem>
                <IonItem>
                    <Link>
                        <IonButton fill={'outline'} onClick={() => {
                            window.open("https://dashboard.stripe.com/webhooks")
                        }}>Stripe Webhooks</IonButton>
                    </Link>
                </IonItem>
                <IonItem>
                    <IonButton fill={'outline'} onClick={() => {
                        window.open('https://us-east-1.console.aws.amazon.com/sqs/v2/home?region=us-east-1#/queues');
                    }}>SQS</IonButton>
                </IonItem>
                <IonItem>
                    <IonButton fill={'outline'} onClick={() => {
                        window.open('https://dashboard.heroku.com/apps');
                    }}>API</IonButton>
                </IonItem>
                <IonItem>
                    <img style={{maxHeight: '50px'}}
                         src={"https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/s3-bucket-health.jpeg"}
                         alt={"S3 Is Down"}/>
                </IonItem>
                <IonItem>
                    {resJson}
                </IonItem>
                <IonItem>
                    <IonButton
                    fill={'outline'}
                        onClick={() => {
                        if (disabled) return;
                        getHealthCheck().then(res => {
                            setDisabled(false);
                            setResJson(JSON.stringify(res));
                        }).catch(error => {
                            setResJson("Error: " + JSON.stringify(error));
                            setDisabled(false);
                        });
                    }}>{disabled ? "Loading..." : "Refresh"}</IonButton>
                </IonItem>
            </IonContent>
        </IonPage>
    )
}

export default HealthCheckPage;
