import React, {useRef, useState} from 'react';
import {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonIcon,
    IonAvatar, IonFooter, IonModal, useIonViewDidEnter
} from '@ionic/react';
import {ellipsisHorizontal, ellipsisVertical} from "ionicons/icons";
import RightChatMessage from "../../components/Chat/messages/RightChatMessage";
import SubscriberSendMessageButton from "../../components/Subscribers/SubSendMessageButton";
import {getCreatorsMassSends} from "../../requests/MassSends/MassSendRequests";
import CreatorOptionsModal from "../../components/AdditionalOptions/CreatorOptionsModal";
import {uniqueArrWithKeyFunction} from "../../Utils/Utils";
import {setLastReadMessage} from "../../requests/LastRead/LastReadMessageRequests";

const SubscriberManagementPage = ({pusher}) => {
    const [showModal, setShowModal] = useState(false);
    const [pastSends, setPastSends] = useState([]);
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
        }, 100);
    }
    useIonViewDidEnter(() => {
        getCreatorsMassSends().then(data => {
            if (data) {
                setPastSends(data.messages);
                scrollToBottom();
                const subsChannel = pusher.subscribe("presence-cache-subscribers-" + data.userId);
                subsChannel.bind('new-message', msgJson => {
                    let newMsg = JSON.parse(msgJson);
                    setPastSends(prev => uniqueArrWithKeyFunction(prev.concat(newMsg), (el) => el.id));
                    scrollToBottom();
                    return setLastReadMessage().catch(_ => {
                    });
                });
            }
        }).catch(_ => {
            alert("We were unable to retrieve your mass-send history.");
        });
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="secondary">
                        <IonBackButton color={"white"} defaultHref={"/chats"}/>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={() => setShowModal(!showModal)}>
                            <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <div>
                        <IonAvatar slot="" style={{
                            margin: '0 auto',
                            position: 'absolute',
                            top: "5px",
                            left: "calc(50% - 15px)"
                        }}>
                            <img
                                style={{
                                    width: "30px",
                                    height: '30px',
                                    borderRadius: '50%',
                                    objectFit: 'cover'
                                }}
                                src={'assets/id.png'}/>
                        </IonAvatar>

                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="chat_message_wrapper" id={'cm-wrapper'}>
                    <ul>
                        {pastSends.map((message) => (
                            <RightChatMessage message={message}/>
                        ))}
                    </ul>
                </div>
                <div ref={messagesEndRef}/>
            </IonContent>
            <IonFooter>
                <SubscriberSendMessageButton/>
            </IonFooter>
            <IonModal isOpen={showModal}>
                <CreatorOptionsModal
                    closeModal={() => setShowModal(false)}
                    isAllSubscribers={true}
                    conversationId={"ALL"}
                />
            </IonModal>
        </IonPage>
    )
}

export default SubscriberManagementPage;
