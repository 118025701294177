import React, {useState} from 'react';
import {IonContent, IonHeader, IonToolbar, IonPage, IonButtons, IonBackButton, useIonViewDidEnter} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory} from "react-router-dom";
import {createAgeVerification} from "../../requests/AgeVerifications/AgeVerificationRequests";

const AgeVerificationIntro = () => {
    const history = useHistory();
    const [disabled, setDisabled] = useState();
    const [url, setUrl] = useState();
    useIonViewDidEnter(() => {
        createAgeVerification().then(verifyUrl => {
            setUrl(verifyUrl);
        }).catch(_ => {
            setDisabled(false);
        });
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div>
                    <p className={"m-0-auto width-80 ion-text-center mt-10"}>
                        In order to keep our platform safe for our users, we do not allow individuals under the age of
                        18 to register as stars.
                        <div className={'mt-10'}>To help us enforce this, we have partnered with Stripe to verify the
                            age of users hoping to register. Click 'Verify My Age' to confirm you are 18 or older. You
                            will need a form of identification.</div>
                        <div className={'mt-10'}>If you have a following but are under 18, you can still earn money on
                            the platform through referrals.
                        </div>
                    </p>
                    <br/>
                    {!url ? <></>
                        :
                        <div className={"d-flex ion-justify-content-center"}>
                            <button className="btn login_btn btn-red-gradient" onClick={() => {
                                window.open(url, "_blank");
                            }}>Verify My Age
                            </button>
                        </div>
                    }
                    <br/>
                    <div className={"d-flex ion-justify-content-center"}>
                        <button className="btn login_btn btn-red-gradient" onClick={() => {
                            history.push("/age-verification-code")
                        }}>Use Code
                        </button>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default AgeVerificationIntro;
