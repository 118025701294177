import React from 'react';
import LinkTreeLinkIcon from "./LinkTreeLinkIcon";

const LinkTreeLink = ({link, isEditMode = true, index, currentIndex, setCurrentIndex, remove, setLink}) => {
    return (
        <div className="col-md-6 text-align-center w-100">
            {
                !isEditMode || currentIndex !== index ? <></> : (
                    <div className="position-relative mt-5">
                        <input type="text" value={link.linkUrl} className="form-control form-control-lg"
                               placeholder="URL"
                               onChange={(e) => {
                                   setLink({linkText: link.linkText, linkUrl: e.target.value, linkImg: link.linkImg})
                               }}
                        />
                        <i className="fa fa-times-circle" onClick={e => {
                            e.preventDefault();
                            remove();
                        }}></i>
                    </div>
                )
            }
            <div className={`position-relative d-flex flex-row floating-hover  ${!isEditMode || currentIndex !== index ? "" : "mb-5"}`}
                 onClick={() => {
                     if (isEditMode) {
                         setCurrentIndex(index)
                     } else {
                         window.open(link.linkUrl, "_system")
                     }
                 }}>

                {
                    isEditMode ?
                        <>
                            <input type="text" className="cursor-pointer form-control form-control-lg"
                                   value={link.linkText} placeholder="Label for the link above (optional)"
                                   disabled={!isEditMode}
                                   onChange={(e) => {
                                       setLink({linkText: e.target.value, linkUrl: link.linkUrl, linkImg: link.linkImg})
                                   }}
                            />
                            <i className="fa fa-times-circle"/>
                        </>
                        :
                        <div
                            className={"d-flex text-align-center flex-row link-button form-control form-control-lg cursor-pointer align-items-center"}
                            style={{borderRadius: "25px", display: "flex", justifyContent: "center"}}
                        >
                            <LinkTreeLinkIcon linkUrl={link.linkUrl}/>
                            <span className={'profile-link-text'}>{link.linkText}</span>
                        </div>
                }


            </div>
        </div>
    );
}

export default LinkTreeLink;
