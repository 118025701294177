import React from 'react';
import {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar, IonSpinner, useIonViewDidEnter,
} from '@ionic/react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {getSearchParamOrDefault} from "../../Utils/Utils";
import {getMagicLink} from "../../requests/MagicLink/MagicLinkRequests";

const AppRedirectPage = ({}) => {
    const {id} = useParams();
    const history = useHistory();
    useIonViewDidEnter(() => {
        const redirectUrl = getSearchParamOrDefault("redirect", "/chats");
        getMagicLink(id).then(sessionToken => {
            const expiration = new Date().getTime() + (1000 * 60 * 60 * 24);
            localStorage.setItem("session-token", JSON.stringify({token: sessionToken, expiration}));
            setTimeout(() => {
                history.replace(redirectUrl);
            }, 300);
        }).catch(_ => {
            history.replace(redirectUrl);
        });
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    )
}

export default AppRedirectPage;
