import React, {useState} from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {
    IonPage,
    IonHeader,
    IonContent,
    IonDatetimeButton,
    IonDatetime,
    IonModal,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonLabel,
    useIonViewDidEnter, IonInput, IonBackButton, IonButtons, IonToolbar
} from "@ionic/react";
import {useHistory, useParams} from "react-router-dom";
import {checkIfHasConflict, requestBooking, requestVideoChat} from "../../requests/Meeting/MeetingRequests";
import {getCreatorPreferencesForUser} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {formatCurrency} from "../../Utils/Utils";
import {getSalesTaxRate} from "../../requests/SalesTax/SalesTaxRate";

const ScheduleVideoChat = ({}) => {
    const {id} = useParams();
    const [vcPrice, setVcPrice] = useState();
    const [vcDuration, setVcDuration] = useState();
    const [disabled, setDisabled] = useState();
    const [startTime, setStartTime] = useState();
    const [hours, setHours] = useState();
    const [min, setMin] = useState();
    const [expiration, setExpiration] = useState();
    const [zipCode, setZipCode] = useState(19711);
    const [rate, setRate] = useState(1);
    const history = useHistory();
    useIonViewDidEnter(() => {
        getCreatorPreferencesForUser(id).then(prefs => {
            setVcPrice(prefs.videoChatPricePerMinute);
            setVcDuration(prefs.minimumVideoChatDuration);
        }).catch(_ => {
            console.log("ERROR " + JSON.stringify(_));
        });
    });
    const bookMeeting = () => {
        if (disabled) return;
        setDisabled(true);
        const thirtyMinFromNow = new Date().getTime() + 30 * 60 * 1000;
        if (!startTime || startTime < thirtyMinFromNow) {
            alert("You must specify a start time");
            setDisabled(false);
            return;
        }
        if (!hours && !min) {
            alert("You must specify a duration in either hours or minutes or both");
            setDisabled(false);
            return;
        }
        if (!expiration || expiration < thirtyMinFromNow) {
            alert("You must specify when you need an answer by. You must allow at least thirty minutes from now");
            setDisabled(false);
            return;
        }
        const duration = (hours ? hours * 60 : 0) + (min ? min : 0);
        if (duration < vcDuration) {
            alert(`Your video chat must be at least ${vcDuration} minutes`);
            setDisabled(false);
        }
        return checkIfHasConflict(id, startTime, duration).then(hasConflict => {
            if (hasConflict) {
                alert("Sorry. But there's a conflict!");
                setDisabled(false);
                return;
            }
            return requestVideoChat(id, startTime, duration, expiration).then(paymentSecret => {
                history.push("/pay/" + paymentSecret);
                setDisabled(false);
            });
        }).catch(_ => {
            alert("Something went wrong. Try again or refresh the page/app");
            console.log("ERROR " + JSON.stringify(_));
            setDisabled(false);
        });
    }
    const duration = (hours ? hours * 60 : 0) + (min ? min : 0);
    const price = !duration ? "" : formatCurrency(vcPrice * duration * rate);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={'width-100'}>
                <IonItem>
                    <IonLabel>Schedule a Video Chat</IonLabel>
                </IonItem>
                {vcPrice && vcDuration ?
                    <IonItem>
                        Rate: {formatCurrency(vcPrice)}/minute. Minimum of {vcDuration} minutes.
                    </IonItem>
                    : <></>
                }
                <IonItem className={'width-100'}>
                    <IonLabel>When</IonLabel>
                    <IonDatetimeButton datetime={'datetime'}/>
                </IonItem>
                <IonItem>
                    <IonLabel>How Long:</IonLabel>
                </IonItem>
                <IonItem className={'width-100'}>
                    <IonSelect className={'width-100'}
                               interface="popover"
                               placeholder="# of Hours?"
                               onIonChange={(e) => setHours(e.detail.value)}
                    >
                        {Array(169).fill(0).map((_, idx) => {
                            return <IonSelectOption className={'width-100'}
                                                    value={idx}>{idx} {idx > 1 ? "hrs" : "hr"}</IonSelectOption>
                        })}
                    </IonSelect>
                </IonItem>
                <IonItem className={'width-100'}>
                    <IonSelect className={'width-100'} interface="popover" placeholder="# of minutes?"
                               onIonChange={(e) => setMin(e.detail.value)}
                    >
                        {
                            Array(60).fill(0).map((_, idx) => {
                                return <IonSelectOption className={'width-100'} value={idx}>
                                    {idx} {idx > 1 ? "mins" : "min"}
                                </IonSelectOption>
                            })
                        }
                    </IonSelect>
                </IonItem>
                <IonItem>
                    When do you need an answer by
                </IonItem>
                <IonItem>
                    <IonDatetimeButton datetime={'datetime2'}/>
                </IonItem>
                <IonItem>
                    Zip Code (for sales tax):
                </IonItem>
                <IonItem>
                    <IonInput type={'text'}
                              value={zipCode}
                              onIonChange={(e) => {
                                  e.preventDefault();
                                  const {value} = e.target;
                                  setZipCode(value);
                                  if (value.length === 5 && !isNaN(value)) {
                                      getSalesTaxRate(value).then(taxRate => {
                                          if (taxRate) {
                                              setRate(1 + taxRate.rate);
                                          }
                                      }).catch(_ => {
                                          console.log("ERROR " + JSON.stringify(_));
                                      });
                                  }
                              }}
                              placeholder={"Enter your zip code"}
                    />
                </IonItem>
                <IonItem className={'width-100'}>
                    By clicking the button below, you acknowledge that you are only paying for the ability to video chat
                    with the star. If the star does not want to sing or do some other activity, that is up to the star.
                    You are paying solely for the ability to video chat with the star. If you video chat with the star
                    and have a negative experience, your only recourse is leaving a negative review. By clicking the
                    button below, you are accepting these terms. If you do not wish to accept these terms, do not click
                    the button below.
                </IonItem>
                <br/>
                <button className="btn login_btn btn-red-gradient width-100"
                        onClick={bookMeeting}>
                    Deposit {price ? price : ""} & Send Request
                </button>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        id="datetime"
                        className={'color-white'}
                        onIonChange={e => {
                            setStartTime(new Date(e.target.value).getTime())
                        }}
                    />
                </IonModal>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime
                        id="datetime2"
                        className={'color-white'}
                        onIonChange={e => {
                            setExpiration(new Date(e.target.value).getTime())
                        }}
                    />
                </IonModal>
            </IonContent>
        </IonPage>
    );
}

export default ScheduleVideoChat;
