import React, {useState} from 'react';

const PostContent = ({post}) => {
    const [isVideo, setIsVideo] = useState(false);
    const [hovering, setHovering] = useState();
    const onClick = () => window.open(post.img, "_blank");
    return (
            !isVideo ?
            <img onClick={onClick} src={post.img} alt=""
                 onError={({currentTarget}) => {
                     currentTarget.onerror = null;
                     setIsVideo(true);
                 }}
                 className="w-100"/>
            :
            <video id={"post" + post} onMouseEnter={() => {
                document.getElementById("post" + post).play();
                setHovering(true);
            }} autoPlay={hovering} src={post.img} className={"w-100"} controls={hovering}/>
    );
}

export default PostContent;
