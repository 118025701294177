import React, {useEffect, useState} from 'react';
import {formatCurrency} from "../../../../Utils/Utils";

const WebSliders = ({
                        waitAndSearch,
                        lat,
                        setLat,
                        lng,
                        setLng,
                        maxPriceForRequests,
                        setMaxPriceForRequests,
                        maxPriceForVideoChats,
                        setMaxPriceForVideoChats,
                        maxPriceForBookings,
                        setMaxPriceForBookings,
                        minApprovalRating,
                        setMinApprovalRating,
                        maxPriceForSubscribers,
                        setMaxPriceForSubscribers,
                        maxPriceForTextMessage,
                        setMaxPriceForTextMessage,
                        maxPriceForMMS,
                        setMaxPriceForMMS,
                        maxDistance,
                        setMaxDistance
                    }) => {
    const [lastChanged, setLastChanged] = useState();

    const displayText = (str) => {
        setLastChanged(str);
    }

    const sliders = [
        {
            name: "Price Per Sub",
            min: 500,
            max: 50000,
            value: maxPriceForSubscribers,
            step: 25,
            onChange: (({target}) => {
                setMaxPriceForSubscribers(target.value)
                setLastChanged("Price Per Sub")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "Subscription: < " + formatCurrency(val) + "/mo" : "Any Subscription Price"
            ))
        },
        {
            name: "Price Per SMS",
            min: 100,
            max: 50000,
            value: maxPriceForTextMessage,
            step: 1,
            onChange: (({target}) => {
                setMaxPriceForTextMessage(target.value)
                setLastChanged("Price Per SMS")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "SMS: < " + formatCurrency(val) + "/msg" : "Any SMS Price"
            ))
        },
        {
            name: "Price Per MMS",
            min: 100,
            max: 100000,
            value: maxPriceForMMS,
            step: 25,
            onChange: (({target}) => {
                setMaxPriceForMMS(target.value)
                setLastChanged("Price Per MMS")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "MMS: < " + formatCurrency(val) + "/msg" : "Any MMS Price"
            ))
        },
        {
            name: "Price Per Minute (Video Chat)",
            min: 100,
            max: 30000,
            value: maxPriceForVideoChats,
            step: 25,
            onChange: (({target}) => {
                setMaxPriceForVideoChats(target.value)
                setLastChanged("Price Per Minute (Video Chat)")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "Video Chat: < " + formatCurrency(val) + "/min" : "Any Video Chat Price"
            ))
        },
        {
            name: "Price Per Minute (Booking)",
            min: 2000,
            max: 100000000,
            value: maxPriceForBookings,
            step: 1,
            onChange: (({target}) => {
                setMaxPriceForBookings(target.value);
                setLastChanged("Price Per Minute (Booking)")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "Booking: < " + formatCurrency(val) + "/hr" : "Any Booking Price"
            ))
        },
        {
            name: "Max Price Per Request",
            min: 500,
            max: 99999999,
            value: maxPriceForRequests,
            step: 25,
            onChange: (({target}) => {
                setMaxPriceForRequests(target.value);
                setLastChanged("Max Price Per Request")
                waitAndSearch();
            }),
            formattedValue: ((val) => (
                val ? "< " + formatCurrency(val) + "/request" : "Any Request Price"
            ))
        },
        {
            name: "Rating",
            min: 0,
            max: 1,
            value: minApprovalRating,
            step: 0.01,
            onChange: (e) => {
                e.preventDefault();
                const newRating = e.target.value;
                if (newRating >= .5) {
                    setMinApprovalRating(newRating);
                    waitAndSearch();
                }
                setLastChanged("Rating")
            },
            formattedValue: ((val) => (
                val ? "" + (val * 100).toFixed(0) + "% would hire again" : "Any Rating"
            ))
        },
        {
            name: "Distance",
            min: 1,
            max: 100,
            value: maxDistance,
            step: 1,
            onChange: (e) => {
                setMaxDistance(e.target.value);
                if (!lat || !lng) {
                    return navigator.geolocation.getCurrentPosition(({coords}) => {
                        const latitude = coords.latitude;
                        const longitude = coords.longitude;
                        setLat(latitude);
                        setLng(longitude);
                        waitAndSearch();
                    }, () => {
                        alert("Something went wrong, we were unable to find your latitude or longitude. Please check your browser settings and refresh the page.");
                    }, {enableHighAccuracy: true});
                } else {
                    waitAndSearch();
                }
            },
            formattedValue: ((val) => (
                val ? maxDistance + "mi" : "Any Distance"
            ))
        }
    ];
    const [idx, setIdx] = useState(0);
    const [showText, setShowText] = useState(true);
    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            if (idx < 7) {
                setIdx(prevIdx => prevIdx + 1)
            } else {
                setShowText(false);
                clearInterval(interval);
            }
        }, 300);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, []);


    return (
        <div className="col-lg-3 col-md-1 col-sm-1 small-screens-hidden web-only mt-5"
             style={{
                 height: "100%",
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "center"
             }}>
            {
                sliders.map((slide, currentIdx) => (
                    <div key={'slide-name-' + slide.name}
                         onMouseEnter={() => displayText(slide.name)}
                         onMouseLeave={() => displayText(undefined)}>
                        {
                            (showText && idx === currentIdx) || slide.name === lastChanged ?
                                <>
                                    <div className={"range-text w-100 center-text text-center"}>
                                        {slide.formattedValue(slide.value)}
                                    </div>
                                    <br/>
                                </>
                                :
                                <></>
                        }
                        <input
                            className="my-range w-80 mt-3 mb-3 z-index-10000"
                            type="range"
                            min={slide.min}
                            max={slide.max}
                            value={slide.value}
                            onChange={slide.onChange}
                            step={slide.step}
                            style={{
                                background: `linear-gradient(to right, #F47B60 0%, #F47B60 ${
                                    (slide.value / slide.max) * 100
                                }%, #DEE2E6 ${
                                    100 - ((slide.value / slide.max) * 100)
                                }%, #DEE2E6 100%`
                            }}
                        >
                        </input>
                        <br/>
                    </div>
                ))
            }
        </div>
    );
}

export default WebSliders;
