import Badge from "../Badge";
import {getProfilePicturesBaseUrl, replaceAll} from "../../../../Utils/Utils";
import {useHistory} from "react-router-dom";

const WebBadges = ({tags, removeCategory, liked = []}) => {
    const history = useHistory();
    return (
        <div className="col-lg-3 col-md-1 col-sm-1 small-screens-hidden web-only"
             style={{height: "100%", overflowY: "scroll"}}>
            {
                tags.map((el, idx) => {
                    return <Badge
                        key={"badge-key-idx-" + idx}
                        handleClick={() => removeCategory(el)}
                        text={replaceAll(el, "%23", "#")}
                        color={
                            idx % 3 === 1 ? "blue" : (idx % 3 === 2 ? "purple" : "yellow")
                        }
                    />
                })
            }
            <div className={"row"} style={{marginLeft: "20px d-flex flex-wrap"}}>
                {liked.map(likedProfile => {
                    return <img
                        key={(likedProfile ? likedProfile.userId : "") + "key"}
                        onClick={() => history.push("/p/" + likedProfile.handle)}
                        className={"transform-on-hover-5 cursor-pointer"}
                        style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: '50%',
                            objectFit: "cover",
                            padding: '0',
                            margin: "3px"
                        }}
                        src={getProfilePicturesBaseUrl() + likedProfile.userId}/>;
                })}
            </div>
        </div>
    );
}

export default WebBadges;
