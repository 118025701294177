import React from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {IonPage, IonHeader, IonToolbar, IonContent, IonSpinner, useIonViewDidEnter} from '@ionic/react';
import {useHistory} from "react-router-dom";
import {clearLocation, setLocation} from "../../requests/Location/LocationRequests";
const ClearLocationPage = ({}) => {
    const history = useHistory();
    useIonViewDidEnter(() => {
        clearLocation().then(_ => {
            setTimeout(() => {
                alert("Your location has been cleared!");
                history.replace("/settings");
            }, 300);
        }).catch(_ => {
            alert("Something went wrong, we were unable to clear your location. Try again");
            history.goBack();
        });
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/>
            </IonContent>
        </IonPage>
    )
}
export default ClearLocationPage;
