import {getRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const getCurrentUsersBio = () => {
    return getRequest(getBaseUrl() + "/profile-bios/current-users").then(defaultResponseHandler);
}

export const updateBio = (bio) => {
    return postRequest(getBaseUrl() + `/profile-bios`, {bio}).then(defaultResponseHandler);
}
