import React from 'react';
import {IonPage, IonHeader, IonToolbar, IonContent, useIonViewDidEnter} from "@ionic/react";
import HeaderLogo from "../../components/Header/HeaderLogo";
import {createChat} from "../../requests/Conversation/ConversationRequests";
import {useHistory, useParams} from "react-router-dom";
import {getSearchParamOrDefault} from "../../Utils/Utils";
import {checkIfCurrentUserHasHandle} from "../../requests/Handles/HandleRequests";

const CreateChatPage = () => {
    const {id} = useParams();
    const history = useHistory();

    useIonViewDidEnter(() => {
        checkIfCurrentUserHasHandle().then(handle => {
            const hasHandle = !!handle;
            if (hasHandle) {
                createChat(id).then(convoId => {
                    const redirect = getSearchParamOrDefault("redirect", "/chat/" + convoId)
                    history.replace(redirect);
                }).catch(_ => {
                    alert("Something went wrong");
                    history.goBack();
                });
            } else {
                history.replace("/ensure-handle?redirect=" + window.location.pathname + window.location.search);
            }
        }).catch(_ => {
            alert("Something went wrong. Try refreshing the page or restarting the app");
            console.log("ERROR " + JSON.stringify(_));
        });

    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <p className={"ion-text-center mt-3"}>Loading...</p>
            </IonContent>
        </IonPage>
    )
}

export default CreateChatPage;
