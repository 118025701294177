import React, {useEffect, useState} from 'react';
import {IonContent, IonItem, IonInput, IonLabel, useIonViewDidEnter} from '@ionic/react';
import {getVideoForSale, purchaseVideo} from "../../../../requests/VideosForSale/VideosForSaleRequests";
import {formatCurrency} from "../../../../Utils/Utils";
import {getSalesTaxRate} from "../../../../requests/SalesTax/SalesTaxRate";
import {useHistory} from "react-router-dom";

const VideoForSaleDetails = ({message, closeModal, currentUserId}) => {
    const [video, setVideo] = useState();
    const [zipCode, setZipCode] = useState();
    const [disabled, setDisabled] = useState();
    const [total, setTotal] = useState();
    const history = useHistory();
    useEffect(() => {
        getVideoForSale(message.metadata.videoId).then(video => {
            setVideo(video)
        }).catch(_ => {
            closeModal();
        });
    }, [message]);
    const handleClick = () => {
        if (!zipCode || zipCode.length < 5 || isNaN(zipCode) || disabled) return;
        setDisabled(true);
        return purchaseVideo(message.metadata.videoId, zipCode).then(paymentSecret => {
            history.push("/pay/" + paymentSecret);
            closeModal();
            setDisabled(false);
            setTotal(null);
            setVideo(null);
        }).catch(_ => {
            alert("Something went wrong. Keep in mind, you cannot purchase your own videos");
            setDisabled(false);
            setTotal(null);
            setVideo(null);
            closeModal();
        });
    }
    if (!video) return <></>;
    return (
        <IonContent>
            <IonItem>
                {video.messageBody}
            </IonItem>
            <IonItem>
                Price: {formatCurrency(video.salePriceInCents)}
            </IonItem>
            <IonItem>
                <IonLabel>Zip Code:&nbsp;&nbsp;&nbsp;</IonLabel>
                <IonInput placeholder={"Zip Code"} value={zipCode}
                          onIonChange={e => {
                              e.preventDefault();
                              const {value} = e.target;
                              if (value.length > 5) return;
                              setZipCode(value);
                              if (value.length === 5 && !isNaN(value)) {
                                  getSalesTaxRate(value).then(rate => {
                                      if (rate) {
                                          setTotal((1 + rate.rate) * video.salePriceInCents);
                                      }
                                  }).catch(_ => {

                                  });
                              }
                          }}
                />
            </IonItem>
            <br/>
            <button className="btn login_btn btn-red-gradient width-100"
                    onClick={handleClick}
            >
                {disabled ? "Loading..." : total ? "Purchase for " + formatCurrency(total) : "Enter a zip code"}
            </button>
        </IonContent>
    )
}

export default VideoForSaleDetails;
