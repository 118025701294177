import React from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import {getCreditCardPublicKey, getFrontendBaseUrl} from "../../Utils/Utils";
import CheckOutForm from "./CheckoutForm";

const stripePromise = loadStripe(getCreditCardPublicKey());

const SinglePaymentCardInput = ({clientSecret, refresh, amount, reset, buttonText = "Pay"}) => {

    const options = {
        clientSecret,
        appearance: {
            variables: {
                colorText: '#a0a0a0',
            }
        }
    };
    const returnUrl = getFrontendBaseUrl() + "/payment-final-steps";
    return clientSecret ?
        (
            <div>
                <Elements stripe={stripePromise} options={options}>
                    <CheckOutForm message={buttonText} returnUrl={returnUrl} postSubmit={refresh} amount={amount} reset={reset}/>
                </Elements>
            </div>
        )
        :
        <div style={{color: "white"}}>Loading</div>
        ;
}

SinglePaymentCardInput.defaultProps = {
    clientSecret: "",
    refresh: (() => {}),
    amount: 0
}

export default SinglePaymentCardInput;
