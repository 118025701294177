import React, {useState} from 'react';
import {
    IonPage,
    IonToolbar,
    IonHeader,
    IonContent,
    IonBackButton,
    IonButtons,
    IonList,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonIcon,
    IonItem,
    IonLabel,
    IonReorderGroup,
    IonReorder, useIonViewDidEnter, IonFabButton, IonFab, useIonViewDidLeave
} from "@ionic/react";
import {add, trash} from 'ionicons/icons';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {addLinkToLinkTree, getUsersLinkTree} from "../../requests/LinkTree/LinkTreeRequests";
import {isApp} from "../../Utils/Utils";
import ManageStep from "../SettingsManagement/ManageStepComponent";

const LinkCreationPage = ({}) => {
    const {id} = useParams();
    const history = useHistory();
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [currentIdx, setCurrentIdx] = useState(0);
    const [disabled, setDisabled] = useState();
    const steps = [
        {
            key: 'link-setup-0',
            label: "Enter the url",
            placeholder: "e.g. https://youtube.com",
            value: url,
            onChange: (e) => setUrl(e.target.value),
            inputType: "text",
            inputMode: 'url',
            onNextClicked: () => {
                if (!url) return;
                if (!url.startsWith("http")) {
                    alert("Your url doesn't look right");
                    return;
                }
                setCurrentIdx(prev => prev + 1);
            },
            onPrevClicked: () => {
                history.goBack();
            }
        },
        {
            key: 'link-setup-1',
            label: "What do you want the link's label to say",
            placeholder: "EX. My Youtube Channel",
            value: title,
            onChange: (e) => setTitle(e.target.value),
            inputType: "text",
            inputMode: 'text',
            onNextClicked: () => {
                if (disabled || !title || !title.trim()) return;
                setDisabled(true);
                addLinkToLinkTree(url, title).then(r => {
                    history.replace("/link-management/" + id);
                    setDisabled(false);
                    setCurrentIdx(0);
                    setTitle('');
                    setUrl('');
                }).catch(_ => {
                    alert("Something went wrong. Try again");
                    setDisabled(false);
                })
            },
            onPrevClicked: () => {
                setCurrentIdx(0);
            }
        }
    ];
    useIonViewDidEnter(() => {
        setCurrentIdx(0);
        setTitle('');
        setUrl('');
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/settings"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ManageStep step={steps[currentIdx]} disabled={disabled}/>
            </IonContent>
        </IonPage>
    );
}

export default LinkCreationPage;
