import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar, isPlatform, useIonViewDidEnter
} from '@ionic/react';
import './Settings.css';
import HeaderLogo from "../../components/Header/HeaderLogo";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
import {getUsersAgeVerification} from "../../requests/AgeVerifications/AgeVerificationRequests";
import WebPageRedirectButton from "../WebPageLinks/WebPageLinksRedirect";
import {checkIfCurrentUserHasCreatorPreferences} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {createMagicLink} from "../../requests/MagicLink/MagicLinkRequests";
import {isApp} from "../../Utils/Utils";
import ProfilePageButtons from "../../components/Settings/ProfilePageButtons";
import PricingButtons from "../../components/Settings/PricingButtons";

const Settings = () => {
    const handleSaveClicked = () => {
    };
    const [currentUserId, setCurrentUserId] = useState();
    const [isCreator, setIsCreator] = useState();
    const [magicLink, setMagicLink] = useState();
    const buttons = [
        // {label: "Notifications", route: "/notification-preferences"},
        {label: "Set Location", route: "/set-your-location", isMagicLink: true},
        {label: "Clear Location", route: "/clear-location", isMagicLink: true},
        // {label: "Manage Webpage URL (Domain)", route: "/manage-domains"},

        // {label: "Manage Background Photo", route: "/manage-banner-photo"},
    ];
    const [showLocationButtons, setShowLocationButtons] = useState(false);
    const history = useHistory();
    useIonViewDidEnter(() => {
        const isMobilePlatform = isApp();
        const setupPage = () => getUsersAgeVerification().then(res => {
            const {userId, isAdult} = res;
            setCurrentUserId(userId);
            setIsCreator(isAdult);
            if (isAdult) {
                checkIfCurrentUserHasCreatorPreferences().then(hasPrefs => {
                    if (!hasPrefs) {
                        const routeToNavigateTo = "/creator-setup";
                        if (isMobilePlatform) {
                            window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + routeToNavigateTo, "_blank")
                        } else {
                            history.push(routeToNavigateTo);
                        }
                    }
                });
            }
        }).catch(_ => {
            alert("Something went wrong " + JSON.stringify(_));
        });
        if (isMobilePlatform) {
            createMagicLink().then(magicLinkId => {
                setMagicLink(magicLinkId);
                setupPage();
            }).catch(_ => {
                setupPage();
            });
        } else {
            setupPage();
        }
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: 'center',
                    alignItems: "center"
                }}>
                    {
                        currentUserId ?
                            <ProfilePhoto currentUserId={currentUserId}/>
                            : <></>
                    }
                    <div className="profile_form ion-text-center">
                        {
                            isCreator ?
                                <>
                                    <PricingButtons magicLink={magicLink}/>
                                    <ProfilePageButtons currentUserId={currentUserId} magicLink={magicLink}/>
                                    {
                                        showLocationButtons ? <></> :
                                            <div className={'mt-10'} key={"btn-location-toggle"}>
                                                <button className="btn login_btn btn-red-gradient color-white"
                                                        onClick={() => {
                                                            setShowLocationButtons(true);
                                                        }}>Location
                                                </button>
                                            </div>
                                    }
                                    {
                                        (showLocationButtons ? buttons : []).map(btn => (
                                            btn.isMagicLink ?
                                                <div className={'mt-10'} key={"btn-" + btn.route}>
                                                    <button className="btn login_btn btn-red-gradient color-white"
                                                            onClick={() => {
                                                                if (isApp()) {
                                                                    window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + `${btn.route}`, "_blank")
                                                                } else {
                                                                    history.push(btn.route);
                                                                }
                                                            }}>{btn.label}
                                                    </button>
                                                </div>
                                                :
                                                <div className={'mt-10'} key={"btn-" + btn.route}>
                                                    <button className="btn login_btn btn-red-gradient color-white"
                                                            onClick={() => history.push(btn.route)}>{btn.label}
                                                    </button>
                                                </div>
                                        ))
                                    }
                                </>
                                :
                                <>
                                    <div className={'mt-10'}>
                                        <button
                                            onClick={() => {
                                                const routeToNavigateTo = "/age-verification-intro";
                                                if (isApp()) {
                                                    window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + routeToNavigateTo, "_blank");
                                                } else {
                                                    history.push(routeToNavigateTo);
                                                }
                                            }}
                                            className="btn login_btn btn-red-gradient">Register as a Star
                                        </button>
                                    </div>
                                </>
                        }
                        <div className={'mt-10'} key={"btn-" + "/withdrawals"}>
                            <button className="btn login_btn btn-red-gradient color-white"
                                    onClick={() => history.push("/withdrawals")}>{"Withdrawals"}
                            </button>
                        </div>
                        <div className={'mt-10'} key={"btn-" + "/withdrawals"}>
                            <button className="btn login_btn btn-red-gradient color-white"
                                    onClick={() => history.push("/notification-preferences")}>{"Notifications"}
                            </button>
                        </div>
                        <div className={'mt-10'}>
                            <button className="btn login_btn btn-red-gradient color-white"
                                    onClick={() => {
                                        history.push("/referral-links");
                                    }}>
                                Refer Stars & Get Paid
                            </button>
                        </div>
                        <div className={'mt-10'}>
                            <button className="btn login_btn btn-red-gradient color-white"
                                    onClick={() => history.push("/logout")}
                            >
                                Log Out
                            </button>
                        </div>
                        <br/>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Settings;
