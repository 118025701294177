import React, {useEffect, useState} from 'react';
import PostContent from "./ProfilePostContent";
import {getPostsByUser} from "../../requests/Posts/PostRequests";
import {formatDuration, getProfilePicturesBaseUrl} from "../../Utils/Utils";

const ProfilePosts = ({userId, posts}) => {
    const timeNow = new Date().valueOf();
    return (
        <div className="all-card-box mb-4 p-md-4 p-3">
            <div className="row g-4">
                {posts.map(post => (
                    <div className="col-lg-4 col-sm-6">
                        <div className="card-video darkMode-elable position-relative">
                            <>
                                <div className="img border-radius-20">
                                    <div className="thumbnail position-relative">
                                        <PostContent post={post}/>
                                        <div className="overlay"></div>
                                        <span
                                            className="video-length">{formatDuration((timeNow - post.createdAt) / 1000) + " ago"}</span>
                                    </div>
                                </div>
                            </>
                            <div className="text d-flex">
                                <div className="flex-shrink-0">
                                    <img src={getProfilePicturesBaseUrl() + userId} alt="avatar"
                                         onError={({currentTarget}) => {
                                             currentTarget.onerror = null;
                                             currentTarget.src = "assets/id.png";
                                         }}
                                         className="img-fluid user-avatar shadow-sm"/>
                                </div>
                                <div className="flex-grow-1">
                                    <h3 className="title">{(post.message || "").substring(0, 160)}{post.message && post.message.length > 160 ? "..." : ""}</h3>
                                    <p className="creator-name">{""}</p>
                                </div>
                                <div className="dropdown pt-1">
                                    <button className="btn" type="button" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a>
                                        </li>
                                        <li><a className="dropdown-item" href="#">Something else
                                            here</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProfilePosts;
