import {defaultResponseHandler, getBaseUrl, postRequest} from "../BaseRequests/BaseRequests";
import {replaceAll} from "../../Utils/Utils";
import {searchByHandle} from "../Search/SearchRequests";

export const searchUsers = (
    tags = [],
    lat,
    lng,
    maxSubscriptionPrice,
    maxSMSPrice,
    maxMMSPrice,
    maxPriceForRequests,
    maxPriceForVideoChats,
    maxPriceForBookings,
    approvalRate,
    mustAcceptRequests,
    mustAcceptBookings,
    mustAcceptVideoChats,
    maxDistance,
    page = 0,
    size = 20
) => {
    const hasDistance = lat && lng;
    const path = hasDistance ? "/search" : "/search/without-location";
    const distance = maxDistance ? maxDistance : 250;
    return postRequest(getBaseUrl() + `${path}?page=${page}&size=${size}${hasDistance ? `&distance=${distance}` : ""}`, {
        tags: tags.map(tag => replaceAll(tag, "%23", "#")),
        lat,
        lng,
        maxSubscriptionPrice,
        maxSMSPrice,
        maxMMSPrice,
        maxPriceForRequests,
        maxPriceForVideoChats,
        maxPriceForBookings,
        approvalRate,
        mustAcceptRequests,
        mustAcceptBookings,
        mustAcceptVideoChats
    }, {}).then(defaultResponseHandler);
}

export const searchUsersWithoutLocation = (
    tags = [],
    lat,
    lng,
    maxPriceForRequests,
    maxPriceForVideoChats,
    maxPriceForBookings,
    approvalRate,
    mustAcceptRequests,
    mustAcceptBookings,
    mustAcceptVideoChats,
    page = 0,
    size = 20
) => {
    return postRequest(getBaseUrl() + `/search/without-location?page=${page}&size=${size}`, {
        tags: tags.map(tag => replaceAll(tag, "%23", "#")),
        lat: null,
        lng: null,
        maxPriceForRequests,
        maxPriceForVideoChats,
        maxPriceForBookings,
        approvalRate,
        mustAcceptRequests,
        mustAcceptBookings,
        mustAcceptVideoChats
    }, {}).then(defaultResponseHandler);
}
