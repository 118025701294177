import {
    patchRequest as basePatchRequest,
    getRequest as baseGetRequest,
    postRequest as basePostRequest,
    baseDeleteRequest,
    putRequest as basePutRequest
} from './BaseRequests';
import axios from "axios";

export const patchRequest = (url, data, headers = {}) => {
    headers['Authorization'] = getSessionToken();
    return basePatchRequest(url, data, headers);
}

export const putRequest = (url, data, headers = {}) => {
    headers['Authorization'] = getSessionToken();
    return basePutRequest(url, data, headers);
}

export const postRequest = (url, data = {}, headers = {}) => {
    headers['Authorization'] = getSessionToken();
    return basePostRequest(url, data, headers);
}

export const deleteRequest = (url, headers = {}) => {
    headers['Authorization'] = getSessionToken();
    return baseDeleteRequest(url, headers);
}

export const getRequest = (url, headers = {}) => {
    headers['Authorization'] = getSessionToken();
    return baseGetRequest(url, headers);
}

export const uploadMultiPartData = (url, file, headers = {}, params = {}) => {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(params).forEach(param => {
        formData.append(param, params[param]);
    });
    headers['Content-Type'] = 'multipart/form-data';
    headers['Authorization'] = getSessionToken();
    const config = {headers: headers};
    return axios.put(url, formData, config);
}

export const uploadMultiPartDataPost = (url, file, headers = {}, params = {}) => {
    const formData = new FormData();
    Object.keys(params).forEach(param => {
        formData.append(param, params[param]);
    });
    headers['Content-Type'] = 'multipart/form-data';
    headers['Authorization'] = getSessionToken();
    formData.append('file', file);
    const config = {headers: headers};
    return axios.post(url, formData, config);
}

const getSessionToken = () => {
    const stJson = localStorage.getItem("session-token");
    const st = JSON.parse(stJson);
    return st.token;
}
