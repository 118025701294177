import {defaultResponseHandler, getBaseUrl, postRequest} from "../BaseRequests/BaseRequests";
import {getRequest} from "../BaseRequests/AuthenticatedBaseRequests";

export const beginSignUp = (phone) => {
    return postRequest(getBaseUrl() + "/auth/sign-up", {phone}, {}).then(defaultResponseHandler);
}

export const login = (phone, password) => {
    return postRequest(getBaseUrl() + "/auth/login", {phone, password}, {}).then(defaultResponseHandler);
}

export const verifyPhoneNumber = (phone, authCode, referralLinkId) => {
    return postRequest(getBaseUrl() + "/auth/verify-phone", {
        phone,
        password: authCode,
        referralLinkId
    }, {}).then(defaultResponseHandler);
}

export const completeSignUp = (phone, password) => {
    return postRequest(getBaseUrl() + "/auth/complete-sign-up", {phone, password}, {}).then(defaultResponseHandler);
}

export const getCurrentUsersId = () => {
    return getRequest(getBaseUrl() + "/auth").then(defaultResponseHandler);
}
