import React, {useEffect, useState} from 'react';
import {
    IonModal,
    IonButton,
    IonButtons,
    IonHeader,
    IonInput,
    IonTitle,
    IonToolbar,
    IonItem,
    IonContent,
    IonLabel
} from "@ionic/react";
import {
    getCreatorPreferencesForUser,
    getCurrentUsersCreatorPreferences
} from "../../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {getSalesTaxRate} from "../../../requests/SalesTax/SalesTaxRate";
import {purchaseMessages} from "../../../requests/MessageBalance/MessageBalances";
import {useHistory} from "react-router-dom";
import {formatCurrency} from "../../../Utils/Utils";

const PurchaseMessagesModal = ({showModal, closeModal, type, creatorUserId}) => {
    const [messageCount, setMessageCount] = useState(1);
    const [pricePerMMS, setPricePerMMS] = useState(0);
    const [pricePerSMS, setPricePerSMS] = useState(0);
    const [total, setTotal] = useState(0);
    const [zipCode, setZipCode] = useState(19711);
    const [rate, setRate] = useState(1);
    const [disabled, setDisabled] = useState();
    const history = useHistory();
    useEffect(() => {
        getCreatorPreferencesForUser(creatorUserId).then(prefs => {
            if (!prefs) closeModal();
            const {mmsPriceInCents, smsPriceInCents} = prefs;
            setPricePerMMS(mmsPriceInCents);
            setPricePerSMS(smsPriceInCents);
            if (type === "mms" && !mmsPriceInCents) {
                alert('User does not accept picture/video messages');
                closeModal();
            }
            setTotal(messageCount * (type === "mms" ? mmsPriceInCents : smsPriceInCents))
        }).catch(_ => {
            closeModal();
        });
        return () => {
            setZipCode(19711);
            setRate(1);
        }
    }, [creatorUserId, type, showModal]);

    const buyMessage = () => {
        if (disabled) return;
        setDisabled(true);
        return purchaseMessages(creatorUserId, messageCount, type === "mms", zipCode).then(paymentSecret => {
            closeModal();
            setDisabled(false);
            history.push("/pay/" + paymentSecret);
        }).catch(_ => {
            alert("Something went wrong");
            console.log("ERROR " + JSON.stringify(_));
            setDisabled(false);
            closeModal();
        });
    }

    return (
        <IonModal isOpen={showModal}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={closeModal}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>Purchase {type.toUpperCase()} Messages</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel position="stacked">How many messages?</IonLabel>
                    <IonInput value={messageCount}
                              onIonChange={e => {
                                  setMessageCount(e.target.value);
                                  setTotal(e.target.value * (type === "mms" ? pricePerMMS : pricePerSMS) * rate)
                              }}
                              type="number" placeholder="Message count. Min 1. Max 10"/>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Zip Code?</IonLabel>
                    <IonInput value={zipCode}
                              onIonChange={e => {
                                  e.preventDefault();
                                  const {value} = e.target;
                                  if (value.length > 5) return;
                                  setZipCode(value);
                                  if (value.length === 5 && !isNaN(value)) {
                                      getSalesTaxRate(value).then(rate => {
                                          if (rate) {
                                              setTotal((1 + rate.rate) * (messageCount * (type === "mms" ? pricePerMMS : pricePerSMS)));
                                              setRate(1 + rate.rate);
                                          }
                                      }).catch(_ => {
                                          console.log("ERROR " + JSON.stringify(_));
                                      });
                                  }
                              }}
                              type="number" placeholder="5 Digit Zip Code for Sales Tax."/>
                </IonItem>
            </IonContent>
            <button className={'btn btn-red-gradient'} onClick={buyMessage}>
                {disabled ? "Loading... " : `Purchase for ${formatCurrency(total)}`}
            </button>
        </IonModal>
    );
}

export default PurchaseMessagesModal;
