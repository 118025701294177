import React from 'react';

const LinkTreeLinkIcon = ({linkUrl}) => {
    if (linkUrl.includes("youtube")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"youtube"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("facebook")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"facebook"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("instagram")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"instagram"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("onlyfans")) { // todo
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"onlyfans"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("fansly")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"fansly"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("twitch")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"twitch"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("tiktok")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"tiktok"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("vimeo")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"vimeo"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("twitter")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"twitter"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("patreon")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"patreon"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("ticketmaster")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"ticketmaster"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("shopify")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"shopify"}-link-logo.webp`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("reddit")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"reddit"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("imgur")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"imgur"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("substack")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"substack"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("spotify")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"spotify"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    if (linkUrl.includes("amazon")) {
        return (
            <img src={`https://fan-numbers-v2-constants-bucket.s3.amazonaws.com/${"amazon"}-link-logo.png`}
                 style={{width: '22px', height: '22px', objectFit: 'cover', marginRight: '10px'}}/>
        );
    }
    return <></>

}

export default LinkTreeLinkIcon;
