import {getRequest, postRequest, uploadMultiPartData} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl, putRequest} from "../BaseRequests/BaseRequests";

export const getUsersInbox = () => {
    return getRequest(getBaseUrl() + "/inboxes").then(defaultResponseHandler);
}

export const createChat = (userId) => {
    return postRequest(getBaseUrl() + "/conversations/" + userId).then(defaultResponseHandler);
}

export const getChatById = (chatId) => {
    return getRequest(getBaseUrl() + '/conversations/' + chatId).then(defaultResponseHandler);
}

export const sendMessage = (convoId, message) => {
    return postRequest(getBaseUrl() + "/conversations/send/" + convoId, message).then(defaultResponseHandler);
}

export const sendMMSMessage = (convoId, message, isMov = false) => {
    return postRequest(getBaseUrl() + "/conversations/send-mms/" + convoId + "?isMov=" + isMov, message).then(defaultResponseHandler);
}

export const directUploadMMSPhoto = (directUploadUrl, file) => {
    return putRequest(directUploadUrl, file, {'Content-Type': file.type}).then(defaultResponseHandler);
}
