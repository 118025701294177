import React, {useState} from 'react';

const DesktopHeader = ({
                           phone,
                           setPhone,
                           scrollIntoView,
                           handleSignUp,
                           disabled,
                           searchVal,
                           setSearchVal,
                           performNewSearch,
                           reset
                       }) => {
    const [isSignUpMode, setIsSignUpMode] = useState();
    return (
        <header className="desktop-header d-lg-block d-none">
            <nav className="navbar navbar-expand-lg navbar-light">
                <form className="container-fluid" onSubmit={(e) => {
                    e.preventDefault();
                    if (isSignUpMode) {
                        handleSignUp();
                    } else {
                        performNewSearch();
                    }
                }}>
                    <a href="/chats" className="light-mode-logo navbar-brand">
                        <img src="assets/logo-sm-dark.png" alt="logo2" className="img-fluid"/>
                    </a>
                    <a href="/chats" className="dark-mode-logo navbar-brand">
                        <img src="assets/logo-sm-light.png" alt="logo3" className="img-fluid"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="input-group input-group-lg">
                            <span className="input-group-text">
                                <i className={'fa fa-search'}></i>
                            </span>
                            <input type="text"
                                   className="form-control"
                                   placeholder={isSignUpMode ? "Enter your phone number" : "Search by hashtag or @"}
                                   onChange={(e) => isSignUpMode ? setPhone(e.target.value) : setSearchVal(e.target.value)}
                                   value={isSignUpMode ? phone : searchVal}
                                   aria-label="Recipient's username with two button addons"

                            />
                            <>
                                <button
                                    className={`btn btn-expand z-index-1002 sign-up-button`}
                                    type="button"
                                    onClick={performNewSearch}>
                                    Search
                                </button>
                                <button className="btn btn-reset z-index-1003" type="button"
                                        onClick={reset}>
                                    Reset
                                </button>
                            </>
                        </div>
                    </div>
                </form>
            </nav>
        </header>
    );
}

export default DesktopHeader;
