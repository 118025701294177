import React, {useState} from "react";
import {formatDuration} from "../../../Utils/Utils"

export const PostCard = ({post}) => {
    const {createdAt, img, message} = post;
    const [isVideo, setIsVideo] = useState();
    const [showText, setShowText] = useState();
    const timeNow = new Date().valueOf();
    const [hovering, setHovering] = useState();
    return (
        <div
            className="col-lg-12 px-3 mb-sm-0 mb-4 video-card-container cursor-pointer "
            onMouseEnter={() => setShowText(true)}
            onMouseLeave={() => setShowText(false)}
        >
            <a href={img} target={"_blank"}>
                <div className="card-video darkMode-elable ">
                    <div className="img border-radius-20 darken-on-hover">
                        <div className="thumbnail position-relative">
                            {

                                !isVideo ?
                                    // <a href={img}>
                                    <img
                                        src={img}
                                        alt=""
                                        className="w-100 position-relative"
                                        style={{objectFit: 'cover'}}
                                        onError={({currentTarget}) => {
                                            currentTarget.onerror = null;
                                            setIsVideo(true);
                                        }}
                                    />
                                    // </a>
                                    :
                                    <video
                                        id={"search-video" + post}
                                        controls={true}
                                        autoPlay={hovering}
                                        onMouseEnter={() => {
                                            setHovering(true);
                                            const el = document.getElementById("search-video" + post);
                                            el.play();
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById("search-video-" + post).pause();
                                        }}
                                        className="w-100 position-relative"
                                        src={img}
                                    />
                            }
                            <div className="overlay"/>
                            <span
                                className="video-length">{formatDuration((timeNow - createdAt) / 1000) + " ago"}</span>
                            {
                                showText ? <p style={{color: "white", padding: '4px 16px'}}
                                              className={""}>{message ? message.substr(0, 200) : ""}</p> : <></>
                            }
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}
