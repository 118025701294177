import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import ProfilePosts from "../../components/Profile/ProfilePosts";
import LinkTreeClone from "../../components/LinkTree/LinkTreeClone";
import ProfileAvatar from "../../components/Profile/ProfileAvatar";
import ProfileBio from "../../components/Profile/ProfileBio";
import ProfileBanner from "../../components/Profile/ProfileBanner";
import {getUserIdForHandle} from "../../requests/Handles/HandleRequests";
import {
    IonPage,
    IonContent,
    useIonViewDidEnter,
    IonHeader,
    IonToolbar,
    IonSearchbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonFabButton,
    IonFab,
} from "@ionic/react";
import {
    search,
    close,
    chatbubbleOutline,
    ellipsisHorizontal,
    notificationsOffOutline,
    notificationsOutline
} from 'ionicons/icons';
import {generateUniqueID, getSearchParamOrDefault, isApp, isLoggedIn, replaceAll} from "../../Utils/Utils";
import HeaderLogo from "../../components/Header/HeaderLogo";
import AdditionalOptionsFab from "../../components/AdditionalOptions/AdditionalOptionsFab";
import {getCreatorPreferencesForUser} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {checkIsFollowing, checkIsSubscribed} from "../../requests/Subscriptions/SubscriptionRequests";
import LinkTree from "../../components/LinkTree/LinkTreeClone";
import {getUsersLinkTree} from "../../requests/LinkTree/LinkTreeRequests";
import {getPostsByUser} from "../../requests/Posts/PostRequests";
import Bounties from "../../components/Bounties/Bounties";
import {getUsersBounties} from "../../requests/Bounties/BountyRequests";

const Profile = () => {
    const history = useHistory();
    const {id} = useParams();
    const handle = id;
    const [bounties, setBounties] = useState({descriptionToId: {}, idToAmount: {}});
    const [showButtons, setShowButtons] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [userId, setUserId] = useState();
    const [showSearch, setShowSearch] = useState();
    const [isSignUpMode, setIsSignUpMode] = useState(false);
    const [currentTab, setCurrentTab] = useState("POSTS");
    const [photoId, setPhotoId] = useState(generateUniqueID()); // used to cache image loads
    const [allowsBookings, setAllowsBookings] = useState(false);
    const [allowsVideoChats, setAllowsVideoChats] = useState(false);
    const [subPrice, setSubPrice] = useState(false);
    const [allowsContentRequests, setAllowsContentRequests] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [links, setLinks] = useState({});
    const [linkOrdering, setLinkOrdering] = useState([]);
    const [posts, setPosts] = useState([]);
    useIonViewDidEnter(() => {
        getUserIdForHandle(handle).then(usersId => {
            setUserId(usersId);
            if (usersId) {
                getCreatorPreferencesForUser(usersId).then(prefs => {
                    setSubPrice(prefs.twoWayPrice);
                    // setAllowsBookings(isSubscribed ? prefs.acceptsAppointments : prefs.allowsPublicBookings);
                    // setAllowsVideoChats(isSubscribed ? prefs.acceptsVideoChats : prefs.allowsPublicVideoChats);
                    // setAllowsContentRequests(isSubscribed ? prefs.acceptsRequests : prefs.allowsPublicRequests);
                    setAllowsBookings(prefs.acceptsAppointments);
                    setAllowsVideoChats(prefs.acceptsVideoChats);
                    setAllowsContentRequests(prefs.acceptsRequests);
                    if (isLoggedIn()) {
                        const checkSubbedRequest = prefs.twoWayPrice ? checkIsSubscribed : checkIsFollowing;
                        checkSubbedRequest(usersId).then(isSubscribed => {
                            setIsFollowing(!!isSubscribed);
                        }).catch(_ => {

                        });
                    }
                });
                getUsersLinkTree(usersId).then(notALink => {
                    let hasLinks = notALink && notALink.order && notALink.order.length;
                    if (notALink) {
                        setLinks(notALink.linkIdToLink);
                        setLinkOrdering(notALink.order);
                    }
                    getPostsByUser(usersId).then(listOfPosts => {
                        setPosts((listOfPosts || []).reverse());
                        if (!listOfPosts || !listOfPosts.length) {
                            if (hasLinks) {
                                setCurrentTab("LINKS");
                            }
                        }
                        getUsersBounties(usersId).then(usersBounties => {
                            if (usersBounties) {
                                setBounties(usersBounties);
                            }
                        });
                    }).catch(_ => {
                    });
                }).catch(_ => {
                    getPostsByUser(usersId).then(listOfPosts => {
                        setPosts((listOfPosts || []).reverse());
                        getUsersBounties(usersId).then(usersBounties => {
                            if (usersBounties) {
                                setBounties(usersBounties);
                            }
                        });
                    }).catch(_ => {
                    });
                });
            } else {
                history.replace("/chats");
            }
        }).catch(_ => {
            alert("Something went wrong. Try refreshing the page " + _);
        });
        const showLinks = getSearchParamOrDefault("showLinks", false);
        setTimeout(() => {
            if (showLinks === true || showLinks === "true") {
                setCurrentTab("LINKS");
            } else {
                // setCurrentTab("POSTS");
            }
        }, 300);
    }, [id]);

    const handleSearchClicked = (e) => {
        e.preventDefault();
        if (isSignUpMode) {
            setIsSignUpMode((!isSignUpMode));
        } else {
            if (!searchVal) return;
            // if (searchVal.startsWith("@")) {
            //     if (isApp()) {
            //         window.open("https://fannumbers.com/p/" + searchVal.split(" ").shift(), "_blank");
            //     } else {
            //         history.push("/p/" + searchVal.split(" ").shift());
            //     }
            //     return;
            // }
            // let join = searchVal.split(" ").map(el => el && (el.charAt(0) === '#' || el.charAt(0) === '@') ? "%23" + el.substring(1) : "%23" + el).join(" ");
            // if (isApp()) {
            //     window.open("https://fannumbers.com/search-redirect/" + join, "_blank");
            // } else {
            //     history.push("/search-redirect/" + join);
            // }
            e.preventDefault();
            if (!searchVal) return;
            if (searchVal.includes("#")) {
                history.push("/search-redirect/" + replaceAll(searchVal, "#", "%23"));
            } else {
                history.push("/search/" + searchVal);
            }
        }
    }

    if (!userId) return (
        <IonPage>
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <HeaderLogo/>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowSearch(prev => !prev)}>
                                <IonIcon icon={showSearch ? close : search}/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
            </IonPage>
        </IonPage>
    )

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowSearch(prev => !prev)}>
                            <IonIcon icon={showSearch ? close : search}/>
                        </IonButton>
                    </IonButtons>
                    {
                        showSearch ?
                            <form onSubmit={handleSearchClicked}>
                                <IonSearchbar
                                    className={'bg-none'}
                                    placeholder={"Search by hashtag or @"} value={searchVal}
                                    onIonChange={(e) => setSearchVal(e.target.value)}/>
                            </form>
                            : <></>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ProfileBanner userId={userId} id={"random-id" + photoId}/>
                <section className="profile-info-full mb-5">
                    <div className="container">
                        <div className="card profile-info-card shadow position-relative">
                            <div className="profile-info">
                                <div className="user-avatar">
                                    <ProfileAvatar userId={userId}/>
                                </div>
                                <h2 className="user-name">@{replaceAll(handle, "@", "")}</h2>
                                <ProfileBio userId={userId}/>
                            </div>
                            <div className="modal fade" id="search-box-popup" tabIndex="-1"
                                 aria-labelledby="search-box-popupLabel"
                                 aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"/>
                                        </div>
                                        <div className="modal-body pb-4">
                                            <input type="search" className="form-control form-control-lg"
                                                   placeholder="Search"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <nav className="nav profile-nav mt-md-0 mt-4">
                                <div onClick={() => setCurrentTab("POSTS")}
                                     className={`nav-link cursor-pointer  ${currentTab === "POSTS" ? " active " : ""}`}>Past
                                    Sends
                                </div>
                                <div onClick={() => setCurrentTab("LINKS")}
                                     className={`nav-link cursor-pointer  ${currentTab === "LINKS" ? " active " : ""}`}>Links
                                </div>
                                <div onClick={() => setCurrentTab("BOUNTIES")}
                                     className={`nav-link cursor-pointer  ${currentTab === "BOUNTIES" ? " active " : ""}`}>Bounties
                                </div>
                            </nav>
                            {
                                {
                                    "POSTS": <ProfilePosts userId={userId} posts={posts}/>,
                                    "LINKS": <LinkTree linkOrdering={linkOrdering} links={links} userId={userId}/>,
                                    "BOUNTIES": <Bounties descriptionToId={bounties.descriptionToId}
                                                          idToAmount={bounties.idToAmount} userId={userId}/>
                                }[currentTab]
                            }
                        </div>
                    </div>
                </section>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton
                        onClick={() => {
                            if (isApp()) {
                                window.open(`https://fannumbers.com/create-chat-page/${userId}`, "_blank")
                            } else {
                                history.push(`/create-chat-page/${userId}`);
                            }
                        }}>
                        <IonIcon icon={chatbubbleOutline}/>
                    </IonFabButton>
                    <IonFabButton
                        className={'mt-1'}
                        onClick={() => {
                            if (isFollowing) {
                                history.push("/cancel-subscription/" + userId);
                            } else {
                                if (isApp()) {
                                    if (subPrice) {
                                        window.open(`https://fannumbers.com/create-chat-page/${userId}?redirect=/create-subscription/${userId}`, "_blank")
                                    } else {
                                        window.open(`https://fannumbers.com/create-chat-page/${userId}?redirect=/follow-user/${userId}?redirect=${window.location.pathname + window.location.search}`, "_blank")
                                    }
                                } else {
                                    if (subPrice) {
                                        history.push(`/create-chat-page/${userId}?redirect=/create-subscription/${userId}`);
                                    } else {
                                        history.push(`/create-chat-page/${userId}?redirect=/follow-user/${userId}?redirect=${window.location.pathname + window.location.search}`);
                                    }
                                }
                            }
                        }}>
                        <IonIcon icon={isFollowing ? notificationsOffOutline : notificationsOutline}/>
                    </IonFabButton>
                    {
                        userId && showButtons ?
                            <AdditionalOptionsFab
                                creatorId={userId}
                                allowsBookings={allowsBookings}
                                allowsVideoChats={allowsVideoChats}
                                allowsContentRequests={allowsContentRequests}
                            />
                            : <></>
                    }
                    {
                        <IonFabButton
                            className={'mt-1'}
                            onClick={() => {
                                setShowButtons(!showButtons);
                            }}>
                            <IonIcon icon={showButtons ? close : ellipsisHorizontal}/>
                        </IonFabButton>
                    }
                </IonFab>
                {/*<footer className="footer">*/}
                {/*    <p className="copyright-text">© 2023 FANNUMBERS LLC. All Rights Reserved</p>*/}
                {/*</footer>*/}
                {/*<IonFab vertical="bottom" horizontal="end" slot="fixed">*/}
            </IonContent>
        </IonPage>
    );
}

export default Profile;
