import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const MobileHeader = ({
                          scrollIntoView,
                          phone,
                          setPhone,
                          searchVal,
                          setSearchVal,
                          handleSignUp,
                          setShowSliders,
                          performNewSearch,
                          reset
                      }) => {
    const [showSearch, setShowSearch] = useState();
    const [disabled, setDisabled] = useState();
    const [isSignUpMode, setIsSignUpMode] = useState();
    const isNotValidPhoneNumber = (phoneNum) => {
        return !phoneNum || phoneNum.length < 10 || (phoneNum.length !== 10 && (phoneNum.length !== 12 || !phoneNum.startsWith("+1")));
    }
    return (
        <header className="mobile-header d-lg-none d-block shadow-sm border-bottom">
            <div className="position-relative">
                <nav className="navbar mt-3">
                    <div className="container d-flex">
                        <div>
                            <Link to="/chats" className="light-mode-logo navbar-brand"><img
                                src="assets/images/logo-sm-dark.png" alt="logo" className="img-fluid"/></Link>
                            <Link to="/chats" className="dark-mode-logo navbar-brand"><img
                                src="assets/logo-sm-light.png" alt="logo" className="img-fluid"/></Link>
                        </div>
                        <ul className="ps-0 d-flex align-items-center">

                            {
                                scrollIntoView ?
                                    <li>
                                        <div className="dropdown" onClick={(e) => {
                                            e.stopPropagation();
                                            scrollIntoView();
                                        }}>
                                            <div className={"fa fa-question-circle general-question-mark"}
                                                 style={{fontSize: "22px", marginRight: "16px"}}></div>
                                        </div>
                                    </li>
                                    : <></>
                            }
                            <li>
                                <div className="dropdown" onClick={(e) => {
                                    setShowSearch(!showSearch);
                                }}>
                                </div>
                            </li>
                            <li>
                                <button className="btn search-showHide-btn pt-2 px-0 collapsed"
                                        onClick={() => {
                                            setShowSearch(!showSearch)
                                            if (setShowSliders) {
                                                setShowSliders(!showSearch);
                                            }
                                        }}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                            </li>
                            <li>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div id="collapseOne"
                     className={`mobile-search shadow-sm accordion-collapse ${showSearch ? "" : "collapse"}`}>
                    <form className="input-group"
                          onSubmit={(e) => {
                              e.preventDefault();
                              if (isSignUpMode) {
                                  handleSignUp();
                              } else {
                                  const searchFormatted = searchVal && searchVal.startsWith("#") ? searchVal.substring(1) : searchVal;
                                  performNewSearch();
                              }
                          }}
                    >
                        <span className="input-group-text pe-0"><i
                            className={`fa ${isSignUpMode ? "fa-phone" : "fa-search"}`}></i></span>
                        <input type="text"
                               className="form-control"
                               placeholder={isSignUpMode ? "Enter Your Phone #" : "Search by hashtag or @"}
                               value={isSignUpMode ? phone : searchVal}
                               style={{fontSize: "16px"}}
                               onChange={(e) => {
                                   if (isSignUpMode) {
                                       setPhone(e.target.value)
                                   } else {
                                       setSearchVal(e.target.value)
                                   }
                               }}
                        />
                        <button
                            className={`btn z-index-1001 ${isSignUpMode ? "search-tag-button" : "border-0 btn-red-gradient"}`}
                            type="button"
                            onClick={() => {
                                if (isSignUpMode) {
                                    setIsSignUpMode(!isSignUpMode)
                                } else {
                                    performNewSearch();
                                }
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            className={`btn btn-reset z-index-1003 ${isSignUpMode ? `btn-red-gradient ${isSignUpMode ? "sign-up-button " : ""}` : ""}`}
                            onClick={reset}
                        >
                            {/*{isSignUpMode ? "Sign Up" : "Earn"}*/}
                            Reset
                        </button>
                    </form>
                </div>
            </div>
        </header>
    )
}

export default MobileHeader;
