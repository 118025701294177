import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router";

const SwipeButtons = ({topCardRef, setFilterValue, lastIdx, profileHandle}) => {
    const history = useHistory();
    const [profileRoute, setProfileRoute] = useState('');
    useEffect(() => {
        setProfileRoute("/p/" + profileHandle);
    }, [profileHandle])
    return (
        <div className="row col-11" style={{height: "50px", margin: "0 auto"}}>
            <div className={"col-6 col-md-12 col-sm-12"}
                 style={{margin: "0 auto", display: "flex", justifyContent: "space-evenly", maxWidth: "550px"}}>
                <button className={"tinder-swipe-button tinder-swipe-button-dislike"}
                        onClick={() => {
                            setFilterValue("");
                            topCardRef.current.swipe('left');
                        }}
                >
                    <i className={"fa fa-thumbs-down"}></i>
                </button>
                <button className={"tinder-swipe-button"}
                        onClick={() => {
                            history.push(profileRoute)
                        }}
                >
                    <i className={"fa fa-user"}></i>
                </button>
                <button className={"tinder-swipe-button tinder-swipe-button-like"}
                        onClick={() => {
                            setFilterValue("");
                            topCardRef.current.swipe('right');
                        }}
                >
                    <i className={"fa fa-thumbs-up"}></i>
                </button>
            </div>
        </div>
    );
}

export default SwipeButtons;
