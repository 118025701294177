import React, {useState} from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    IonModal,
    IonBackButton,
    IonButtons,
    IonSpinner,
    useIonViewDidEnter
} from "@ionic/react";
import HandlesModal from "../../components/Handle/HandlesModal";
import {checkIfCurrentUserHasHandle} from "../../requests/Handles/HandleRequests";
import {getSearchParamOrDefault} from "../../Utils/Utils";
import {useHistory} from "react-router-dom";

const EnsureHandle = () => {
    const [disabled, setDisabled] = useState(true);
    const history = useHistory();
    const checkHasHandle = () => {
        setDisabled(true);
        const redirect = getSearchParamOrDefault("redirect", "/chats");
        checkIfCurrentUserHasHandle().then(handle => {
            const hasHandle = !!handle;
            if (hasHandle) {
                history.replace(redirect);
            } else {
                setDisabled(false);
            }
        }).catch(_ => {
            alert("Something went wrong. Try refreshing the page or restarting the app");
            console.log("ERROR " + JSON.stringify(_));
        });
    }
    useIonViewDidEnter(() => {
        checkHasHandle();
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {
                    disabled ? <IonSpinner className={'w-100 m-0-auto mt-5'} name="dots"/> : <></>
                }
                {disabled ? <></> :
                    <>
                        <HandlesModal
                            hideHeader={true}
                            setHasHandle={checkHasHandle}
                            closeModal={() => {
                                setDisabled(true);
                                history.goBack();
                            }}
                        />
                    </>
                }
            </IonContent>
        </IonPage>
    );
}

export default EnsureHandle;
