import {postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl} from "../BaseRequests/BaseRequests";

export const blockNotifications = () => {
    return postRequest(getBaseUrl() + "/notification-preferences/block", {}).then(defaultResponseHandler);
}

export const setNotificationPreferences = (frequencyInMinutes) => {
    return postRequest(getBaseUrl() + "/notification-preferences", {frequencyInMinutes}).then(defaultResponseHandler);
}
