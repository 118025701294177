import {getRequest, patchRequest, postRequest} from "../BaseRequests/AuthenticatedBaseRequests";
import {defaultResponseHandler, getBaseUrl, putRequest} from "../BaseRequests/BaseRequests";

export const getCreatorsMassSends = () => {
    return getRequest(getBaseUrl() + "/mass-sends").then(defaultResponseHandler);
}

export const createMassSend = (body) => {
    return postRequest(getBaseUrl() + "/mass-sends", {body}).then(defaultResponseHandler);
}

export const createMMSMassSend = (body, postToWall) => {
    return postRequest(getBaseUrl() + "/mass-sends/mms", {body, postToWall}).then(defaultResponseHandler);
}

export const directUploadMassMMS = (directUploadUrl, file) => {
    return putRequest(directUploadUrl, file, {'Content-Type': file.type}).then(defaultResponseHandler);
}
