import React, {Component} from 'react';
import {IonItem} from "@ionic/react";
import {writeToClipboard} from "../../Utils/Utils";

class ExistingReferralLink extends Component {

    constructor(props) {
        super(props);
        this.timeout = null;
        this.state = {copied: false};
    }

    render = () => {
        const {copied} = this.state;
        const setCopied = (copied) => this.setState({copied});
        return (
            <IonItem className={"cursor-pointer"} onClick={() => {
                // navigator.clipboard.writeText(this.props.link).then(
                writeToClipboard(this.props.link).then(
                    () => {
                        setCopied(true);
                        if (this.timeout) {
                            clearTimeout(this.timeout);
                        }
                        this.timeout = setTimeout(() => {
                            setCopied(false);
                        }, 2500);
                    },
                    (_) => {
                        /* failure */
                        console.log("Failure " + _);
                    }
                );
            }}>
                {copied ?
                    "Link copied to your clipboard!" :
                    this.props.link
                }
            </IonItem>
        );
    }
}

export default ExistingReferralLink;
