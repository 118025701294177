import React, {useState} from 'react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {
    IonPage,
    IonHeader,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonTextarea, useIonViewDidEnter, IonToolbar, IonButtons, IonBackButton
} from "@ionic/react";
import {useHistory, useParams} from "react-router-dom";
import {getCreatorPreferencesForUser} from "../../requests/CreatorPreferences/CreatorPreferencesRequests";
import {formatCurrency} from "../../Utils/Utils";
import {createContentRequest} from "../../requests/ContentRequests/ContentRequests";
import {getSalesTaxRate} from "../../requests/SalesTax/SalesTaxRate";

const ContentRequestsPage = ({}) => {
    const {id} = useParams();
    const [disabled, setDisabled] = useState();
    const [priceForRequest, setPriceForRequest] = useState();
    const [requestDescription, setRequestDescription] = useState('');
    const [zipCode, setZipCode] = useState(19711);
    const [rate, setRate] = useState(1);
    const [minPrice, setMinPrice] = useState();
    const history = useHistory();
    useIonViewDidEnter(() => {
        getCreatorPreferencesForUser(id).then(prefs => {
            setMinPrice(prefs.minimumPriceForRequestInCents);
        }).catch(_ => {

        });
    });
    const requestContent = () => {
        if (disabled) return;
        if (!requestDescription || requestDescription.trim().length === 0) {
            alert("You must specify what you would like done");
            return;
        }
        setDisabled(true);
        if (priceForRequest * 100 < minPrice) {
            alert(`The minimum price for requested content is ${formatCurrency(minPrice)}`)
            setDisabled(false);
            return;
        }
        return createContentRequest(id, requestDescription, priceForRequest * 100).then(paymentSecret => {
            history.push("/pay/" + paymentSecret);
            setDisabled(false);
        }).catch(_ => {
            alert("Something went wrong. We were unable to create your content request. Try again.");
            setDisabled(false);
        });
    }
    const price = !priceForRequest ? "" : formatCurrency(priceForRequest * 100 * rate);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={'width-100'}>
                <IonItem>
                    <IonLabel>Make a Request</IonLabel>
                </IonItem>
                <IonItem>
                    Min Request Price: {formatCurrency(minPrice)}
                </IonItem>
                <IonItem>
                    Request Details:
                </IonItem>
                <IonItem>
                    <IonTextarea value={requestDescription} onIonChange={(e) => setRequestDescription(e.target.value)}
                                 placeholder={"Enter the details of your request here"}
                    />
                </IonItem>
                <IonItem>
                    Price:
                </IonItem>
                <IonItem>
                    <IonInput type={'number'}
                              value={priceForRequest}
                              onIonChange={(e) => setPriceForRequest(e.target.value)}
                              placeholder={"Ex. 100 for $100"}
                    />
                </IonItem>
                <IonItem>
                    Zip Code (for sales tax):
                </IonItem>
                <IonItem>
                    <IonInput type={'text'}
                              value={zipCode}
                              onIonChange={(e) => {
                                  e.preventDefault();
                                  const {value} = e.target;
                                  setZipCode(value);
                                  if (value.length === 5 && !isNaN(value)) {
                                      getSalesTaxRate(value).then(taxRate => {
                                          if (taxRate) {
                                              setRate(1 + taxRate.rate);
                                          }
                                      }).catch(_ => {

                                      });
                                  }
                              }}
                              placeholder={"Enter your zip code"}
                    />
                </IonItem>
                <IonItem className={'width-100'}>
                    By clicking the button below, you acknowledge that you are granting the star seven days (168 hrs) to
                    complete the request by submitting a video. If the star does not submit a video to complete the
                    request in that time, you will be refunded your deposit. If the star does not complete the request
                    to your satisfaction, your only recourse is to leave a negative review. The only circumstance that
                    will result in you receiving a refund is if the star does not submit content to complete the request
                    within 7 days (168 hours). If you do not accept these terms, do not click the button below. If you
                    understand and accept these terms, click the button below.
                </IonItem>
                <br/>
                <button className="btn login_btn btn-red-gradient width-100"
                        onClick={requestContent}>
                    Deposit {price ? price : ""} & Send Request
                </button>
            </IonContent>
        </IonPage>
    );
}

export default ContentRequestsPage;
