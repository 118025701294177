import {defaultResponseHandler, getBaseUrl, getRequest} from "../BaseRequests/BaseRequests";
import {postRequest} from "../BaseRequests/AuthenticatedBaseRequests";

export const checkIfHasConflict = (creatorId, startTime, duration) => {
    return getRequest(getBaseUrl() + `/meetings?creatorId=${creatorId}&startTime=${startTime}&duration=${duration}`).then(defaultResponseHandler);
}

export const requestBooking = (creatorId, startTime, hostingPreference, address, duration, expiration, answers) => {
    const url = getBaseUrl() + `/meetings/booking?creatorId=${creatorId}&startTime=${startTime}&hostingPreference=${hostingPreference}&address=${address}&duration=${duration}&expiration=${expiration}&answers=${answers}`;
    return postRequest(url).then(defaultResponseHandler);
}

export const requestVideoChat = (creatorId, startTime, duration, expiration) => {
    const url = getBaseUrl() + `/meetings/video-chat?creatorId=${creatorId}&startTime=${startTime}&duration=${duration}&expiration=${expiration}`;
    return postRequest(url).then(defaultResponseHandler);
}

export const acceptMeeting = (meetingId, address) => {
    return postRequest(getBaseUrl() + "/meetings/accept/" + meetingId, {address, password: ""}).then(defaultResponseHandler);
}

export const markArrival = (meetingId) => {
    return postRequest(getBaseUrl() + "/meetings/mark-arrived/" + meetingId, {}).then(defaultResponseHandler);
}

export const confirmArrival = (meetingId) => {
    return postRequest(getBaseUrl() + "/meetings/confirm-arrived/" + meetingId, {}).then(defaultResponseHandler);
}

export const startVideoChat = (meetingId, userId, password) => {
    return postRequest(getBaseUrl() + "/meetings/start-daily-video-call/" + meetingId, {userId, password: password}, {}).then(defaultResponseHandler);
}


