import {defaultResponseHandler, getBaseUrl, getRequest} from "../BaseRequests/BaseRequests";
import {postRequest} from "../BaseRequests/AuthenticatedBaseRequests";

export const getFeeStatus = (convoId) => {
    return getRequest(getBaseUrl() + "/paused-messaging-fees/" + convoId).then(defaultResponseHandler);
}

export const setFeeStatus = (convoId, status = false) => {
    return postRequest(getBaseUrl() + "/paused-messaging-fees/" + convoId + "/" + status).then(defaultResponseHandler);
}
