import React, {useState} from 'react';
import {
    IonPage,
    IonHeader,
    IonContent,
    IonLabel,
    IonInput,
    IonItem,
    IonToolbar,
    IonButtons,
    IonBackButton
} from '@ionic/react';
import HeaderLogo from "../../components/Header/HeaderLogo";
import {useHistory, useParams} from "react-router-dom";
import {createTip} from "../../requests/Tips/TipRequests";
import {formatCurrency} from "../../Utils/Utils";

const TipsPage = ({}) => {
    const [amount, setAmount] = useState(5.50);
    const [disabled, setDisabled] = useState(false);
    const {id} = useParams();
    const history = useHistory();
    const price = !amount ? "" : formatCurrency(amount * 100);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HeaderLogo/>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/chats"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="stacked">How much to tip?</IonLabel>
                    <IonInput value={amount}
                              step={0.01}
                              onIonChange={e => {
                                  setAmount(e.target.value);
                              }}
                              min={5.5}
                              max={999999}
                              inputMode={"decimal"}
                              type="number" placeholder="Min $5.50. Max $999,999"/>
                </IonItem>
            </IonContent>
            <button className={'btn btn-red-gradient'}
                    onClick={() => {
                        if (disabled) return;
                        if (isNaN(amount) || amount < 5.5) {
                            alert("Your tip must be at least $5.50");
                            return;
                        }
                        setDisabled(true);
                        return createTip(id, amount).then(clientSecret => {
                            history.push("/pay/" + clientSecret);
                            setDisabled(false);
                        }).catch(_ => {
                            setDisabled(false);
                        });
                    }}
            >
                {disabled ? "Loading..." :
                    `Tip ${price}`
                }
            </button>
        </IonPage>
    );
}

export default TipsPage;
