import React, {useState} from 'react';
import {
    IonContent,
    IonItem
} from '@ionic/react';
import * as UpChunk from '@mux/upchunk';
import {
    completeRequest,
    createMuxUploadLinkForContentRequest
} from "../../../../requests/ContentRequests/ContentRequests";
import {formatCurrency} from "../../../../Utils/Utils";

const FanRequestDetails = ({message, currentUserId, closeModal}) => {
    const {metadata} = message;
    const [file, setFile] = useState();
    const {
        requestBody,
        priceInCents,
        creatorId,
        id
    } = JSON.parse(metadata.fanRequest);
    const [disabled, setDisabled] = useState();
    const selectFile = () => {
        if (disabled) return;
        console.log("Select file clicked");
        document.getElementById('fan-request-completion-file-input').click();
    }
    const finishRequest = () => {
        if (disabled || !file) return;
        setDisabled(true);
        const handleError = (_) => {
            setDisabled(false);
            alert("Something went wrong. The request may have expired or you may have selected an invalid file type");
        }
        const handleSuccess = (_) => {
            setDisabled(false);
            setFile(null);
            closeModal();
            alert("Your upload has completed. The message will send shortly.");
        }
        // upload to mux
        return createMuxUploadLinkForContentRequest(id).then(directUploadUrl => {
            const upload = UpChunk.createUpload({
                endpoint: directUploadUrl, // Authenticated url
                file: file, // File object with your video file’s properties
                chunkSize: 5120, // Uploads the file in ~5mb chunks
            });
            upload.on('error', handleError);
            upload.on('success', handleSuccess);
        }).catch(handleError);
    }
    return (
        <IonContent>
            <IonItem>
                Video Request
            </IonItem>
            <IonItem>
                {requestBody}
            </IonItem>
            <IonItem>
                Price: {formatCurrency(priceInCents)}
            </IonItem>
            <IonItem>
                To complete the request and get paid, click the button below to upload a video of you doing the request.
            </IonItem>
            {
                creatorId !== currentUserId || disabled ? <></> :
                    <>
                        <br/>
                        <button className="btn login_btn btn-red-gradient width-100"
                                onClick={selectFile}>
                            {disabled ? "Loading..." : `Upload Video`}
                        </button>
                        <input id={'fan-request-completion-file-input'}
                               className={'display-none'}
                               type={'file'}
                               onChange={(e) => {
                                   setFile(e.target.files[0]);
                               }}
                        />
                        <br/>
                        <br/>
                    </>
            }
            {
                creatorId !== currentUserId || !file ? <></> :
                    <>
                        <br/>
                        <button className="btn login_btn btn-red-gradient width-100"
                                onClick={finishRequest}>
                            {disabled ? "Loading..." : `Finish & Get Paid`}
                        </button>
                    </>
            }
        </IonContent>
    );
}

export default FanRequestDetails;
