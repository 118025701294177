import React, {useState} from 'react';
import {useHistory} from "react-router-dom";

const PricingButtons = ({magicLink}) => {
    const history = useHistory();
    const [showButtons, setShowButtons] = useState();
    const buttons = [
        {label: "Set Messaging Prices", route: "/manage-messaging"},
        {label: "Set Sub Prices", route: "/manage-subs"},
        {label: "Set Request Prices", route: "/manage-requests"},
        {label: "Set Video Chat Prices", route: "/manage-video-chats"},
        {label: "Set Booking Prices", route: "/manage-bookings"},
    ];
    if (showButtons) {
        return (
            buttons.map(btn => (
                btn.isMagicLink ?
                        <div className={'mt-10'} key={"pricing-button-" + btn.route}>
                            <button className="btn login_btn btn-red-gradient color-white"
                                    onClick={() => {
                                        window.open(`https://fannumbers.com/app-redirect/${magicLink}?redirect=` + `${btn.route}`, "_blank")
                                    }}>{btn.label}
                            </button>
                        </div>
                    :
                        <div className={'mt-10'} key={"pricing-button-" + btn.route}>
                            <button className="btn login_btn btn-red-gradient color-white"
                                    onClick={() => history.push(btn.route)}>{btn.label}
                            </button>
                        </div>

            ))
        );
    }
    return (
        <>
            <div className={'mt-10'}>
                <button className="btn login_btn btn-red-gradient color-white"
                        onClick={() => setShowButtons(true)}>{"Manage Prices"}
                </button>
            </div>
        </>
    );

}

export default PricingButtons;
